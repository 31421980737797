import { Link, Paper, Typography, useTheme } from '@material-ui/core';

import { ESabbiBoxIcons } from '../WhatIsSabbiBox/enums/ESabbiBoxIcons';
import WhatIsSabbiBox from '../WhatIsSabbiBox/WhatIsSabbiBox';
import { IWhatIsSabbi } from './interfaces/IWhatIsSabbi';
import useStyles from './WhatIsSabbiStyles.material';

const WhatIsSabbi = ({ className = '' }: IWhatIsSabbi) => {
    const classes = useStyles();
    const theme = useTheme();
    return (
        <Paper className={`${classes.WhatIsSabbi} ${className}`}>
            <Typography className={classes.WhatIsSabbi_Title}>¿Qué es sabbi?</Typography>
            <Typography align="justify" gutterBottom>
                <b>
                    Es la nueva Tarjeta de Crédito digital que te brinda la flexibilidad necesaria
                    para realizar tus compras de manera más conveniente.
                </b>
            </Typography>
            <Typography align="justify" gutterBottom>
                Te facilitamos el acceso a todo lo que necesitas pagar, de manera flexible ya sea al
                contado o en cómodas cuotas.*
            </Typography>

            <WhatIsSabbiBox icon={ESabbiBoxIcons.shoppingCart} title="Comprar al contado">
                <Typography align="justify">
                    Agrega tu Tarjeta de Crédito bancaria preferida a{' '}
                    <span style={{ color: theme.palette.primary.main }}>sabbi</span> y realiza
                    compras con un sólo clic en los establecimientos asociados que cuenten con esta
                    opción habilitada.
                </Typography>
            </WhatIsSabbiBox>

            <WhatIsSabbiBox icon={ESabbiBoxIcons.creditCard} title="Comprar en cuotas">
                <Typography align="justify">
                    Compra en cuotas a través de un Pago inicial (Pie) que se carga de inmediato y
                    un crédito que se otorga mediante tu{' '}
                    <span style={{ color: theme.palette.primary.main }}>
                        Tarjeta de Crédito Digital sabbi
                    </span>
                    *.
                </Typography>
                <Typography align="justify">
                    Este método de pago facilita la gestión de tus cuotas, que serán facturadas
                    mensualmente a la Tarjeta de Crédito vinculada. Así, sólo el Pie se verá
                    reflejado inicialmente en los movimientos de tu tarjeta.
                </Typography>
                <Typography align="justify">
                    El crédito entregado se administra mediante tu{' '}
                    <span style={{ color: theme.palette.primary.main }}>
                        Tarjeta de Crédito Digital sabbi
                    </span>
                    , sin costos administrativos ni comisiones asociadas.
                </Typography>
            </WhatIsSabbiBox>

            <Typography style={{ marginBottom: '1rem' }} align="justify">
                *Dependiendo del comercio, éste te dará la opción de pagar al contado y/o en cuotas.
            </Typography>
            <Typography style={{ marginBottom: '1rem' }} align="justify">
                Conoce las tarjetas de crédito bancarias habilitadas{' '}
                <Link
                    style={{ color: theme.palette.primary.main }}
                    href="https://sabbi.cl/"
                    target="_blank">
                    aquí.
                </Link>
            </Typography>

            <Typography style={{ marginBottom: '1rem' }} align="justify">
                Más información en{' '}
                <Link
                    style={{ color: theme.palette.primary.main }}
                    href="https://sabbi.cl/"
                    target="_blank">
                    www.sabbi.cl
                </Link>
            </Typography>
        </Paper>
    );
};

export default WhatIsSabbi;
