import { FormControl, FormHelperText, RadioGroup, Typography, useTheme } from '@material-ui/core';
import { FC, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { BNPL2RadioButton, BNPLRadioButton } from '../BNPLRadioButton/BNPLRadioButton';
import useStyles from './BNPLRadioGroupStyles.material';
import IBNPLRadioGroupProps from './interfaces/IBNPLRadioGroupProps';

type AccordionItem = {
    value: string | number;
    expanded: boolean;
};

export const BNPLRadioGroup: FC<IBNPLRadioGroupProps> = ({
    id,
    name,
    classNames = '',
    rules,
    defaultValue = '',
    radioButtons,
    onChange,
    defaultHelperText = ''
}) => {
    const classes = useStyles();
    const { formState, control } = useFormContext();
    const { errors } = formState;
    const handleOnchange = (value: string | number | undefined) => {
        onChange && onChange(value);
    };
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={rules}
            render={({ field }) => (
                <FormControl
                    component="fieldset"
                    className={`${classNames} ${classes.BNPLGroupControl} ${
                        errors?.[name] ? 'error' : 'success'
                    }`}>
                    <RadioGroup
                        aria-label={name}
                        value={field.value}
                        id={id}
                        name={name}
                        onChange={(value) => {
                            handleOnchange(value.target.value);
                            field.onChange(value);
                        }}>
                        {radioButtons.map((radioButton) => (
                            <BNPLRadioButton
                                isChecked={field.value == radioButton.value}
                                key={`k-${radioButton.key}`}
                                label={radioButton.label}
                                value={radioButton.value}
                                amount={radioButton.amount}
                                caption={radioButton.caption}
                                priceCaption={radioButton.priceCaption}
                            />
                        ))}
                    </RadioGroup>
                    <FormHelperText className={classes.HelperText} id={`helper-text-${id}`}>
                        {errors?.[name] ? errors?.[name].message : defaultHelperText}
                    </FormHelperText>
                </FormControl>
            )}
        />
    );
};

export const BNPL2RadioGroup: FC<IBNPLRadioGroupProps> = ({
    id,
    name,
    classNames = '',
    rules,
    defaultValue = '',
    radioButtons,
    onChange,
    defaultHelperText = ''
}) => {
    const [expandedItems, setExpandedItems] = useState<AccordionItem[]>([
        {
            value: '',
            expanded: false
        }
    ]);

    const classes = useStyles();
    const theme = useTheme();
    const { formState, control } = useFormContext();
    const { errors } = formState;

    useEffect(() => {
        const radioItems: AccordionItem[] = [];
        if (radioButtons.length > 0) {
            radioButtons.map((item) => {
                const tempItem: AccordionItem = {
                    value: item.value,
                    expanded: false
                };
                radioItems.push(tempItem);
            });
            setExpandedItems(radioItems);
        }
    }, [radioButtons]);

    const handleOnchange = (value: string | number | undefined) => {
        const tempItem = expandedItems.map((item) => {
            if (item.value == value) {
                return {
                    value: item.value,
                    expanded: true
                };
            }
            return {
                value: item.value,
                expanded: false
            };
        });
        setExpandedItems(tempItem);
        onChange && onChange(value);
    };

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={rules}
            render={({ field }) => (
                <FormControl
                    component="fieldset"
                    className={`${classNames} ${classes.BNPLGroupControl} ${
                        errors?.[name] ? 'error' : 'success'
                    }`}>
                    <RadioGroup
                        aria-label={name}
                        value={field.value}
                        id={id}
                        name={name}
                        onChange={(value) => {
                            handleOnchange(value.target.value);
                            field.onChange(value);
                        }}>
                        {radioButtons.length > 0 &&
                            radioButtons.map((radioButton, index) => {
                                return (
                                    <BNPL2RadioButton
                                        isChecked={field.value == radioButton.value}
                                        key={`k-${radioButton.key}`}
                                        expanded={
                                            expandedItems &&
                                            expandedItems.length > 0 &&
                                            expandedItems[index]?.expanded !== undefined
                                                ? expandedItems[index].expanded
                                                : false
                                        }
                                        isBnpl={radioButton.isBnpl}
                                        initialQuota={radioButton.initialQuota}
                                        date={radioButton.date}
                                        label={radioButton.label}
                                        value={radioButton.value}
                                        quota={radioButton.quota && radioButton.quota}
                                        amount={radioButton.amount}
                                        caption={radioButton.caption}
                                        priceCaption={radioButton.priceCaption}
                                    />
                                );
                            })}
                    </RadioGroup>
                    <FormHelperText className={classes.HelperText} id={`helper-text-${id}`}>
                        {errors?.[name] ? errors?.[name].message : defaultHelperText}
                    </FormHelperText>
                </FormControl>
            )}
        />
    );
};
