import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    HyperLinkBox: {
        maxWidth: '100%',
        marginBottom: '10px',
        ['@media (max-width:959px)']: {
            width: '600px',
            margin: 'auto',
            marginBottom: '10px'
        }
    },
    HyperLink_GoBack: {
        ['@media (max-width:959px)']: {
            marginLeft: '-9px'
        },
        '& a': {
            fontFamily: 'robotoMedium',
            display: 'flex',
            alignItems: 'center'
        }
    },
    AlertBar: {
        '&.opened': {
            marginBottom: '1rem'
        }
    },
    ContentCard: {
        background: `linear-gradient(180deg, ${theme.palette.secondary.main} 30%, ${theme.palette.common.white} 30%);`
    },
    ContentCard_Error: {
        padding: '60px 55px 40px'
    },
    SubscribedCardPage: {
        maxWidth: '100%'
    },
    Message: {
        textAlign: 'center',
        marginBottom: '2rem',
        fontSize: '16px',
        lineHeight: '24px'
    },
    MessageImportant: {
        fontFamily: 'robotoBold'
    },
    Title: {
        fontSize: '1.4rem',
        fontFamily: 'robotoMedium',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '1rem',
        marginTop: '2.5rem'
    },
    Emoji: {
        marginLeft: '10px'
    },
    CheckCircleIcon: {
        backgroundColor: theme.palette.primary.main,
        height: '18px',
        width: '18px',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: '.7rem',
        color: theme.palette.secondary.light
    },
    ShowCreditCard: {
        margin: '1.5rem auto 0'
    },
    ButtonsBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        ['@media (max-width:450px)']: {
            flexDirection: 'column-reverse',
            alignItems: 'center',
            justifyContent: 'center'
        }
    },
    AddNewCardButton: {
        color: theme.palette.grey[800],
        width: '185px',
        maxWidth: '225px',
        minWidth: '185px',
        textTransform: 'none',
        margin: '1rem',
        ['@media (max-width:450px)']: {
            maxWidth: '100%',
            width: '100%',
            margin: '1rem 0'
        }
    },
    ContinueButton: {
        maxWidth: '225px',
        alignSelf: 'center',
        ['@media (max-width:450px)']: {
            width: '100%',
            maxWidth: '100%',
            margin: '1rem 0'
        }
    },
    SabbiImg: {
        height: '126px',
        marginBottom: '20px'
    },
    SubmitButton: {
        margin: '1rem 0'
    },
    ButtonsDiv: {
        display: 'flex',
        flexDirection: 'row-reverse',
        marginTop: '20px',
        justifyContent: 'center',
        alignItems: 'center',
        ['@media (max-width:450px)']: {
            flexDirection: 'column'
        }
    },
    CancelButton: {
        color: theme.palette.grey[800],
        textTransform: 'none',
        margin: 0,
        marginRight: '30px',
        '&:hover': {
            backgroundColor: theme.palette.common.white
        },
        ['@media (max-width:450px)']: {
            marginRight: 0
        }
    }
}));

export default useStyles;
