import sabbiHttpInstance from '../../instances/sabbi.instance';
import { IGenerateNewOtp } from './interfaces/IGenerateNewOtp';
import { IGenerateNewOtpResponse } from './interfaces/IGenerateNewOtpResponse';
import { IValidateOtp } from './interfaces/IValidateOtp';
import { IValidateOtpResponse } from './interfaces/IValidateOtpResponse';

export const validateOtpService = async (data: IValidateOtp): Promise<IValidateOtpResponse> => {
    const {
        data: { payload }
    } = await sabbiHttpInstance.post(`/user/validate-otp`, data);
    return payload;
};

export const generateNewOtpService = async (
    data: IGenerateNewOtp
): Promise<IGenerateNewOtpResponse> => {
    const {
        data: { payload }
    } = await sabbiHttpInstance.post(`/user/generate-new-otp`, data);
    return payload;
};
