import { Grid, Typography } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import { useEffect, useState } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import ModalErrorIcon from '../../assets/icons/modal-error-icon.svg';
import AlertBar from '../../components/AlertBar/AlertBar';
import { IErrorValues } from '../../components/AlertBar/interfaces/IErrorValues';
import ContentBox from '../../components/ContentBox/ContentBox';
import CardRadioGroup from '../../components/FormsContext/CardRadioGroup/CardRadioGroup';
import { CardRadioButton } from '../../components/FormsContext/CardRadioGroup/interfaces/ICardRadioGroupProps';
import { FormContext } from '../../components/FormsContext/FormContext/FormContext';
import RoundButton from '../../components/FormsContext/RoundButton/RoundButton';
import HyperLink from '../../components/HyperLink/HyperLink';
import MessageBox from '../../components/MessageBox/MessageBox';
import ModalDialog from '../../components/ModalDialog/ModalDialog';
import { useAuth } from '../../context/AuthContext/auth';
import { useBackdrop } from '../../context/BackdropContext/backdrop';
import { useModalExpired } from '../../context/ModalContext/modalExpired';
import { useTpa } from '../../context/TPAContext/tpa';
import { useRedirection } from '../../hooks/useRedirection';
import { ECardRoutePaths } from '../../routes/enum/ECardRoutePaths';
import { ELoginRoutePaths } from '../../routes/enum/ELoginRoutePaths';
import {
    cardSubscribeDebitService,
    userSubscribedCardsQuantity
} from '../../services/sabbi/card/card';
import { ECardType } from '../../shared/enums/ECardType';
import { cancelTRXandReturnTrade } from '../../utils/clientHelpers';
import { getDataDevice } from '../../utils/device/dataDeviceHelpers';
import { IDevice } from '../../utils/device/interfaces/IDevice';
import { serviceErrorMapper } from '../../utils/errors';
import IModalValuesState from './interfaces/IModalValuesState';
import useStyles from './SelectCardTypePageStyles.material';

type Inputs = {
    cardType?: string;
};

const SelectCardTypePage = () => {
    const [isLoading, setIsloading] = useState<boolean>(true);
    const [validCards, setValidCards] = useState<boolean>();
    const [cardsQuantity, setCardsQuantity] = useState<number>();
    const [modalValues, setModalValues] = useState<IModalValuesState>({
        cancelModal: false,
        modalNoCards: false
    });
    const [errorValues, setErrorValues] = useState<IErrorValues>({
        title: '',
        visible: false
    });
    const history = useHistory();
    const classes = useStyles();
    const backdrop = useBackdrop();
    const tpaContext = useTpa();
    const authContext = useAuth();
    const modalContext = useModalExpired();
    const { handleErrorRedirection, shouldRedirect } = useRedirection();
    const methods = useForm<FieldValues>({ mode: 'all', defaultValues: {} });

    const CARD_TYPES: Array<CardRadioButton> = [
        {
            value: ECardType.CREDIT,
            label: 'Crédito',
            key: 'radioCredit'
        },
        {
            value: ECardType.PREPAID,
            label: 'Prepago',
            disabled: true,
            disabledText: 'Pronto',
            key: 'radioPrepaid'
        }
    ];

    useEffect(() => {
        validUserCardCount();
    }, []);

    useEffect(() => {
        if (validCards) {
            modalContext.checkTeExpiration();
        } else if (validCards !== undefined) {
            if (authContext.user.te) {
                history.replace(ECardRoutePaths.CARD_SELECT);
            } else {
                history.replace(ELoginRoutePaths.LOGIN);
            }
        }
    }, [validCards]);

    useEffect(() => {
        if (!modalContext.isExpired) {
            if (validCards) {
                setIsloading(false);
            }
        }
    }, [modalContext.isExpired, validCards]);

    const validUserCardCount = async () => {
        try {
            const response = await userSubscribedCardsQuantity({
                TE: authContext.user.te as string,
                TPA: tpaContext.tokenInfo.tpa as string
            });
            const { cardsQuantity } = response;
            setCardsQuantity(cardsQuantity);
            if (cardsQuantity >= 5) {
                setValidCards(false);
            } else {
                setValidCards(true);
            }
        } catch (error) {
            setValidCards(false);
        }
    };

    /**
     * Cancel transaction and return to businessPartner URL
     * @return {Promise<void>}
     */
    const getRedirectUrlTrade = async () => {
        backdrop.openBackdrop();
        try {
            await cancelTRXandReturnTrade(tpaContext.tokenInfo.tpa as string);
        } catch (error) {
            const handledError = serviceErrorMapper(error);
            if (shouldRedirect(handledError)) {
                handleErrorRedirection(handledError);
            } else {
                showError(handledError.message);
            }
        } finally {
            backdrop.closeBackdrop();
        }
    };

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        backdrop.openBackdrop();
        switch (data.cardType) {
            case ECardType.CREDIT:
                backdrop.closeBackdrop();
                history.push({
                    pathname: ECardRoutePaths.CARD_SUBSCRIBE,
                    state: data
                });
                break;
            case ECardType.PREPAID:
                showError('El tipo de tarjeta prepago no está disponible');
                break;
            default:
                showError('El tipo de tarjeta no es válido');
                break;
        }
    };

    const handleAlertTimeout = () => {
        setTimeout(() => {
            clearError();
        }, 6000);
    };

    const showError = (title: string) => {
        setErrorValues({
            title: title,
            visible: true
        });
        handleAlertTimeout();
    };

    const clearError = () => {
        setErrorValues({
            title: '',
            visible: false
        });
    };

    const handleCardSelect = async () => {
        history.replace(ECardRoutePaths.CARD_SELECT);
    };

    const showModal = (modalName: keyof IModalValuesState) => {
        setModalValues({
            ...modalValues,
            [modalName]: true
        });
    };

    const closeModals = () => {
        setModalValues({
            cancelModal: false,
            modalNoCards: false
        });
    };
    if (isLoading) {
        return <></>;
    } else {
        return (
            <section className={classes.SelectPage}>
                <Grid container>
                    <Grid item xs={12}>
                        <div className={classes.HyperLinkBox}>
                            <HyperLink
                                classNames={classes.HyperLink_GoBack}
                                underline="none"
                                onClick={getRedirectUrlTrade}>
                                <ChevronLeftIcon />
                                volver al comercio
                            </HyperLink>
                        </div>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <ContentBox size="md">
                        <Typography variant="h2" component="h2">
                            Agrega tu tarjeta
                        </Typography>
                        <Typography variant="body1" component="p" align="center" gutterBottom>
                            Agrega tu Tarjeta de Crédito preferida. Con ella podrás pagar
                            directamente en una cuota o de manera automática si escoges la forma de
                            pago en cuotas sabbi.
                        </Typography>
                        <AlertBar
                            classNames={classes.AlertBar}
                            variant="filled"
                            severity="error"
                            message={errorValues.title}
                            open={errorValues.visible}
                            closeOnClick={() => clearError()}
                            icon={<WarningRoundedIcon />}
                        />
                        <FormContext
                            className={classes.Form}
                            id={'SubscribeForm'}
                            onSubmit={onSubmit}
                            methods={methods}>
                            <Typography
                                className={classes.SelectTypeText}
                                variant="body1"
                                component="p">
                                Selecciona tipo de tarjeta:
                            </Typography>
                            <CardRadioGroup
                                id="cardType"
                                name="cardType"
                                radioButtons={CARD_TYPES}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Debe seleccionar un tipo de tarjeta'
                                    }
                                }}
                            />
                            <div className={classes.ButtonsDiv}>
                                <RoundButton
                                    classNames={classes.SubmitButton}
                                    id={'submitbtn'}
                                    color="primary"
                                    name={'submitbtn'}
                                    type="submit">
                                    Agregar tarjeta
                                </RoundButton>
                                <RoundButton
                                    classNames={classes.CancelButton}
                                    onClick={() =>
                                        cardsQuantity === 0
                                            ? showModal('modalNoCards')
                                            : handleCardSelect()
                                    }>
                                    {cardsQuantity === 0 ? 'No tengo tarjetas' : 'Cancelar'}
                                </RoundButton>
                            </div>
                        </FormContext>
                    </ContentBox>
                </Grid>
                <ModalDialog
                    id="ModalNoCards"
                    open={modalValues.modalNoCards}
                    handleCancel={getRedirectUrlTrade}
                    handleAccept={closeModals}
                    acceptText={'Agregar tarjeta'}
                    cancelText={'Volver al comercio'}
                    acceptButtonColor={'primary'}
                    title={
                        <>
                            <img
                                src={ModalErrorIcon}
                                alt="icon error"
                                className={classes.ErrorIcon}
                            />
                            <Typography variant="h2" component="h2">
                                Registra una tarjeta para continuar
                            </Typography>
                        </>
                    }
                    scroll={'paper'}
                    maxWidth={'sm'}>
                    <div className={classes.ModalNoCards}>
                        <Typography component="p">
                            Para comenzar a disfrutar de los beneficios de sabbi,{' '}
                            <b>es necesario agregar una tarjeta para activar tu cuenta</b>
                        </Typography>
                    </div>
                </ModalDialog>
            </section>
        );
    }
};
export default SelectCardTypePage;
