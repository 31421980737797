import { makeStyles } from '@material-ui/core/styles';
import COLORS from '../../themes/colors';

const useStyles = makeStyles((theme) => ({
    CheckoutPage: {
        maxWidth: '100%'
    },
    ContentBox: {
        padding: '0px',
        display: 'flex',
        flexDirection: 'column'
    },
    Message: {
        textAlign: 'center'
    },
    SuccessIcon: {
        width: '60px',
        height: '60px',
        '&.success': {
            color: theme.palette.success.main
        },
        '&.error': {
            color: theme.palette.error.light
        }
    },
    RenderCheckout: {
        display: 'flex',
        flexDirection: 'column'
    },
    Title: {
        marginBottom: '10px',
        textAlign: 'start'
    },
    CheckoutTable: {
        marginBottom: '30px'
    },
    RoundButton: {
        maxWidth: '200px',
        display: 'flex',
        backgroundColor: theme.palette.background.default,
        margin: '1.5rem auto 0 auto'
    },
    ButtonsBox: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '100%',
        flexWrap: 'wrap',
        justifyContent: 'center',
        marginTop: '2rem'
    },
    SelectedCard: {
        '& .makeStyles-SelectedCard-31': {
            background: theme.palette.grey[200],
            borderRadius: '12px',
            minHeight: '56px',
            justifyContent: 'left',
            paddingLeft: '20px',
            marginBottom: '5px'
        }
    },
    DowloadCheckout: {
        color: theme.palette.grey[700],
        borderBottom: '1px solid',
        marginInlineStart: 'initial',
        alignSelf: 'center'
    },
    HeaderSucces: {
        padding: '2rem',
        background: theme.palette.grey[200],
        borderTopRightRadius: '20px',
        borderTopLeftRadius: '20px'
    },
    bodySucces: {
        padding: '2rem',
        display: 'initial'
    },
    HyperLinkBox: {
        maxWidth: '100%',
        marginBottom: '10px',
        ['@media (max-width:959px)']: {
            width: '600px',
            margin: 'auto',
            marginBottom: '10px'
        }
    },
    HyperLink_GoBack: {
        ['@media (max-width:959px)']: {
            marginLeft: '-9px'
        },
        '& a': {
            fontFamily: 'robotoMedium',
            display: 'flex',
            alignItems: 'center'
        }
    },
    partnerLogo: {
        display: 'flex',
        width: '65px',
        maxHeight: '65px'
    },
    TableHead: {
        backgroundColor: COLORS.lilacLighter
    },
    CellHead: {
        color: theme.palette.primary.main,
        fontFamily: 'robotoMedium'
    },
    CellBody: {
        padding: '5px 10px'
    },
    AccordionFee: {
        boxShadow: 'none',
        width: 'inherit',
        '&.MuiPaper-root': {
            padding: '0px'
        },
        '&.MuiAccordion-root.Mui-expanded': {
            margin: 0
        }
    },
    AccordionFee_Summary: {
        padding: '0px 10px',
        minHeight: '57px',
        height: '57px',
        borderBottom: `1px solid ${theme.palette.grey[400]}`,
        '&.Mui-expanded': {
            minHeight: '57px',
            margin: 0
        },
        '& p': {
            marginBottom: '-5px'
        },
        '@media (max-width:960px)': {
            height: 'auto'
        }
    },
    AccordionFee_LeftGrid: {
        '@media (max-width:960px)': {
            textAlign: 'center'
        }
    },
    AccordionFee_RightGrid: {
        textAlign: 'right',
        '& .MuiTypography-caption': {
            color: theme.palette.primary.main,
            fontFamily: 'robotoMedium'
        },
        '@media (max-width:960px)': {
            textAlign: 'center'
        }
    },
    AccordionFeeDetails: {
        padding: 0
    },
    TableFees_HeadCell: {
        color: theme.palette.primary.main,
        fontFamily: 'robotoMedium',
        backgroundColor: COLORS.cyanLight,
        padding: '10px 15px 0 15px '
    },
    InnerTableContainer: {
        '&.MuiTableCell-root': {
            padding: 0
        }
    },
    CustomScrollbar: {
        maxHeight: '100px'
    },
    InnerTable: {
        '& .MuiTableCell-root': {
            padding: '5px 15px'
        },
        '& .MuiTableCell-root:last-child': {
            padding: '5px 20px 5px 15px'
        }
    },
    TableFees: {
        backgroundColor: COLORS.cyanLight,
        '& .MuiTableCell-root': {
            border: 'none'
        }
    },
    Table_Container: {
        margin: '18px auto 0',
        borderRadius: '18px 18px 0 0'
    },
    BNPLRadioButton: {
        cursor: 'default',
        background: 'rgba(129, 245, 214, 0.2)',
        marginBottom: '1.4rem',
        padding: '15px'
    },
    ProgressDiv: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: 'auto',
      flexDirection: 'column',
    }
}));

export default useStyles;
