import sabbiHttpInstance from '../../instances/sabbi.instance';
import { IBusinessInformation } from './interfaces/IBusinessInformation';
import { IBusinessPartnerConfiguration } from './interfaces/IBusinessPartnerConfiguration';

export const businessInformation = async (
    data: IBusinessInformation
): Promise<IBusinessPartnerConfiguration> => {
    const {
        data: { payload }
    } = await sabbiHttpInstance.post('/business-partners/business-information', data);
    return payload;
};
