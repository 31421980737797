import { makeStyles } from '@material-ui/core/styles';
import COLORS from '../../themes/colors';
const useStyles = makeStyles((theme) => ({
    TableContainer: {
        boxShadow: '0px 1px 8px 0px rgba(0, 0, 0, 0.25)',
        backgroundColor: 'transparent',
        borderRadius: '10px',
    },
    TableHead: {
        backgroundColor: COLORS.lilacLighter
    },
    CellHead: {
        color: theme.palette.primary.main,
        fontFamily: 'robotoBold',
        borderBottom: '1px solid white',
        fontSize: '16px'
    },
    CellHead_FirstRounded: {
        borderRadius: '10px 0 0 0',
    },
    CellHead_LastRounded: {
        borderRadius: '0 10px 0 0',
    },
    CellBody: {
        padding: '16px',
        fontSize: '16px',
        '& p': {
            fontSize: '16px',
        }
    },
    CellBody_P: {
        fontFamily: 'robotoBold',
        marginBottom: '-5px'
    },
}));

export default useStyles;
