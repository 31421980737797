import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  MobileTransactionDetail:{
    margin: 'auto',
    maxWidth: '100%',
    width: '650px',
    '&.MuiAccordion-root.Mui-expanded':{
        margin: 'auto',
        "& svg#rotateChevronIcon": {
          transform: 'rotate(180deg)',
        }
    },
  },
  CartIcon: {
      marginRight: '10px',
    },
  ChevronIcon: {
      marginLeft: '10px',
      transform: 'rotate(0deg)',
      transition: 'transform 0.3s ease-in-out',
    },
  TotalPriceText: {
      color: theme.palette.primary.main,
      display: 'block',
      flexGrow: 1,
      fontFamily: 'robotoBold',
      fontSize: '16px',
      textAlign: 'right',
  },
  CustomScrollbar: {
      maxHeight: '310px',
      borderTop: '1px solid rgba(224, 224, 224, 1)',
    },
  TableContainer: {
      paddingRight: '15px',
      paddingBottom: '20px',
      '& .MuiTableCell-root': {
          padding: '18px 17px'
      },
      '& .MuiTableRow-root:last-child .MuiTableCell-root': {
        borderBottom: 'none'
      }
  },
  TableCellProduct: {
      width: '80%',
  },
  ShopTitle:{
      fontFamily: 'robotoMedium',
      margin: '12px 18px 17px',
  },
  ShopName: {
      fontFamily: 'robotoMedium',
  },
  Quantity: {
      fontSize: '12px',
      fontFamily: 'robotoMedium'
    },
  WhiteGradient:{
      width: '100%',
      bottom: 0,
      height: '50px',
      zIndex: 1000,
      background: 'linear-gradient(180deg,rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .4) 30%, #FFFFFF 100%)',
      marginTop: '-45px',
  },
}));

export default useStyles;
