import { Typography } from '@material-ui/core';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';

import ContentBox from '../../components/ContentBox/ContentBox';
import SuccessMessage from '../../components/SuccessMessage/SuccessMessage';
import { useAuth } from '../../context/AuthContext/auth';
import { ECardRoutePaths } from '../../routes/enum/ECardRoutePaths';
import { ELoginRoutePaths } from '../../routes/enum/ELoginRoutePaths';
import useStyles from './NotFoundPageStyles.material';

export const NotFoundPage: FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const teContext = useAuth();
    const handleContinue = () => {
        if (teContext.user.te) {
            history.replace(ECardRoutePaths.CARD_SELECT);
        } else {
            history.replace(ELoginRoutePaths.LOGIN);
        }
    };
    return (
        <section className={classes.CreatedPasswordPage}>
            <ContentBox>
                <SuccessMessage
                    success={false}
                    title="404!"
                    continueOnClick={handleContinue}
                    continueText={'Conocer beneficios'}>
                    <Typography className={classes.Message} component="p">
                        No pudimos encontrar lo que buscabas. <br /> Te invitamos a conocer los
                        beneficios y descuentos de sabbi con todas nuestras tiendas asociadas.
                    </Typography>
                </SuccessMessage>
            </ContentBox>
        </section>
    );
};
