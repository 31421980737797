import { EQuotaChargeType } from '../../../../shared/enums/EQuotaChargeType';
import { EQuotas } from '../../../../shared/enums/EQuotas';
import { EPaymentMethods } from '../../../../utils/enums/EPaymentMethods';

export enum EPaymentMethodsState {
    ENABLED = 'ENABLED',
    DISABLED = 'DISABLED'
}

enum EPaymentTypesState {
    ENABLED = 'ENABLED',
    DISABLED = 'DISABLED'
}

interface IBusinessPartnerInformationPaymentType {
    id: number;
    state: EPaymentTypesState;
    name: string;
    fee: number;
    createdAt: Date;
    updatedAt: Date;
}

export interface IBusinessPartnerInformationPaymentMethod {
    id: number;
    state: EPaymentMethodsState;
    name: EPaymentMethods;
    quotas: EQuotas[];
    firstPaymentDelay: number;
    shareRevenue: number;
    interestRate: number;
    initialQuotaChargeType: EQuotaChargeType;
    initialQuotaChargeAmount: number;
    category: string[];
    createdAt: Date;
    updatedAt: Date;
    paymentTypes: IBusinessPartnerInformationPaymentType[];
}
