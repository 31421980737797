import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    RegisterManualAddressPage: {
        maxWidth: '100%',
        '& h2': {
            textAlign: 'center',
            marginBottom: '2rem'
        }
    },
    HyperLinkBox: {
        maxWidth: '100%',
        marginBottom: '10px',
        ['@media (max-width:959px)']: {
            width: '600px',
            margin: 'auto',
            marginBottom: '10px'
        }
    },
    HyperLink_GoBack: {
        ['@media (max-width:959px)']: {
            marginLeft: '-9px'
        },
        '& a': {
            display: 'flex',
            alignItems: 'center'
        }
    },  
    ContentBox: {
        display: 'flex',
        flexDirection: 'column',
    },
    Form: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        flexDirection: 'column',
        '& .MuiFormControl-root': {
            maxWidth: '100%',
            marginBottom: '30px',
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        '& .MuiFormControl-root:last-of-type': {
            marginBottom: '20px'
        }
    },
    SubmitButton: {
        width: '100%',
        maxWidth: '460px',
        alignSelf: 'center',
        ['@media (max-width:450px)']: {
            width: '100%',
        }
    },
    CancelButton: {
        color: theme.palette.grey[800],
        width: '100%',
        maxWidth: '460px',
        textTransform: 'none',
        ['@media (max-width:450px)']: {
            maxWidth: '100%',
            width: '100%',
        }
    },
    ButtonsBox: {
        width: '100%',
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column-reverse',
    },
    SabbiLogo: {
        width: '60px',
        height: '60px',
        alignSelf: 'center',
        marginBottom: '1.9rem'
    },
    AlertBar: {
        '&.opened': {
            marginBottom: '1rem'
        }
    },
    Subtitle_Text: {
        fontFamily: 'robotoMedium',
        fontSize: '18px',
        color: theme.palette.primary.dark,
        marginBottom: '1rem',
        alignSelf: 'center',
        maxWidth: '460px',
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
}));

export default useStyles;
