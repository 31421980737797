import { makeStyles } from '@material-ui/core/styles';
import COLORS from '../../themes/colors';

const useStyles = makeStyles((theme) => ({
    CreditCard: {
        width: '265px',
        height: '160px',
        background: `linear-gradient(150deg,${theme.palette.grey[100]}  57%, ${COLORS.grey1500} 57%)`,
        borderRadius: '25px',
        position: 'relative',
        padding: '20px',
        boxShadow: '0px 4px 20px rgba(99, 92, 92, 0.5), 0px 4px 15px rgba(62, 62, 62, 0.3)'
    },
    GenericCard: {
        color: theme.palette.common.white
    },
    TitleCard: {
        zIndex: 100,
        position: 'relative',
        display: 'flex',
        alignItems: 'center'
    },
    GreyBadge: {
        '& .MuiBadge-badge': {
            backgroundColor: theme.palette.grey[400],
            fontFamily: 'robotoMedium'
        }
    },
    TypeBadge: {
        width: '30px',
        '& .MuiBadge-badge': {
            color: theme.palette.common.black,
            border: `1px solid ${theme.palette.grey[400]}`
        },
        ['@media (max-width:380px)']: {
            display: 'none'
        }
    },
    Card_Icon_Check: {
        fontSize: '32px',
        color: theme.palette.primary.main,
        marginLeft: 'auto',
        backgroundColor: theme.palette.secondary.main,
        borderRadius: '100px'
    },
    DotsCard: {
        zIndex: 100,
        position: 'relative',
        marginTop: '0.8rem',
        marginLeft: '.5rem',
        display: 'flex',
        alignItems: 'center'
    },
    DotsCard_Dots: {
        fontFamily: 'robotoMedium',
        fontWeight: 700,
        fontSize: '20px',
        color: COLORS.blackLight,
        lineHeight: '24px',
        letterSpacing: '1.5px'
    },
    Date_User_Card: {
        zIndex: 100,
        display: 'flex',
        marginTop: '1rem',
        marginLeft: '.5rem',
        alignItems: 'baseline'
    },
    Text: {
        color: COLORS.blackLight,
        fontSize: '16px',
        fontFamily: 'robotoMedium',
        whiteSpace: 'nowrap'
    },
    CreditCardImage_Card: {
        textAlign: 'right',
        marginLeft: '15px',
        marginTop: '-4px'
    },
    CreditCardImage: {
        '& img': {
            maxWidth: '40px',
            maxHeight: '30px'
        },
        '& svg': {
            color: theme.palette.common.white
        }
    },
    CardImage_NoUser_Card: {
        textAlign: 'right',
        marginRight: '17px',
        marginTop: '19px'
    },
    CardImage_NoUser: {
        display: 'initial',
        '& img': {
            maxWidth: '40px',
            maxHeight: '30px'
        },
        '& svg': {
            color: theme.palette.common.white
        }
    }
}));

export default useStyles;
