import { makeStyles } from '@material-ui/core/styles';
import purpleCheck from '../../../assets/icons/purpleCheck.svg';

const useStyles = makeStyles((theme) => ({
    CardRadioButton: {
        border: `solid 1px ${theme.palette.grey[200]}`,
        borderRadius: '20px',
        display: 'flex',
        margin: '.6rem auto',
        '&.checked': {
            border: `solid 1px rgba(0, 0, 0, 0)`,
            boxShadow: `0px 0px 0px 2px ${theme.palette.primary.main}`
        },
        '& .MuiFormControlLabel-label': {
            width: '100%'
        }
    },
    Radio: {
        display: 'none'
    },
    RadioButtonContainer: {
        position: 'relative',
        width: 'initial'
    },
    DeleteButton: {
        position: 'absolute',
        right: '55px',
        top: 'calc(50% - 12px)'
    },
    RadioBoxButton: {
        alignItems: 'center',
        display: 'flex',
        width: '100%'
    },
    BNPL2RadioBoxButton: {
        borderRadius: '20px !important',
        minHeight: '70px'
    },
    BNPL2Summary: {
        '&.Mui-expanded': {
            margin: 0
        },
        '& .MuiAccordionSummary-expandIcon': {
            position: 'absolute',
            right: '1rem',
            top: 0
        }
    },
    Label: {
        color: theme.palette.primary.main,
        fontSize: '1.1rem',
        lineHeight: '1.2rem',
        marginBottom: '.4rem',
        '@media (max-width:500px)': {
            fontSize: '1rem',
            marginBottom: 0
        }
    },
    Caption: {
        alignSelf: 'flex-start',
        color: theme.palette.grey[500],
        fontFamily: 'robotoBold',
        fontSize: 12,
        marginTop: '10px'
    },
    CaptionCreditDetails: {
        color: theme.palette.grey[600],
        fontFamily: 'robotoBold',
    },
    Amount: {
        color: theme.palette.primary.main,
        fontSize: '1.3rem',
        lineHeight: '1.2rem',
        marginBottom: '.4rem',
        textAlign: 'right'
    },
    PriceCaption_Grid: {
        display: 'grid'
    },
    PriceCaption: {
        alignSelf: 'flex-end',
        color: theme.palette.grey[500],
        fontFamily: 'robotoBold',
        fontSize: 12,
        justifySelf: 'flex-end',
        textAlign: 'right',
        marginTop: '10px'
    },
    RadioMaterial: {
        marginLeft: 'auto'
    },
    QuotaInfoList: {
        marginTop: 0,
        paddingLeft: '1rem',
        '& li::marker': {
            content: `url('${purpleCheck}')`,
            paddingTop: '4px'
        },
        '& li': {
            paddingLeft: '8px',
            margin: '10px 0'
        },
        '& li:first-child': {
            marginTop: 0
        },
    },
    ExpandMoreIcon: {
        color: theme.palette.primary.main,
        fontSize: '2rem'
    }
}));

export default useStyles;
