import { Box, Button, Grid, Typography } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import ModalErrorIconSrc from '../../assets/icons/modal-error-icon.svg';
import AlertBar from '../../components/AlertBar/AlertBar';
import { IErrorValues } from '../../components/AlertBar/interfaces/IErrorValues';
import ContentBox from '../../components/ContentBox/ContentBox';
import RoundButton from '../../components/FormsContext/RoundButton/RoundButton';
import HyperLink from '../../components/HyperLink/HyperLink';
import { useAuth } from '../../context/AuthContext/auth';
import { useBackdrop } from '../../context/BackdropContext/backdrop';
import { useTpa } from '../../context/TPAContext/tpa';
import { useQuery } from '../../hooks/useQuery';
import { useRedirection } from '../../hooks/useRedirection';
import { ECardRoutePaths } from '../../routes/enum/ECardRoutePaths';
import { ELoginRoutePaths } from '../../routes/enum/ELoginRoutePaths';
import { EPasswordRoutePaths } from '../../routes/enum/EPasswordRoutePaths';
import { EUserRoutePaths } from '../../routes/enum/EUserRoutePaths';
import { IRutValidateOnboardingResponse } from '../../services/sabbi/validation/interfaces/IRutValidateOnboardingResponse';
import { IValidateUniqueKey } from '../../services/sabbi/validation/interfaces/IValidateUniqueKey';
import { validateUniqueKey } from '../../services/sabbi/validation/validation';
import { cancelTRXandReturnTrade } from '../../utils/clientHelpers';
import { getDataDevice } from '../../utils/device/dataDeviceHelpers';
import { EFlowIndicator } from '../../utils/enums/EFlowIndicator';
import { serviceErrorMapper } from '../../utils/errors';
import { IUserContactData } from '../OtpValidationPage/interfaces/IOtpValidationLocationState';
import { IUniqueKeyLocationState } from '../UniqueKeyPage/interfaces/IUniqueKeyLocationState';
import useStyles from './UniqueKeyValidationPageStyles.material';

const UniqueKeyValidationPage: FC = () => {
    const [errorType, setErrorType] = useState<'SERVICE' | 'VALIDATION'>();
    const [errorValues, setErrorValues] = useState<IErrorValues>({
        title: '',
        visible: false
    });
    const [formValues, setFormValues] = useState<IUniqueKeyLocationState>();
    const classes = useStyles();
    const backdrop = useBackdrop();
    const tpaContext = useTpa();
    const authContext = useAuth();
    const history = useHistory();
    const location = useLocation<IUniqueKeyLocationState>();
    const query = useQuery(location.search);
    const { handleErrorRedirection, shouldRedirect } = useRedirection();

    useEffect(() => {
        validateUniqueKeyToken();
    }, []);

    const validateUniqueKeyToken = async () => {
        backdrop.openBackdrop();
        const token = query.get('token');
        const rut = query.get('rut');
        try {
            if (token && rut) {
                const device = getDataDevice();
                const validateUniqueKeyRequest: IValidateUniqueKey = {
                    TPA: tpaContext.tokenInfo.tpa as string,
                    device,
                    tokenUniqueKey: token,
                    documentNumber: rut
                };
                const validation = await validateUniqueKey(validateUniqueKeyRequest);
                if (
                    validation.statusValidation &&
                    validation.metadata.flow === EFlowIndicator.REGISTER
                ) {
                    history.replace({
                        pathname: EPasswordRoutePaths.PASSWORD_CREATE,
                        state: {
                            statusValidation: validation.statusValidation,
                            userRegisterData: validation.metadata.userRegisterData,
                            userContactData: validation.metadata.userContactData,
                            TOTP: validation.metadata.TOTP,
                            flow: validation.metadata.flow
                        }
                    });
                } else {
                    const historyValues: IUniqueKeyLocationState = {
                        userRegisterData: validation.metadata
                            .userRegisterData as IRutValidateOnboardingResponse,
                        userContactData: validation.metadata.userContactData as IUserContactData,
                        TOTP: validation.metadata.TOTP as string,
                        flow: validation.metadata.flow as EFlowIndicator
                    };
                    setFormValues(historyValues);
                    setErrorType('VALIDATION');
                }
            } else {
                if (!authContext.user?.te) {
                    history.replace(ELoginRoutePaths.LOGIN);
                } else {
                    history.replace(ECardRoutePaths.CARD_SELECT);
                }
            }
        } catch (error) {
            setErrorType('SERVICE');
        } finally {
            backdrop.closeBackdrop();
        }
    };

    /**
     * Pass form values back to Unique Key Auth page
     * @return {void}
     */
    const returnToUniqueKeyAuth = () => {
        if (formValues) {
            history.replace({
                pathname: EUserRoutePaths.UNIQUE_KEY,
                state: {
                    userRegisterData: formValues.userRegisterData,
                    userContactData: formValues.userContactData,
                    TOTP: formValues.TOTP,
                    flow: formValues.flow
                }
            });
        }
    };

    /**
     * Remove error
     * @return {void}
     */
    const clearError = () => {
        setErrorValues({
            title: '',
            visible: false
        });
    };
    /**
     * Clear error on timeout
     * @return {void}
     */
    const handleAlertTimeout = () => {
        setTimeout(() => {
            clearError();
        }, 6000);
    };
    /**
     * Show Alert error and set error values state
     * @param {string} title error message
     * @return {void}
     */
    const showError = (title: string) => {
        setErrorValues({
            title: title,
            visible: true
        });
        handleAlertTimeout();
    };

    /**
     * Cancel transaction and return to businessPartner URL
     * @return {Promise<void>}
     */
    const getRedirectUrlTrade = async () => {
        backdrop.openBackdrop();
        try {
            await cancelTRXandReturnTrade(tpaContext.tokenInfo.tpa as string);
        } catch (error) {
            const handledError = serviceErrorMapper(error);
            if (shouldRedirect(handledError)) {
                handleErrorRedirection(handledError);
            } else {
                showError(handledError.message);
            }
        } finally {
            backdrop.closeBackdrop();
        }
    };

    const renderRedirectionGrid = () => (
        <Grid container>
            <Grid item xs={12} lg={12}>
                <div className={classes.HyperLinkBox}>
                    <HyperLink
                        classNames={classes.HyperLink_GoBack}
                        underline="none"
                        onClick={getRedirectUrlTrade}>
                        <ChevronLeftIcon />
                        volver al comercio
                    </HyperLink>
                </div>
            </Grid>
        </Grid>
    );

    const renderValidationError = () => (
        <Box>
            {renderRedirectionGrid()}
            <Grid container>
                <Grid item xs={12} md={12}>
                    <ContentBox classNames={classes.ContentBox}>
                        <img
                            className={classes.InfoIcon}
                            src={ModalErrorIconSrc}
                            alt="Error Icon"
                        />
                        <Typography component="h2" variant="h2" gutterBottom>
                            Validación fallida
                        </Typography>
                        <Typography component="p" className={classes.ErrorText} align="center">
                            <b>No pudimos validar tu identidad.</b>
                            <br /> Por favor inténtalo nuevamente ahora o más <br /> tarde.
                        </Typography>
                        <AlertBar
                            classNames={classes.AlertBar}
                            variant="filled"
                            severity="error"
                            message={errorValues.title}
                            open={errorValues.visible}
                            closeOnClick={() => clearError()}
                            icon={<WarningRoundedIcon />}
                        />
                        <div className={classes.ButtonsBox}>
                            <RoundButton
                                classNames={classes.SubmitButton}
                                color="primary"
                                type="button"
                                onClick={returnToUniqueKeyAuth}>
                                Intentar nuevamente
                            </RoundButton>
                            <Button
                                type="button"
                                className={classes.CancelButton}
                                onClick={getRedirectUrlTrade}>
                                Volver al comercio
                            </Button>
                        </div>
                    </ContentBox>
                </Grid>
            </Grid>
        </Box>
    );

    const renderServiceError = () => (
        <Box>
            {renderRedirectionGrid()}
            <Grid container>
                <Grid item xs={12} md={12}>
                    <ContentBox classNames={classes.ContentBox}>
                        <img
                            className={classes.InfoIcon}
                            src={ModalErrorIconSrc}
                            alt="Error Icon"
                        />
                        <Typography component="h2" variant="h2" gutterBottom>
                            Validación fallida
                        </Typography>
                        <Typography component="p" className={classes.ErrorText} align="center">
                            Ocurrió un error en la validación y{' '}
                            <b>
                                estamos <br /> trabajando para solucionarlo.
                            </b>
                        </Typography>
                        <Typography component="p" className={classes.ErrorText} align="center">
                            Por favor, inténtalo nuevamente <br /> más tarde.
                        </Typography>
                        <AlertBar
                            classNames={classes.AlertBar}
                            variant="filled"
                            severity="error"
                            message={errorValues.title}
                            open={errorValues.visible}
                            closeOnClick={() => clearError()}
                            icon={<WarningRoundedIcon />}
                        />
                        <div className={classes.ButtonsBox}>
                            <RoundButton
                                classNames={classes.SubmitButton}
                                color="primary"
                                type="button"
                                onClick={getRedirectUrlTrade}>
                                Volver al comercio
                            </RoundButton>
                        </div>
                    </ContentBox>
                </Grid>
            </Grid>
        </Box>
    );

    const validateRendering = () => {
        if (errorType == 'VALIDATION') {
            return renderValidationError();
        }
        if (errorType == 'SERVICE') {
            return renderServiceError();
        }
    };

    return <section className={classes.UniqueKeyValidationPage}>{validateRendering()}</section>;
};

export default UniqueKeyValidationPage;
