import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    FormControlLabel,
    Grid,
    Radio,
    Typography,
    useTheme
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FC } from 'react';

import { thousandSeparator } from '../../../utils/clientHelpers';
import useStyles from './BNPLRadioButtonStyles.material';
import { IBNPL2RadioButtonProps, IBNPLRadioButtonProps } from './interfaces/IBNPLRadioButtonProps';
import { IBNPL2BoxButtonProps, IRadioBoxButtonProps } from './interfaces/IRadioBoxButtonProps';

const RadioBoxButton: FC<IRadioBoxButtonProps> = ({ label, amount, caption, priceCaption }) => {
    const classes = useStyles();
    return (
        <div className={classes.RadioBoxButton}>
            <Grid container>
                <Grid item xs={6} lg={10}>
                    <Typography className={classes.Label} variant="subtitle2" component="p">
                        {label}
                    </Typography>
                </Grid>
                <Grid item xs={6} lg={2}>
                    {amount && (
                        <Typography className={classes.Amount} variant="subtitle2" component="p">
                            $ {thousandSeparator(amount, '.')}
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={6} lg={6}>
                    <Typography className={classes.Caption} variant="caption" component="p">
                        {caption}
                    </Typography>
                </Grid>
                <Grid item xs={6} lg={6} className={!amount ? 'displayGrid' : ''}>
                    <Typography className={classes.PriceCaption} variant="caption" component="p">
                        {priceCaption}
                    </Typography>
                </Grid>
            </Grid>
        </div>
    );
};

const BNPL2RadioBoxButton: FC<IBNPL2BoxButtonProps> = ({
    label,
    amount,
    initialQuota,
    quota,
    isBnpl,
    expanded,
    caption,
    date,
    priceCaption,
    defaultExpanded = false
}) => {
    const classes = useStyles();
    const theme = useTheme();
    return (
        <Accordion
            className={classes.BNPL2RadioBoxButton}
            defaultExpanded={defaultExpanded}
            expanded={expanded}>
            <AccordionSummary
                className={classes.BNPL2Summary}
                expandIcon={<ExpandMoreIcon className={classes.ExpandMoreIcon} />}>
                <Grid container>
                    <Grid item xs={12} lg={12}>
                        <Typography className={classes.Label} variant="subtitle2" component="p">
                            {label}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} lg={6}>
                        <Typography className={classes.Caption} variant="caption" component="p">
                            {caption}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} lg={6}>
                        <Typography
                            className={classes.PriceCaption}
                            variant="caption"
                            component="p">
                            {priceCaption}
                        </Typography>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                {isBnpl ? (
                    <ul className={classes.QuotaInfoList}>
                        <li>
                            <Typography className={classes.CaptionCreditDetails}>
                                Monto del pago inicial: {initialQuota}
                            </Typography>
                        </li>
                        {quota && (
                            <li>
                                <Typography className={classes.CaptionCreditDetails}>
                                    {quota} cuotas posteriores: $ {thousandSeparator(amount, '.')}
                                </Typography>
                            </li>
                        )}
                        <li>
                            <Typography className={classes.CaptionCreditDetails}>
                                {priceCaption}
                            </Typography>
                        </li>
                        <li>
                            <Typography>
                                El “Pago inicial” se cobrará inmediatamente, sin intereses.
                                Corresponde a un porcentaje de la compra total y este porcentaje es
                                de acuerdo a cada comercio. Las cuotas seleccionadas se reflejarán
                                en tus próximos estados de cuenta de tu{' '}
                                <span style={{ color: theme.palette.primary.main }}>
                                    Tarjeta de Crédito Digital sabbi.
                                </span>
                            </Typography>
                        </li>
                        <li>
                            <Typography>
                                Recuerda que sólo el Pago inicial utilizará el cupo de tu tarjeta
                                asociada, las cuotas siguientes se irán cobrando en los periodos de
                                facturación correspondientes.
                            </Typography>
                        </li>
                        {date && (
                            <li>
                                <Typography>
                                    La cuota que sigue después del “pie” tiene fecha de vencimiento
                                    el {date}
                                </Typography>
                            </li>
                        )}
                    </ul>
                ) : (
                    <ul className={classes.QuotaInfoList}>
                        <li>
                            <Typography>Pago único de: {priceCaption}</Typography>
                        </li>
                    </ul>
                )}
            </AccordionDetails>
        </Accordion>
    );
};

export const BNPLRadioButton: FC<IBNPLRadioButtonProps> = ({
    label,
    value,
    classNames = '',
    isChecked,
    amount,
    caption,
    priceCaption
}) => {
    const classes = useStyles();
    return (
        <div className={classes.RadioButtonContainer}>
            <FormControlLabel
                className={`${classNames} ${classes.CardRadioButton} ${
                    isChecked ? 'checked' : null
                }`}
                value={value}
                control={<Radio className={classes.Radio} />}
                labelPlacement="start"
                label={
                    <RadioBoxButton
                        label={label}
                        amount={amount}
                        caption={caption}
                        priceCaption={priceCaption}
                    />
                }
            />
        </div>
    );
};

export const BNPL2RadioButton: FC<IBNPL2RadioButtonProps> = ({
    label,
    value,
    classNames = '',
    isChecked,
    amount,
    initialQuota,
    quota,
    isBnpl,
    date,
    expanded,
    caption,
    priceCaption
}) => {
    const classes = useStyles();
    return (
        <div className={classes.RadioButtonContainer}>
            <FormControlLabel
                className={`${classNames} ${classes.CardRadioButton} ${
                    isChecked ? 'checked' : null
                }`}
                value={value}
                control={<Radio className={classes.Radio} />}
                labelPlacement="start"
                label={
                    <BNPL2RadioBoxButton
                        label={label}
                        expanded={expanded}
                        amount={amount}
                        date={date}
                        isBnpl={isBnpl}
                        initialQuota={initialQuota}
                        quota={quota}
                        caption={caption}
                        priceCaption={priceCaption}
                    />
                }
            />
        </div>
    );
};
