import { createContext, ReactNode, useContext, useMemo, useState } from 'react';

import { getLocalStorageItem } from '../../utils/localStorageManipulation';
import { IBusinessContext, NewFinancialProducts } from './interfaces/IBusinessContext';

const mapFpFromLocalStorage = () => {
    let defaultFinacialProducts: NewFinancialProducts = {
        bnpl: false,
        oneclick: false,
        webpayplus: false
    };
    let fp: NewFinancialProducts | null = null;
    fp = getLocalStorageItem('FP');
    if (fp) {
        defaultFinacialProducts = fp;
    }
    return defaultFinacialProducts;
};

const setDefaultValues = (): IBusinessContext => {
    const defaultValues: IBusinessContext = {
        financialProducts: {
            bnpl: false,
            oneclick: false,
            webpayplus: false
        },
        checkFinancialProducts: () => false,
        updateFinancialProducts: () => null
    };
    return defaultValues;
};

const businessContext = createContext(setDefaultValues());

const useProvideBusiness = () => {
    const [financialProducts, setFinancialProducts] = useState<NewFinancialProducts>(
        mapFpFromLocalStorage()
    );

    const updateFinancialProducts = (fP: NewFinancialProducts) => {
        const businessProducts = fP;
        setFinancialProducts(businessProducts);
    };

    const checkFinancialProducts = () => {
        if (financialProducts) {
            return (
                'bnpl' in financialProducts &&
                'oneclick' in financialProducts &&
                'webpayplus' in financialProducts
            );
        }
        return false;
    };

    return {
        financialProducts,
        checkFinancialProducts,
        updateFinancialProducts
    };
};

export const ProvideBusiness = ({ children }: { children: ReactNode }) => {
    const business: IBusinessContext = useProvideBusiness();
    const memoizedBusiness = useMemo(
        () => ({
            financialProducts: business.financialProducts,
            checkFinancialProducts: business.checkFinancialProducts,
            updateFinancialProducts: business.updateFinancialProducts
        }),
        [business.financialProducts]
    );
    return (
        <businessContext.Provider value={memoizedBusiness}> {children} </businessContext.Provider>
    );
};

export const useBusiness = () => {
    return useContext(businessContext);
};
