import { Button } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { FC } from 'react';

import sabbiLogo from '../../assets/logo/sabbi.svg';
import RoundButton from '../FormsContext/RoundButton/RoundButton';
import { ISuccessMessageProps } from './interfaces/ISuccessMessageProps';
import useStyles from './SuccessMessageStyles.material';

const SuccessMessage: FC<ISuccessMessageProps> = ({
    success,
    classNames = '',
    title,
    cancelOnClick,
    continueOnClick,
    cancelText,
    continueText,
    disclaimerContent,
    showSabbiLogo = true,
    children,
    showSuccessIcon
}) => {
    const classes = useStyles();

    const renderIcon = () => {
        return success ? (
            <CheckCircleIcon className={`${classes.Icon} ${classes.SuccessIcon}`} />
        ) : (
            <CancelIcon className={`${classes.Icon} ${classes.ErrorIcon}`} />
        );
    };

    return (
        <div className={`${classNames}`}>
            {showSabbiLogo && (
                <div style={{ justifyContent: 'center', display: 'flex' }}>
                    <img src={sabbiLogo} alt="sabbiLogo" className={classes.sabbiImg} />
                </div>
            )}
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '24px' }}>
                <Typography className={classes.Title} variant="h2" component="h2">
                    {title}
                </Typography>
                {showSuccessIcon && renderIcon()}
            </div>
            {children}
            {disclaimerContent && <div className={classes.DisclaimerBox}>{disclaimerContent}</div>}
            <div className={classes.ButtonsBox}>
                {continueText && (
                    <RoundButton
                        aria-label="redirect-button"
                        classNames={classes.RoundButton}
                        type="button"
                        color="primary"
                        onClick={continueOnClick}>
                        {continueText}
                    </RoundButton>
                )}
                {cancelText && (
                    <Button className={classes.CancelButton} type="button" onClick={cancelOnClick}>
                        {cancelText}
                    </Button>
                )}
            </div>
        </div>
    );
};

export default SuccessMessage;
