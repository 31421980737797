import sabbiHttpInstance from '../../instances/sabbi.instance';
import { IChangePassword } from './interfaces/IChangePassword';
import { ILogin } from './interfaces/ILogin';
import { ILoginResponse } from './interfaces/ILoginResponse';
import { ILogout } from './interfaces/ILogout';
import { ILogoutResponse } from './interfaces/ILogoutResponse';
import { IRecoveryPasswordResponse } from './interfaces/IRecoveryPasswordResponse';
import { IRegister } from './interfaces/IRegister';
import { IRegisterResponse } from './interfaces/IRegisterResponse';
import { IValidateDocumentNumber } from './interfaces/IValidateDocumentNumber';
import { IValidateDocumentNumberResponse } from './interfaces/IValidateDocumentNumberResponse';

export const loginService = async (data: ILogin): Promise<ILoginResponse> => {
    const {
        data: { payload }
    } = await sabbiHttpInstance.post(`/user/login`, data);
    return payload;
};

export const logoutService = async (data: ILogout): Promise<ILogoutResponse> => {
    const {
        data: { payload }
    } = await sabbiHttpInstance.post(`/user/logout`, data);
    return payload;
};

export const registerService = async (data: IRegister): Promise<IRegisterResponse> => {
    const {
        data: { payload }
    } = await sabbiHttpInstance.post(`/user/register`, data);
    return payload;
};

export const validateDocumentNumberService = async (
    data: IValidateDocumentNumber
): Promise<IValidateDocumentNumberResponse> => {
    const {
        data: { payload }
    } = await sabbiHttpInstance.post(`/user/validate-document-number`, data);
    return payload;
};

export const changePasswordService = async (
    data: IChangePassword
): Promise<IRecoveryPasswordResponse> => {
    const {
        data: { payload }
    } = await sabbiHttpInstance.post(`/user/recovery-password`, data);
    return payload;
};
