import sabbiHttpInstance from '../../instances/sabbi.instance';
import { ICard } from './interfaces/ICard';
import { ICardDebitSave } from './interfaces/ICardDebitSave';
import { ICardDebitSaveResponse } from './interfaces/ICardDebitSaveResponse';
import { ICardSubscribeDebitResponse } from './interfaces/ICardSubscribeDebitResponse';
import { IDeleteCard } from './interfaces/IDeleteCard';
import { ISubscribe } from './interfaces/ISubscribe';
import { ITE } from './interfaces/ITE';
import { IUserSubscribedCardsQuantityResponse } from './interfaces/IUserSubscribedCardsQuantityResponse';
import { IValidateSubscribe } from './interfaces/IValidateSubscribe';
import { IValidateSubscribeResponse } from './interfaces/IValidateSubscribeResponse';

export const cardSubscribeDebitService = async (
    data: ISubscribe
): Promise<ICardSubscribeDebitResponse> => {
    const {
        data: { payload }
    } = await sabbiHttpInstance.post(`/card/card-subscribe-debit`, data);
    return payload;
};

export const validateAndsubscribeService = async (
    data: IValidateSubscribe
): Promise<IValidateSubscribeResponse> => {
    const {
        data: { payload }
    } = await sabbiHttpInstance.post(`/card/validate-subscribe`, data);
    return payload;
};

export const cardDebitSaveService = async (
    data: ICardDebitSave
): Promise<ICardDebitSaveResponse> => {
    const {
        data: { payload }
    } = await sabbiHttpInstance.post(`/card/card-debit-save`, data);
    return payload;
};

export const subscriptionCancelService = async (data: IDeleteCard): Promise<{ te: string }> => {
    const {
        data: { payload }
    } = await sabbiHttpInstance.post(`/card/subscription-cancel`, data);
    return payload;
};

export const userSubscribedCardsService = async (data: ITE): Promise<ICard[]> => {
    const {
        data: { payload }
    } = await sabbiHttpInstance.post(`/card/user-subscribed-cards`, data);
    return payload;
};

export const userSubscribedCardsQuantity = async (
    data: ITE
): Promise<IUserSubscribedCardsQuantityResponse> => {
    const {
        data: { payload }
    } = await sabbiHttpInstance.post(`/card/user-subscribed-cards-count`, data);
    return payload;
};
