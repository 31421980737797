import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    SuccessCheckoutTable: {
        boxShadow: '0px 1px 8px 0px rgba(0, 0, 0, 0.25)',
        marginTop: '16px',
        borderRadius: '18px',
        '& .MuiTableCell-body': {
            marginTop: '50px',
            fontSize: '16px',
            wordBreak: 'break-all'
        }
    },
    DescriptionCell: {
        fontFamily: 'robotoBold',
        fontSize: '16px',
        padding: '16px',
    },
    ValueCell: {
        fontSize: '16px',
        padding: '16px',
    },
    TotalPrice: {
        fontFamily: 'robotoBold',
        textAlign: 'right',
        fontSize: '16px',
    },
    ShopName: {
        fontFamily: 'robotoBold',
        fontSize: '16px',
    },
    containerTotal: {
        borderRadius: '18px 18px 0 0',
        padding: '16px',
        backgroundColor: theme.palette.grey[200]
    }
}));

export default useStyles;
