import { HttpStatus } from '../../enums/HttpStatus';
import { IApplicationError } from '../ApplicationError';

export const SABBI_FRONT_ERRORS: Record<string, IApplicationError> = {
    SABBI_F_01: {
        code: 'SABBI_F_01',
        status: HttpStatus.UNAUTHORIZED,
        message: 'Transacción inválida.'
    },
    SABBI_F_03: {
        code: 'SABBI_F_03',
        status: HttpStatus.UNAUTHORIZED,
        message: 'Invalid TE'
    },
    SABBI_F_05: {
        code: 'SABBI_F_05',
        status: HttpStatus.UNAUTHORIZED,
        message: 'Error en cifrado.'
    },
    SABBI_F_07: {
        code: 'SABBI_F_07',
        status: HttpStatus.UNAUTHORIZED,
        message: 'Error en encriptado.'
    },
    SABBI_F_08: {
        code: 'SABBI_F_08',
        status: HttpStatus.UNAUTHORIZED,
        message: 'El RUT ingresado es inválido.'
    },
    SABBI_F_11: {
        code: 'SABBI_F_11',
        status: HttpStatus.UNAUTHORIZED,
        message: 'Objeto no válido para encadenar.'
    },
    SABBI_F_12: {
        code: 'SABBI_F_12',
        status: HttpStatus.UNAUTHORIZED,
        message: 'No es posible ingresar esta tarjeta.'
    }
};

export const KUSHKI_ERRORS: Record<string, IApplicationError> = {
    KDEFAULT: {
        code: 'KDEFAULT',
        status: HttpStatus.BAD_REQUEST,
        message: 'Ocurrió un error inesperado, intente más tarde.'
    },
    K006: {
        code: 'K006',
        status: HttpStatus.UNAUTHORIZED,
        message: 'No es posible registrar esta tarjeta.'
    }
};
