import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    ModalError: {
        '& .MuiPaper-root': {
            padding: '3rem',
            borderRadius: '20px',
            ['@media (max-width: 600px)']: {
                padding: '2rem'
            },
            ['@media (max-width: 400px)']: {
                padding: '1rem'
            },
            ['@media (max-width: 320px)']: {
                padding: '.8rem'
            }
        },
        '& .MuiDialog-paperWidthSm': {
            maxWidth: '700px'
        }
    },
    DialogTitle: {
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& h3': {
            fontFamily: 'robotoMedium',
            margin: '1rem 0',
            fontSize: '1.2rem',
            color: theme.palette.grey[800]
        }
    },
     DialogContent: {
        padding: '0 50px',
        color: theme.palette.grey[800],
        fontSize: '1rem',
        textAlign: 'center',
        '@media (max-width:500px)': {
            padding: 0
        }
    },
    DialogActions: {
        maxWidth: '100%',
        flexWrap: 'wrap',
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        '&.customFooter': {
            padding: 0
        }
    },
    DialogButton: {
        marginBottom: 0
    },
}));

export default useStyles;
