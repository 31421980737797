import { FieldPath, FieldValues, RegisterOptions } from 'react-hook-form/dist/types';

export type IBNPLRadioButton = {
    label: string;
    amount: number;
    caption: string;
    priceCaption: string;
    date?: string;
    initialQuota?: number;
    quota?: string | number;
    value: string;
    key: string;
    isBnpl: boolean;
};

export default interface IBNPLRadioGroupProps {
    id: string;
    name: string;
    classNames?: string;
    onChange?: (k?: string | number | undefined) => unknown;
    rules?: RegisterOptions<FieldValues, FieldPath<FieldValues>>;
    defaultValue?: string;
    defaultHelperText?: string;
    radioButtons: Array<IBNPLRadioButton>;
}

export const PAGOPSPVALUE = 'PAGO-PSP-UNICO';
