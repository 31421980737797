import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    WhatIsSabbiBox: {
        padding: "1.2rem",
        margin: "1rem 0",
        borderRadius: "20px",
        display: "flex",
    },
    WhatIsSabbiBox_Title: {
        color: theme.palette.primary.main,
        fontSize: '1rem',
        fontFamily: 'robotoBold',
        marginBottom: '8px'
    }
}));

export default useStyles;
