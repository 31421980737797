import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    SelectCardPage: {
        maxWidth: '100%',
        '& h2': {
            textAlign: 'center',
            marginBottom: '45px'
        }
    },
    SabbiLogo: {
        width: '60px',
        height: '60px',
        display: 'flex',
        alignSelf: 'center',
        margin: '0 auto 1.9rem auto'
    },
    AlertBar: {
        '&.opened': {
            marginBottom: '1rem'
        }
    },
    HyperLinkBox: {
        maxWidth: '100%',
        marginBottom: '10px',
        ['@media (max-width:959px)']: {
            width: '650px',
            margin: 'auto',
            marginBottom: '10px'
        }
    },
    HyperLink_GoBack: {
        ['@media (max-width:959px)']: {
            marginLeft: '-9px'
        },
        '& a': {
            fontFamily: 'robotoMedium',
            display: 'flex',
            alignItems: 'center'
        }
    },
    MainGrid: {
        ['@media (max-width:959px)']: {
            flexFlow: 'column-reverse'
        }
    },
    SelectedCard: {
        marginBottom: '29px'
    },
    BNPLLoader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    Loader: {
        width: '100px',
        objectFit: 'contain',
        ['@media (max-width:720px)']: {
            width: '80px'
        },
        ['@media (max-width:425px)']: {
            width: '50px'
        }
    },
    ContentBox: {
        display: 'flex',
        flexDirection: 'column',
        ['@media (max-width:959px)']: {
            margin: 'auto'
        }
    },
    SelectCard_Text: {
        fontSize: '1rem',
        fontFamily: 'robotoBold',
        marginBottom: '1rem',
        textAlign: 'center'
    },
    AccordionCollapse: {
        marginBottom: '10px',
        '&.MuiAccordion-rounded:first-child': {
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px'
        },
        '& .MuiAccordionDetails-root': {
            flexWrap: 'wrap',
            padding: '8px 24px 16px'
        },
        '@media (min-width:960px) and (max-width:1060px)': {
            '& [class*="Dots"]': {
                fontSize: '2rem',
                letterSpacing: '4px'
            }
        },
        '@media (max-width:520px)': {
            '& [class*="Dots"]': {
                fontSize: '2rem',
                letterSpacing: '4px'
            }
        },
        '@media (max-width:480px)': {
            '& [class*="CardBrand"]': {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                minWidth: '50px',
                width: '50px'
            }
        },
        '@media (max-width:425px)': {
            '& [class*="CardBrand"]': {
                display: 'none'
            }
        }
    },
    CardRadioGroup: {
        flex: '100%',
        '& .MuiFormControlLabel-root img': {
            maxWidth: '40px',
            maxHeight: '30px'
        }
    },
    DeleteIcon: {
        marginLeft: '13px'
    },
    AddIcon: {
        marginLeft: '.7rem',
        height: '24px',
        color: theme.palette.common.black,
        '&.disabled': {
            color: theme.palette.grey[400]
        }
    },
    HeadingIcon: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '10px'
    },
    HeadingText: {
        fontFamily: 'robotoBold',
        fontSize: '.9rem'
    },
    ButtonsDiv: {
        display: 'flex',
        justifyContent: 'space-around',
        margin: '.7rem 0',
        width: '100%',
        '@media (max-width:450px)': {
            alignItems: 'center',
            justifyContent: 'center'
        }
    },
    DeleteCardButton: {
        flex: 1,
        color: theme.palette.common.black,
        border: 'none',
        fontSize: '14px',
        maxWidth: '164px',
        fontFamily: 'robotoMedium'
    },
    AddCardButton: {
        border: 'none',
        color: theme.palette.common.black,
        flex: 1,
        fontFamily: 'robotoMedium',
        fontSize: '14px',
        maxWidth: '164px',
        width: '100%'
    },
    Form: {
        display: 'flex',
        flexDirection: 'column',
        '& .MuiFormControl-root': {
            maxWidth: '100%',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    },
    MaxCards: {
        marginBottom: '1rem'
    },
    BNPLRadioGroup: {
        width: '100%'
    },
    SubmitButton: {
        margin: '0',
        alignSelf: 'center',
        width: '500px',
        backgroundColor: theme.palette.common.white
    },
    BtnCardText: {
        '@media (max-width:500px)': {
            display: 'none'
        }
    },
    ButtonsBox: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        maxWidth: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: '10px',
        marginTop: '1rem',
        justifyContent: 'space-between'
    },
    WebPayPlus: {
        marginLeft: 'initial'
    },
    GuestButton: {
        maxWidth: '275px',
        alignSelf: 'center',
        backgroundColor: theme.palette.common.white,
        padding: '.55rem 20px',
        color: theme.palette.grey[800],
        '& img': {
            marginLeft: '.7rem'
        }
    },
    DeleteModalTitle: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column'
    },
    InfoIcon: {
        marginTop: '10px',
        marginBottom: '27px',
        width: '45px',
        height: '45px'
    },
    ModalDeleteCard_Text: {
        textAlign: 'center',
        maxWidth: '460px',
        margin: '15px auto 50px auto',
        fontSize: '16px',
        '@media (max-width:500px)': {
            margin: '20px auto'
        }
    },
    CheckIcon: {
        marginTop: '30px',
        marginBottom: '30px',
        height: '40px',
        width: '40px'
    },
    ModalDialogSubmitButton: {
        maxWidth: '225px',
        alignSelf: 'center',
        marginBottom: 0
    },
    ButtonTooltip: {
        flex: 1,
        maxWidth: '164px'
    },
    ModalSelectedCard: {
        width: '70%',
        '@media (max-width:500px)': {
            width: '100%',
            marginTop: '1rem'
        }
    }
}));

export default useStyles;
