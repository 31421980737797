import { useHistory } from 'react-router-dom';

import { useAuth } from '../context/AuthContext/auth';
import { useTpa } from '../context/TPAContext/tpa';
import { ELoginRoutePaths } from '../routes/enum/ELoginRoutePaths';
import { ENotAllowedRoutePaths } from '../routes/enum/ENotAllowedRoutePaths';
import { SABBI_FRONT_ERRORS } from '../utils/errors/enums/EAppErrors';
import { SERVICE_ERRORS } from '../utils/errors/enums/EServiceErrors';
import { IParsedError } from '../utils/errors/interfaces/IParsedError';

/**
 * Custom Hook to set redirection on handled errors
 */
export const useRedirection = () => {
    const history = useHistory();
    const tpaContext = useTpa();
    const authContext = useAuth();

    const handleErrorRedirection = (applicationError: IParsedError) => {
        const nullToken = {
            tpa: null,
            purchaseId: null
        };
        if (
            applicationError &&
            typeof applicationError === 'object' &&
            'code' in applicationError
        ) {
            switch ((applicationError as IParsedError).code) {
                case SERVICE_ERRORS.SABBI_16.code:
                case SERVICE_ERRORS.SABBI_17.code:
                case SABBI_FRONT_ERRORS.SABBI_F_01.code:
                    tpaContext.updateTokenInfo(nullToken);
                    history.replace(ENotAllowedRoutePaths.NOT_AUTHORIZED);
                    break;
                case SERVICE_ERRORS.SABBI_18.code:
                case SERVICE_ERRORS.SABBI_19.code:
                    authContext.cleanTE();
                    history.replace(ELoginRoutePaths.LOGIN);
                    break;
                default:
                    break;
            }
        }
    };

    const shouldRedirect = (applicationError: IParsedError) => {
        if (
            applicationError &&
            typeof applicationError === 'object' &&
            'code' in applicationError
        ) {
            switch (applicationError.code) {
                case SERVICE_ERRORS.SABBI_16.code:
                case SERVICE_ERRORS.SABBI_17.code:
                case SERVICE_ERRORS.SABBI_18.code:
                case SERVICE_ERRORS.SABBI_19.code:
                case SABBI_FRONT_ERRORS.SABBI_F_01.code:
                    return true;
                default:
                    return false;
            }
        }
        return false;
    };

    return { handleErrorRedirection, shouldRedirect };
};
