import { Box, Paper, Typography } from '@material-ui/core';
import { FC } from 'react';

import CreditCardIcon from '../../assets/icons/creditCard.svg';
import ShoppingCartIcon from '../../assets/icons/shoppingCart.svg';
import SabbiNoBorderIcon from '../../assets/logo/sabbi-noborder.svg';
import { ESabbiBoxIcons } from './enums/ESabbiBoxIcons';
import { IWhatIsSabbiBox } from './interfaces/IWhatIsSabbiBox';
import useStyles from './WhatIsSabbiBoxStyles.material';

const WhatIsSabbiBox: FC<IWhatIsSabbiBox> = ({ className = '', children, title, icon }) => {
    const classes = useStyles();

    const renderIcon = (icon: ESabbiBoxIcons) => {
        switch (icon) {
            case ESabbiBoxIcons.creditCard:
                return <img src={CreditCardIcon} alt={ESabbiBoxIcons.creditCard} />;
            case ESabbiBoxIcons.shoppingCart:
                return <img src={ShoppingCartIcon} alt={ESabbiBoxIcons.shoppingCart} />;
            default:
                return <img src={SabbiNoBorderIcon} alt={'sabbi'} />;
        }
    };

    return (
        <Paper className={`${classes.WhatIsSabbiBox} ${className}`}>
            <Box sx={{ mr: '1rem' }}>{renderIcon(icon)}</Box>
            <Box>
                <Typography className={classes.WhatIsSabbiBox_Title}>{title}</Typography>
                {children}
            </Box>
        </Paper>
    );
};

export default WhatIsSabbiBox;
