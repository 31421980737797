import RobotoBoldFont from '../assets/fonts/Roboto/Roboto-Bold.ttf';
import RobotoLightFont from '../assets/fonts/Roboto/Roboto-Light.ttf';
import RobotoMediumFont from '../assets/fonts/Roboto/Roboto-Medium.ttf';
import RobotoRegularFont from '../assets/fonts/Roboto/Roboto-Regular.ttf';

export const robotoLight = {
    fontFamily: 'RobotoLight',
    fontStyle: 'normal',
    fontWeight: 300,
    src: `local('RobotoLight'), url(${RobotoLightFont})`,
    unicodeRange:
        'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF'
};

export const robotoRegular = {
    fontFamily: 'RobotoRegular',
    fontStyle: 'normal',
    fontWeight: 400,
    src: `local('RobotoRegular'), url(${RobotoRegularFont})`,
    unicodeRange:
        'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF'
};
export const robotoMedium = {
    fontFamily: 'RobotoMedium',
    fontStyle: 'normal',
    fontWeight: 500,
    src: `local('RobotoMedium'), url(${RobotoMediumFont})`,
    unicodeRange:
        'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF'
};
export const robotoBold = {
    fontFamily: 'RobotoBold',
    fontStyle: 'normal',
    fontWeight: 700,
    src: `local('RobotoBold'), url(${RobotoBoldFont})`,
    unicodeRange:
        'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF'
};
