import { Grid, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import SabbiLogoSrc from '../../assets/logo/sabbi.svg';
import BnplDetailTable from '../../components/BnplDetailTable/BnplDetailTable';
import { IDetailBnpl } from '../../components/BnplDetailTable/interfaces/IDetailBnpl';
import ContentBox from '../../components/ContentBox/ContentBox';
import { BNPLRadioButton } from '../../components/FormsContext/BNPLRadioButton/BNPLRadioButton';
import RoundButton from '../../components/FormsContext/RoundButton/RoundButton';
import HyperLink from '../../components/HyperLink/HyperLink';
import ProgressCircular from '../../components/ProgressCircular/ProgressCircular';
import RejectedBNPL from '../../components/RejectedBNPL/RejectedBNPL';
import SelectedCard from '../../components/SelectedCard/SelectedCard';
import SuccessCheckoutTable from '../../components/SuccessCheckoutTable/SuccessCheckoutTable';
import SuccessMessage from '../../components/SuccessMessage/SuccessMessage';
import { useAuth } from '../../context/AuthContext/auth';
import { useBackdrop } from '../../context/BackdropContext/backdrop';
import { useTpa } from '../../context/TPAContext/tpa';
import { useQuery } from '../../hooks/useQuery';
import { useRedirection } from '../../hooks/useRedirection';
import { ECardRoutePaths } from '../../routes/enum/ECardRoutePaths';
import { ELoginRoutePaths } from '../../routes/enum/ELoginRoutePaths';
import { webPayPlusTransaction } from '../../services/sabbi/transaction/transaction';
import { ECardType } from '../../shared/enums/ECardType';
import { thousandSeparator } from '../../utils/clientHelpers';
import { extractPayloadFromJWT } from '../../utils/cryptography/JWManipulation/jwt';
import { getDataDevice } from '../../utils/device/dataDeviceHelpers';
import { IDevice } from '../../utils/device/interfaces/IDevice';
import { EPaymentCategory } from '../../utils/enums/EPaymentCategory';
import { serviceErrorMapper } from '../../utils/errors';
import {
    deleteLocalStorageAccessData,
    getTPAInformation
} from '../../utils/localStorageManipulation';
import { removeLocalStorageItem } from '../../utils/localStorageManipulation/index';
import { IBnplPayments } from '../CardPaymentDetailPage/interfaces/IBnplPayments';
import useStyles from './CheckoutPageStyles.material';
import { ICheckoutPageLocationState } from './interfaces/ICheckoutPageLocationState';
import { ICheckoutSuccesInfoState } from './interfaces/ICheckoutSuccessInfoState';
import { ISelectedBNPLState } from './interfaces/ISelectedBNPLState';
import { ITableData } from './interfaces/ITableData';
import { ITransactionResponse } from './interfaces/ITransactionResponse';

type BnplInformationState = {
    detailBnpl: IDetailBnpl;
    selectedBnpl: ISelectedBNPLState;
};

const CheckoutPage: FC = () => {
    const [paymentSuccess, setPaymentSuccess] = useState<boolean>(false);
    const [isBusy, setIsBusy] = useState<boolean>(true);
    const [tableData, setTableData] = useState<Array<Record<string, string>>>([{}]);
    const [tableHeaders, setTableHeaders] = useState<Array<Record<string, string>>>([{}]);
    const [CheckoutInfo, setCheckoutInfo] = useState<ICheckoutSuccesInfoState>();
    const [bnplInformation, setBnplInformation] = useState<BnplInformationState>();
    const [BnplPayments, setBnplPayments] = useState<IBnplPayments[]>([]);
    const [businessPartnerUrl, setBusinessPartnerUrl] = useState<string>('');
    const [hasBnpl, setHasBnpl] = useState<boolean>(false);

    const classes = useStyles();
    const { handleErrorRedirection, shouldRedirect } = useRedirection();
    const location = useLocation<ICheckoutPageLocationState>();
    const backdrop = useBackdrop();
    const history = useHistory();
    const tpaContext = useTpa();
    const authContext = useAuth();
    const query = useQuery(location.search);

    useEffect(() => {
        const token = query.get('token');
        removeLocalStorageItem('publicMerchantId');
        if (token) {
            handleWPTransaction(token);
        } else {
            if (validateLocationStateData(location.state)) {
                handleOneClickTransaction();
            } else {
                if (!authContext.user?.te) {
                    history.replace(ELoginRoutePaths.LOGIN);
                } else {
                    history.replace(ECardRoutePaths.CARD_SELECT);
                }
            }
        }
    }, []);

    useEffect(() => {
        if (businessPartnerUrl !== '') {
            handleTimeOut();
        }
    }, [businessPartnerUrl]);

    /**
     * Gets TPA Information
     * @param {ICheckoutPageLocationState} obj Location state object
     * @return {boolean}
     */
    const validateLocationStateData = (obj: ICheckoutPageLocationState): boolean => {
        if (obj) {
            return 'success' in obj && 'transaction' in obj;
        }
        return false;
    };

    /**
     * Handle WebPayPlus Transaction
     * @param {string} token token obtained from query params
     * @return {Promise<void>}
     */
    const handleWPTransaction = async (token: string): Promise<void> => {
        backdrop.openBackdrop();
        let businessUrl;
        try {
            const tpaInfo = await getTPAInformation(tpaContext.tokenInfo.tpa);
            businessUrl = tpaInfo.bodyTPA.businessPartner?.responseUrl as string;
            const device: IDevice = getDataDevice();
            const requestGetStatus = {
                token,
                TPA: tpaContext.tokenInfo.tpa as string,
                TE: authContext.user?.te as string,
                device
            };
            const responseGetStatus = await webPayPlusTransaction(requestGetStatus);
            const extractedTT = extractPayloadFromJWT(responseGetStatus.TT);
            handleTableHeadersFromLocation(extractedTT as ITransactionResponse);
            handleTableDataFromLocation(extractedTT as ITransactionResponse);
            handleCheckoutInfoFromLocation(extractedTT as ITransactionResponse);
            setBusinessPartnerUrl(`${businessUrl}/?TT=${responseGetStatus.TT}`);
            setPaymentSuccess(true);
        } catch (error: any) {
            if (shouldRedirect(error)) {
                handleErrorRedirection(error);
            } else {
                const serviceError = serviceErrorMapper(error);
                setBusinessPartnerUrl(`${businessUrl}/?TT=${serviceError.payload?.TT}`);
                setPaymentSuccess(false);
            }
        } finally {
            setIsBusy(false);
            backdrop.closeBackdrop();
        }
    };

    /**
     * Handle OneClick Transaction
     * @return {Promise<void>}
     */
    const handleOneClickTransaction = async (): Promise<void> => {
        backdrop.openBackdrop();
        let businessUrl;
        try {
            const tpaInfo = await getTPAInformation(tpaContext.tokenInfo.tpa);
            businessUrl = tpaInfo.bodyTPA.businessPartner?.responseUrl as string;
            if (location.state.success) {
                const extractedTT: ITransactionResponse = extractPayloadFromJWT(
                    location.state.transaction.TT
                );
                switch (location.state.paymentMethods) {
                    case EPaymentCategory.PSP:
                        handleTableHeadersFromLocation(extractedTT as ITransactionResponse);
                        handleTableDataFromLocation(extractedTT as ITransactionResponse);
                        handleCheckoutInfoFromLocation(extractedTT as ITransactionResponse);
                        break;
                    case EPaymentCategory.BNPL:
                        if (extractedTT.metaInfo.transaction.bnpl) {
                            const fullBnplInformation: BnplInformationState = {
                                detailBnpl: {
                                    initialQuota: `$ ${thousandSeparator(
                                        Number(extractedTT.metaInfo.transaction.bnpl.initialQuota),
                                        '.'
                                    )}`,
                                    initialQuotaPlusCtc: `$ ${thousandSeparator(
                                        Number(
                                            extractedTT.metaInfo.transaction.bnpl
                                                .initialQuotaPlusCtc
                                        ),
                                        '.'
                                    )}`,
                                    ctc: `$ ${thousandSeparator(
                                        extractedTT.metaInfo.transaction.bnpl.CTC,
                                        '.'
                                    )}`,
                                    cae: String(extractedTT.metaInfo.simulationSelected?.cae),
                                    interest: `${extractedTT.metaInfo.simulationSelected!.interes}`,
                                    ite: `$ ${thousandSeparator(
                                        extractedTT.metaInfo.transaction.bnpl.ITE,
                                        '.'
                                    )}`,
                                    annualRate: `${extractedTT.metaInfo.transaction.bnpl.tasaAnual}`,
                                    term: extractedTT.metaInfo.simulationSelected!.plazo,
                                    quotaValue: `$ ${thousandSeparator(
                                        extractedTT.metaInfo.transaction.bnpl.valorCuota,
                                        '.'
                                    )}`,
                                    expirationDate: String(
                                        moment(
                                            String(
                                                extractedTT.metaInfo.transaction.bnpl
                                                    ?.fechaVencimiento
                                            )
                                        ).format('DD/MM/YYYY')
                                    ),
                                    transactionTotal: `$ ${thousandSeparator(
                                        Number(extractedTT.metaInfo.transaction.total),
                                        '.'
                                    )}`
                                },
                                selectedBnpl: {
                                    label: `Pago inicial (Pie) + ${extractedTT.metaInfo.simulationSelected?.plazo} cuotas`,
                                    amount: Number(
                                        extractedTT.metaInfo.simulationSelected?.valorCuota
                                    ),
                                    caption: `Pago primera cuota  ${location.state.bnplDetailCuotas[0].date}`,
                                    priceCaption: `${extractedTT.metaInfo.transaction.bnpl?.CTC}`,
                                    tasaAnual: String(
                                        extractedTT.metaInfo.simulationSelected?.tasaAnual
                                    ),
                                    tasaInteres: String(
                                        extractedTT.metaInfo.simulationSelected?.tasaInteres
                                    ),
                                    value: `${extractedTT.metaInfo.simulationSelected?.id}`,
                                    key: `BNPL-${extractedTT.metaInfo.simulationSelected?.plazo}-${extractedTT.metaInfo.simulationSelected?.codAutorizacion}`,
                                    interes: Number(
                                        extractedTT.metaInfo.simulationSelected?.interes
                                    ),
                                    CAE: String(extractedTT.metaInfo.simulationSelected?.cae)
                                }
                            };
                            setBnplInformation(fullBnplInformation);

                            handleTableHeaders(
                                tpaInfo.bodyTPA.businessPartner?.businessPartnerName as string
                            );
                            const tableData = {
                                created: extractedTT.metaInfo.transaction.createdAt,
                                ticketNumber: extractedTT.metaInfo.transaction.trxId
                            };
                            handleTableData(tableData);
                            const checkoutInfo: ICheckoutSuccesInfoState = {
                                amount: Number(extractedTT.metaInfo.simulationSelected?.valorCuota),
                                cardType:
                                    extractedTT.metaInfo.transaction.bnpl?.card.subscriptionData
                                        .cardType === ECardType.CREDIT
                                        ? 'Crédito'
                                        : 'Débito',
                                numberCard: extractedTT.metaInfo.transaction.bnpl?.card
                                    .subscriptionData.maskedCardNumber
                                    ? extractedTT.metaInfo.transaction.bnpl?.card.subscriptionData
                                          .maskedCardNumber
                                    : '',
                                paymentBrand: extractedTT.metaInfo.transaction.bnpl?.card
                                    .subscriptionData.paymentBrand
                                    ? extractedTT.metaInfo.transaction.bnpl?.card.subscriptionData
                                          .paymentBrand
                                    : '',
                                trxPmi: extractedTT.metaInfo.transaction.trxPmi,
                                businessPartnerLogo: tpaInfo.bodyTPA.businessPartner
                                    ?.businessPartnerLogo as string,
                                merchantName: tpaInfo.bodyTPA.businessPartner
                                    ?.businessPartnerName as string
                            };
                            setBnplPayments(location.state.bnplDetailCuotas);
                            setCheckoutInfo(checkoutInfo);
                            setHasBnpl(true);
                        }
                        break;
                    default:
                        break;
                }
            }
            setBusinessPartnerUrl(`${businessUrl}/?TT=${location.state.transaction.TT}`);
            setPaymentSuccess(location.state.success);
        } catch (error) {
            const handledError = serviceErrorMapper(error);
            if (shouldRedirect(handledError)) {
                handleErrorRedirection(handledError);
            } else {
                setBusinessPartnerUrl(`${businessUrl}/?TT=${location.state.transaction.TT}`);
                setPaymentSuccess(false);
            }
        } finally {
            setIsBusy(false);
            backdrop.closeBackdrop();
        }
    };

    /**
     * Handle user auto redirection
     * @return {void}
     */
    const handleTimeOut = (): void => {
        setTimeout(() => {
            handleClose();
        }, 45000);
    };

    /**
     * Handle user redirection
     * @return {void}
     */
    const handleClose = (): void => {
        backdrop.openBackdrop();
        deleteLocalStorageAccessData();
        window.location.replace(businessPartnerUrl);
        backdrop.closeBackdrop();
    };

    /**
     * Sets table data (WebPayPLus)
     * @param {ITableData} tableData
     * @return {void}
     */
    const handleTableData = (tableData: ITableData) => {
        const dateInfo = tableData.created.split(' ');
        const date = dateInfo[0];
        const time = dateInfo[1];
        const TABLE_DATA: Array<Record<string, string>> = [
            { text: 'Fecha de compra', value: date },
            { text: 'Hora de compra', value: time },
            { text: 'Cód. autorización', value: tableData.ticketNumber }
        ];
        setTableData(TABLE_DATA);
    };

    /**
     * Sets table headers (WebPayPLus)
     * @param {string} businessPartner
     * @return {void}
     */
    const handleTableHeaders = (businessPartner: string): void => {
        const TABLE_HEADERS: Array<Record<string, string>> = [
            {
                text: 'Comercio',
                value: businessPartner
            }
        ];
        setTableHeaders(TABLE_HEADERS);
    };

    /**
     * Sets table data from location (OneClick)
     * @param {ITransactionResponse} transactionResponse
     * @return {void}
     */
    const handleTableDataFromLocation = (transactionResponse: ITransactionResponse): void => {
        const dateInfo = transactionResponse.metaInfo.transaction.createdAt.split(' ');
        const date = dateInfo[0];
        const time = dateInfo[1];
        const TABLE_DATA: Array<Record<string, string>> = [
            { text: 'Fecha de compra', value: date },
            { text: 'Hora de compra', value: time },
            {
                text: 'Cód. autorización',
                value: String(
                    transactionResponse.metaInfo.transaction.psp?.moreDetails?.ticketNumber
                )
            }
        ];
        setTableData(TABLE_DATA);
    };

    /**
     * Sets table headers from location (OneClick)
     * @param {ITransactionResponse} transactionResponse
     * @return {void}
     */
    const handleTableHeadersFromLocation = (transactionResponse: ITransactionResponse): void => {
        const TABLE_HEADERS: Array<Record<string, string>> = [
            {
                text: 'Comercio',
                value: transactionResponse?.metaInfo.transaction.psp?.moreDetails?.merchantName
                    ? transactionResponse?.metaInfo.transaction.psp?.moreDetails?.merchantName
                    : 'Comercio Asociado'
            }
        ];
        setTableHeaders(TABLE_HEADERS);
    };

    /**
     * Sets checkout information from location (OneClick)
     * @param {ITransactionResponse} transactionResponse
     * @return {Promise<void>}
     */
    const handleCheckoutInfoFromLocation = async (
        transactionResponse: ITransactionResponse
    ): Promise<void> => {
        const tpaInfo = await getTPAInformation(tpaContext.tokenInfo.tpa);
        const cardInfo: ICheckoutSuccesInfoState = {
            amount: Number(transactionResponse.metaInfo.transaction.total),
            cardType: transactionResponse.metaInfo.transaction.psp?.moreDetails?.binInfo.type || '',
            numberCard: `XXXX XXXX XXXX ${transactionResponse.metaInfo.transaction.psp?.moreDetails?.lastFourDigits}`,
            paymentBrand:
                transactionResponse.metaInfo.transaction.psp?.moreDetails?.paymentBrand || '',
            trxPmi: transactionResponse.metaInfo.transaction.trxPmi,
            businessPartnerLogo: tpaInfo.bodyTPA.businessPartner?.businessPartnerLogo as string,
            merchantName: String(
                transactionResponse.metaInfo.transaction.psp?.moreDetails?.merchantName
            )
        };
        setCheckoutInfo(cardInfo);
    };

    /**
     * Render success checkout
     */
    const renderSuccess = () => (
        <div className={classes.RenderCheckout}>
            <Grid container justifyContent="space-between" className={classes.HeaderSucces}>
                <Grid item lg={2} md={2}>
                    <img
                        className={classes.partnerLogo}
                        src={
                            CheckoutInfo?.businessPartnerLogo
                                ? CheckoutInfo?.businessPartnerLogo
                                : SabbiLogoSrc
                        }
                        alt="Company logo"
                    />
                </Grid>
                <Grid item lg={8} md={8}>
                    <Typography className={classes.Title} variant="h2" component="h2">
                        ¡Compra exitosa!
                    </Typography>
                    <Typography className={classes.Title} variant="h4" component="h4">
                        ID compra {CheckoutInfo?.trxPmi}
                    </Typography>
                </Grid>
                <Grid item lg={2} md={2}>
                    <CheckCircleIcon className={`${classes.SuccessIcon} success`} />
                </Grid>
            </Grid>

            <Grid container className={classes.bodySucces}>
                {!hasBnpl ? (
                    <>
                        <Typography className={classes.Title} variant="h4" component="h4">
                            Compra realizada con la tarjeta
                        </Typography>
                        <Grid item lg={12} xs={12}>
                            <SelectedCard
                                cardBrand={CheckoutInfo?.paymentBrand as string}
                                cardMaskedNumber={CheckoutInfo?.numberCard as string}
                                cardType={CheckoutInfo?.cardType as string}
                                classNames={classes.SelectedCard}
                            />
                        </Grid>
                    </>
                ) : (
                    <></>
                )}
                <BNPLRadioButton
                    classNames={classes.BNPLRadioButton}
                    isChecked={false}
                    key={`k-${bnplInformation?.selectedBnpl?.key}`}
                    label={
                        bnplInformation?.selectedBnpl?.label
                            ? bnplInformation.selectedBnpl?.label
                            : 'Total compra'
                    }
                    value={
                        bnplInformation?.selectedBnpl?.value
                            ? bnplInformation.selectedBnpl?.value
                            : ''
                    }
                    amount={
                        bnplInformation?.selectedBnpl?.amount
                            ? Number(bnplInformation.selectedBnpl?.amount)
                            : Number(CheckoutInfo?.amount)
                    }
                    caption={
                        bnplInformation?.selectedBnpl?.caption
                            ? bnplInformation.selectedBnpl?.caption
                            : ''
                    }
                    priceCaption={
                        bnplInformation?.selectedBnpl?.priceCaption
                            ? `Costo Total $ ${thousandSeparator(
                                  Number(bnplInformation.selectedBnpl?.priceCaption),
                                  '.'
                              )}`
                            : ''
                    }
                />
                {hasBnpl ? (
                    bnplInformation?.detailBnpl && (
                        <BnplDetailTable detailBNPL={bnplInformation?.detailBnpl} />
                    )
                ) : (
                    <></>
                )}

                <SuccessCheckoutTable
                    classNames={classes.CheckoutTable}
                    tableData={tableData}
                    tableHeaders={tableHeaders}
                />
                <div className={classes.ButtonsBox}>
                    <div className={classes.ProgressDiv}>
                        <ProgressCircular max={45} />
                        <Typography variant="caption" component="p">
                            Serás redirigido automáticamente al comercio
                        </Typography>
                    </div>

                    <RoundButton
                        aria-label="redirect-button"
                        classNames={classes.RoundButton}
                        type="button"
                        onClick={handleClose}>
                        Volver al comercio
                    </RoundButton>
                </div>
            </Grid>
        </div>
    );

    /**
     * Render error checkout
     */
    const renderError = () => (
        <SuccessMessage
            success={paymentSuccess}
            title={'No se pudo procesar tu pago'}
            continueOnClick={handleClose}
            cancelOnClick={handleClose}
            continueText={'Continuar'}>
            {location.state.paymentMethods === EPaymentCategory.BNPL &&
            location.state.bnplRejectionReasons ? (
                <div style={{ marginBottom: '1rem' }}>
                    <RejectedBNPL rejectionReasons={location.state.bnplRejectionReasons} />
                </div>
            ) : (
                <Typography component="p" className={classes.Message} gutterBottom>
                    <b>¡Lo sentimos!</b> Por error de sistema no podemos procesar tu transacción.
                    Por favor vuelve a intentarlo.
                </Typography>
            )}
            <div className={classes.ProgressDiv}>
                <ProgressCircular max={45} />
                <Typography variant="caption" component="p">
                    Serás redirigido automáticamente al comercio
                </Typography>
            </div>
        </SuccessMessage>
    );

    return !isBusy ? (
        <section className={classes.CheckoutPage}>
            <Grid item xs={12} lg={12}>
                <div className={classes.HyperLinkBox}>
                    <HyperLink
                        classNames={classes.HyperLink_GoBack}
                        underline="none"
                        onClick={handleClose}>
                        <ChevronLeftIcon />
                        volver al comercio
                    </HyperLink>
                </div>
            </Grid>
            <ContentBox classNames={paymentSuccess ? classes.ContentBox : undefined}>
                {paymentSuccess ? renderSuccess() : renderError()}
            </ContentBox>
        </section>
    ) : (
        <></>
    );
};
export default CheckoutPage;
