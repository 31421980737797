import { Button, Grid, Typography } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import EditIcon from '@material-ui/icons/Edit';
import HomeIcon from '@material-ui/icons/Home';
import MailIcon from '@material-ui/icons/Mail';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import WorkIcon from '@material-ui/icons/Work';
import { FC, useEffect, useState } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';

import SabbiLogo from '../../assets/logo/sabbi.svg';
import SabbiLogoNoBorderSrc from '../../assets/logo/sabbi-noborder.svg';
import AlertBar from '../../components/AlertBar/AlertBar';
import { IErrorValues } from '../../components/AlertBar/interfaces/IErrorValues';
import ContentBox from '../../components/ContentBox/ContentBox';
import { FormContext } from '../../components/FormsContext/FormContext/FormContext';
import GoogleAddressInput from '../../components/FormsContext/GoogleAddressInput/GoogleAddressInput';
import { IGoogleAddress } from '../../components/FormsContext/GoogleAddressInput/interfaces/IGoogleAddress';
import PhoneInput from '../../components/FormsContext/PhoneInput/PhoneInput';
import RoundButton from '../../components/FormsContext/RoundButton/RoundButton';
import TextInput from '../../components/FormsContext/TextInput/TextInput';
import HtmlTooltip, { ClickableTooltip } from '../../components/HtmlTooltip/HtmlTooltip';
import HyperLink from '../../components/HyperLink/HyperLink';
import ModalDialog from '../../components/ModalDialog/ModalDialog';
import { useAuth } from '../../context/AuthContext/auth';
import { useBackdrop } from '../../context/BackdropContext/backdrop';
import { useTpa } from '../../context/TPAContext/tpa';
import { useRedirection } from '../../hooks/useRedirection';
import { ECardRoutePaths } from '../../routes/enum/ECardRoutePaths';
import { ELoginRoutePaths } from '../../routes/enum/ELoginRoutePaths';
import { EOtpRoutePaths } from '../../routes/enum/EOtpRoutePaths';
import { EUserRoutePaths } from '../../routes/enum/EUserRoutePaths';
import { generateNewOtpService } from '../../services/sabbi/otp/otp';
import {
    emailCountValidation,
    phoneCountValidation
} from '../../services/sabbi/validation/validation';
import { IAddress } from '../../utils/address/interfaces/IAddress';
import { cancelTRXandReturnTrade } from '../../utils/clientHelpers';
import { getDataDevice } from '../../utils/device/dataDeviceHelpers';
import { EFlowIndicator } from '../../utils/enums/EFlowIndicator';
import { serviceErrorMapper } from '../../utils/errors';
import IModalValuesState from './interfaces/IModalValuesState';
import { IRegisterContactLocationState } from './interfaces/IRegisterContactLocationState';
import useStyles from './UserRegisterContactPageStyles.material';

type Inputs = {
    address?: IGoogleAddress;
    unitNumber?: string;
    cellphone?: string;
    email?: string;
    email2?: string;
    occupation?: string;
};

const UserRegisterContactPage: FC = () => {
    const [modalValues, setModalValues] = useState<IModalValuesState>({
        cancelModal: false
    });
    const [errorValues, setErrorValues] = useState<IErrorValues>({
        title: '',
        visible: false
    });
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation<IRegisterContactLocationState>();
    const backdrop = useBackdrop();
    const tpaContext = useTpa();
    const authContext = useAuth();
    const { handleErrorRedirection, shouldRedirect } = useRedirection();
    const methods = useForm<FieldValues>({ mode: 'all', defaultValues: {} });

    useEffect(() => {
        if (!validateLocationStateData(location.state)) {
            if (!authContext.user?.te) {
                history.replace(ELoginRoutePaths.LOGIN);
            } else {
                history.replace(ECardRoutePaths.CARD_SELECT);
            }
        }
    }, []);

    useEffect(() => {
        if (location.state.userManualAddress) {
            const {
                country,
                comuna,
                region,
                street1,
                streetNumber,
                latitude,
                longitude,
                unitNumber = ''
            } = location.state.userManualAddress;

            const fullAddress = `${street1} ${streetNumber}, ${comuna}, ${region}, ${country}`;

            methods.setValue(
                'address',
                { fullAddress, latitude, longitude },
                { shouldValidate: true, shouldDirty: true, shouldTouch: true }
            );

            unitNumber != '' &&
                methods.setValue('unitNumber', unitNumber, {
                    shouldValidate: true,
                    shouldDirty: true,
                    shouldTouch: true
                });
        }
    }, []);

    const validateLocationStateData = (obj: IRegisterContactLocationState) => {
        if (obj) {
            return 'userRegisterData' in obj;
        }
        return false;
    };
    const getRedirectUrlTrade = async () => {
        backdrop.openBackdrop();
        try {
            await cancelTRXandReturnTrade(tpaContext.tokenInfo.tpa as string);
        } catch (error) {
            const handledError = serviceErrorMapper(error);
            if (shouldRedirect(handledError)) {
                handleErrorRedirection(handledError);
            } else {
                showError(handledError.message);
            }
        } finally {
            backdrop.closeBackdrop();
        }
    };

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        try {
            backdrop.openBackdrop();
            const userDevice = getDataDevice();
            await phoneCountValidation({
                phone: `9${data.cellphone}`,
                TPA: tpaContext.tokenInfo.tpa as string,
                device: userDevice
            });
            await emailCountValidation({
                email: data.email as string,
                TPA: tpaContext.tokenInfo.tpa as string,
                device: userDevice
            });
            const requestNewOtp = {
                mediaSendDestination: `9${data.cellphone}`,
                device: userDevice,
                TPA: tpaContext.tokenInfo.tpa as string
            };
            const newOtp = await generateNewOtpService(requestNewOtp);

            const address: IAddress = {
                country: '',
                comuna: '',
                region: '',
                street1: '',
                streetNumber: '',
                latitude: 0,
                longitude: 0
            };

            if (location.state.userManualAddress) {
                const { country, comuna, region, street1, streetNumber, latitude, longitude } =
                    location.state.userManualAddress;

                address.country = country;
                address.comuna = comuna;
                address.region = region;
                address.street1 = street1;
                address.streetNumber = streetNumber;
                address.latitude = latitude;
                address.longitude = longitude;
            } else {
                address.country = data.address?.country as string;
                address.comuna = data.address?.region as string;
                address.region = data.address?.comuna as string;
                address.street1 = data.address?.street1 as string;
                address.streetNumber = data.address?.streetNumber as string;
                address.latitude = data.address?.latitude;
                address.longitude = data.address?.longitude;
            }
            data.unitNumber != '' && (address.unitNumber = data.unitNumber);

            history.replace({
                pathname: EOtpRoutePaths.OTP_VALIDATION,
                state: {
                    userRegisterData: location.state.userRegisterData,
                    userContactData: {
                        cellphone: `9${data.cellphone}`,
                        email: data.email,
                        address,
                        occupation: data.occupation
                    },
                    flow: EFlowIndicator.REGISTER,
                    TPOTP: newOtp.TPOTP
                }
            });
        } catch (error) {
            const handledError = serviceErrorMapper(error);
            if (shouldRedirect(handledError)) {
                handleErrorRedirection(handledError);
            } else {
                showError(handledError.message);
            }
        } finally {
            backdrop.closeBackdrop();
        }
    };
    const showModal = (modalName: keyof IModalValuesState) => {
        setModalValues({
            ...modalValues,
            [modalName]: true
        });
    };
    const closeModals = () => {
        setModalValues({
            cancelModal: false
        });
    };
    const handleAlertTimeout = () => {
        setTimeout(() => {
            clearError();
        }, 6000);
    };
    const showError = (title: string) => {
        setErrorValues({
            title: title,
            visible: true
        });
        handleAlertTimeout();
    };
    const clearError = () => {
        setErrorValues({
            title: '',
            visible: false
        });
    };
    const handleCancel = () => {
        history.replace(ELoginRoutePaths.LOGIN);
    };
    const handleManualAddressButton = () => {
        backdrop.openBackdrop();
        history.replace({
            pathname: EUserRoutePaths.USER_REGISTER_MANUAL_ADDRESS,
            state: {
                userRegisterData: location.state.userRegisterData
            }
        });
        backdrop.closeBackdrop();
    };

    const renderTooltip = () => {
        const width =
            window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        if (width < 769) {
            return (
                <ClickableTooltip
                    placement={'top-end'}
                    size={'md'}
                    tooltipContent={
                        <div>
                            <h3 className={classes.Tooltip_Head}>
                                Medidas de protección para evitar estafas y fraudes
                            </h3>
                            <p className={classes.Tooltip_Text}>
                                La Unidad de Análisis Financiera (UAF) <br></br> nos exige conocer
                                el domicilio y ocupación de todos los usuarios que se registren en
                                SABBI. Uno de nuestros objetivos es reducir trámites presenciales,
                                por eso pedimos este dato.
                            </p>
                        </div>
                    }>
                    <span>¿Por qué me piden este dato?</span>
                </ClickableTooltip>
            );
        } else {
            return (
                <HtmlTooltip
                    placement={'top-end'}
                    size={'md'}
                    tooltipContent={
                        <div>
                            <h3 className={classes.Tooltip_Head}>
                                Medidas de protección para evitar estafas y fraudes
                            </h3>
                            <p className={classes.Tooltip_Text}>
                                La Unidad de Análisis Financiera (UAF) <br></br> nos exige conocer
                                el domicilio y ocupación de todos los usuarios que se registren en
                                SABBI. Uno de nuestros objetivos es reducir trámites presenciales,
                                por eso pedimos este dato.
                            </p>
                        </div>
                    }>
                    <span>¿Por qué me piden este dato?</span>
                </HtmlTooltip>
            );
        }
    };
    return (
        <section className={classes.RegisterContactPage}>
            <Grid container>
                <Grid item xs={12} lg={12}>
                    <div className={classes.HyperLinkBox}>
                        <HyperLink
                            classNames={classes.HyperLink_GoBack}
                            underline="none"
                            onClick={getRedirectUrlTrade}>
                            <ChevronLeftIcon />
                            volver al comercio
                        </HyperLink>
                    </div>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} md={12}>
                    <ContentBox classNames={classes.ContentBox}>
                        <img className={classes.SabbiLogo} src={SabbiLogo} alt="SabbiLogo" />
                        <Typography variant="h2" component="h2" gutterBottom>
                            Ingresa tus datos personales para registrarte:
                        </Typography>
                        <AlertBar
                            classNames={classes.AlertBar}
                            variant="filled"
                            severity="error"
                            message={errorValues.title}
                            open={errorValues.visible}
                            closeOnClick={() => clearError()}
                            icon={<WarningRoundedIcon />}
                        />
                        <FormContext
                            className={classes.Form}
                            id={'RegisterForm'}
                            onSubmit={onSubmit}
                            methods={methods}>
                            <GoogleAddressInput
                                name={'address'}
                                id={'address'}
                                type={'text'}
                                label={'Dirección'}
                                rules={{
                                    required: { value: true, message: 'La Dirección es requerida' }
                                }}
                                icon={<HomeIcon />}
                                handleManualAddressButton={handleManualAddressButton}
                            />
                            <TextInput
                                id={'unitNumber'}
                                name={'unitNumber'}
                                type={'unitNumber'}
                                label={'Nº de depto. (opcional)'}
                                icon={<EditIcon />}
                            />
                            <PhoneInput
                                id={'cellphone'}
                                name={'cellphone'}
                                maxLength={8}
                                label={'Teléfono móvil'}
                                showStartAdornment={true}
                                rules={{
                                    required: { value: true, message: 'El Teléfono es requerido' },
                                    max: 99999999999,
                                    min: 0,
                                    minLength: 8
                                }}
                                icon={<PhoneAndroidIcon />}
                            />
                            <TextInput
                                id={'email'}
                                name="email"
                                type={'email'}
                                label={'Email'}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'El email es requerido'
                                    },
                                    pattern: {
                                        value: /\S+@\S+\.\S+/,
                                        message: 'No es un formato de email válido'
                                    }
                                }}
                                icon={<MailIcon />}
                            />
                            <TextInput
                                id={'email2'}
                                name={'email2'}
                                type={'email2'}
                                label={'Confirmar email'}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'El email es requerido'
                                    },
                                    validate: (value) => {
                                        const emailValue = (
                                            document.getElementById('email') as HTMLInputElement
                                        ).value;
                                        return value === emailValue;
                                    }
                                }}
                                icon={<MailIcon />}
                            />
                            <Typography variant="h2" component="h2">
                                ¿A qué te dedicas?
                            </Typography>
                            <TextInput
                                id={'occupation'}
                                classNames={classes.Ocupation_Input}
                                name={'occupation'}
                                type={'text'}
                                label={'Profesión, ocupación'}
                                rules={{
                                    required: { value: true, message: 'La ocupación es requerida' },
                                    minLength: { value: 2, message: 'Campo Incorrecto' }
                                }}
                                icon={<WorkIcon />}
                                hasHyperLink={true}
                                hyperLinkText={renderTooltip()}
                            />
                            <div className={classes.ButtonsBox}>
                                <Button
                                    type="button"
                                    className={classes.CancelButton}
                                    onClick={() => showModal('cancelModal')}>
                                    Cancelar
                                </Button>
                                <RoundButton
                                    classNames={classes.SubmitButton}
                                    id={'submitbtn'}
                                    color="primary"
                                    name={'submitbtn'}
                                    type="submit">
                                    Continuar
                                </RoundButton>
                            </div>
                        </FormContext>
                    </ContentBox>
                </Grid>
            </Grid>

            <ModalDialog
                id="ModalCancel"
                open={modalValues.cancelModal}
                handleCancel={closeModals}
                handleAccept={handleCancel}
                cancelText="Volver"
                title={
                    <>
                        <img
                            className={classes.ModalNotNumber_SabbiNoBorder}
                            src={SabbiLogoNoBorderSrc}
                            alt="sabbi"
                        />
                        <Typography variant="h2" component="h2">
                            ¡No queremos que te vayas!{' '}
                            <span role="img" aria-label="confused-face">
                                😕
                            </span>
                        </Typography>
                        <Typography component="p" className={classes.WarningText}>
                            ¿Deseas salir del proceso? Al cancelar la operación, tus datos serán
                            eliminados y deberás comenzar de nuevo el proceso de registro.
                        </Typography>
                    </>
                }
                scroll={'paper'}
                maxWidth={'sm'}>
                <div className={classes.CancelModal}></div>
            </ModalDialog>
        </section>
    );
};
export default UserRegisterContactPage;
