import sabbiHttpInstance from '../../instances/sabbi.instance';
import { IAuthenticateUniqueKey } from './interfaces/IAuthenticateUniqueKey';
import { IAuthenticateUniqueKeyResponse } from './interfaces/IAuthenticateUniqueKeyResponse';
import { IEmailCount } from './interfaces/IEmailCount';
import { IEmailCountResponse } from './interfaces/IEmailCountResponse';
import { IPhoneCount } from './interfaces/IPhoneCount';
import { IPhoneCountResponse } from './interfaces/IPhoneCountResponse';
import { IRutValidateOnboardingResponse } from './interfaces/IRutValidateOnboardingResponse';
import { IRutValidation } from './interfaces/IRutValidation';
import { IValidateUniqueKey } from './interfaces/IValidateUniqueKey';
import { IValidateUniqueKeyResponse } from './interfaces/IValidateUniqueKeyResponse';

export const rutValidateOnboarding = async (
    data: IRutValidation
): Promise<IRutValidateOnboardingResponse> => {
    const {
        data: { payload }
    } = await sabbiHttpInstance.post(`/validation/rut-validate-onboarding`, data);
    return payload;
};

export const emailCountValidation = async (data: IEmailCount): Promise<IEmailCountResponse> => {
    const {
        data: { payload }
    } = await sabbiHttpInstance.post(`/validation/user/email/count`, data);
    return payload;
};

export const phoneCountValidation = async (data: IPhoneCount): Promise<IPhoneCountResponse> => {
    const {
        data: { payload }
    } = await sabbiHttpInstance.post(`/validation/user/phone/count`, data);
    return payload;
};

export const authenticateUniqueKey = async (
    data: IAuthenticateUniqueKey
): Promise<IAuthenticateUniqueKeyResponse> => {
    const {
        data: { payload }
    } = await sabbiHttpInstance.post(`/validation/user/unique-key-authentication`, data);
    return payload;
};

export const validateUniqueKey = async (
    data: IValidateUniqueKey
): Promise<IValidateUniqueKeyResponse> => {
    const {
        data: { payload }
    } = await sabbiHttpInstance.post(`/validation/user/unique-key-validation`, data);
    return payload;
};
