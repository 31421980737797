import { Kushki } from '@kushki/js';
import { BinBody } from '@kushki/js/lib/types/bin_body';
import { BinInfoResponse } from '@kushki/js/lib/types/bin_info_response';
import { CardAsyncTokenRequest } from '@kushki/js/lib/types/card_async_token_request';
import { TokenResponse } from '@kushki/js/lib/types/remote/token_response';
import { SecureOtpRequest } from '@kushki/js/lib/types/secure_otp_request';
import { SecureOtpResponse } from '@kushki/js/lib/types/secure_otp_response';
import { SubscriptionCardAsyncTokenRequest } from '@kushki/js/lib/types/subscription_card_async_token_request';
import { SubscriptionCardAsyncTokenResponse } from '@kushki/js/lib/types/subscription_card_async_token_response';
import { SubscriptionTokenRequest } from '@kushki/js/lib/types/subscription_token_request';
import { TokenChargeRequest } from '@kushki/js/lib/types/token_charge_request';
import { TokenRequest } from '@kushki/js/lib/types/token_request';

import __RUNTIME_CONFIG__ from './envConfig';
import { ApplicationError } from './errors/ApplicationError';
import { KUSHKI_ERRORS } from './errors/enums/EAppErrors';
import { getLocalStorageItem } from './localStorageManipulation/index';

const kushki = new Kushki({
    merchantId: __RUNTIME_CONFIG__.PUBLIC_MERCHANT_ID as string,
    inTestEnvironment: __RUNTIME_CONFIG__.IS_TEST_ENVIRONMENT as unknown as boolean,
    regional: false
});

const kushkiOtp = new Kushki({
    merchantId: __RUNTIME_CONFIG__.OTP_PUBLIC_MERCHANT_ID as string,
    inTestEnvironment: __RUNTIME_CONFIG__.IS_TEST_ENVIRONMENT as unknown as boolean,
    regional: false
});

export const handleRequestToken = (body: TokenRequest): Promise<TokenResponse> => {
    return new Promise((resolve, reject) => {
        kushki.requestToken(body, (response) => {
            if ('token' in response) {
                resolve(response);
            } else {
                reject(
                    new ApplicationError(
                        KUSHKI_ERRORS[response.code]
                            ? KUSHKI_ERRORS[response.code]
                            : KUSHKI_ERRORS.KDEFAULT
                    )
                );
            }
        });
    });
};

export const handleOTPRequestToken = (body: TokenRequest): Promise<TokenResponse> => {
    return new Promise((resolve, reject) => {
        kushkiOtp.requestToken(body, (response) => {
            if ('token' in response) {
                resolve(response);
            } else {
                reject(
                    new ApplicationError(
                        KUSHKI_ERRORS[response.code]
                            ? KUSHKI_ERRORS[response.code]
                            : KUSHKI_ERRORS.KDEFAULT
                    )
                );
            }
        });
    });
};

export const handleRequestSubscriptionToken = (
    body: SubscriptionTokenRequest
): Promise<TokenResponse> => {
    return new Promise((resolve, reject) => {
        kushki.requestSubscriptionToken(body, (response) => {
            if ('token' in response) {
                resolve(response);
            } else {
                reject(response);
            }
        });
    });
};

export const handleRequestSubscriptionCardAsyncToken = (
    body: SubscriptionCardAsyncTokenRequest
): Promise<SubscriptionCardAsyncTokenResponse> => {
    return new Promise((resolve, reject) => {
        kushki.requestSubscriptionCardAsyncToken(body, (response) => {
            if ('token' in response) {
                resolve(response);
            } else {
                reject(response);
            }
        });
    });
};

export const handleRequestBinInfo = (body: BinBody): Promise<BinInfoResponse> => {
    return new Promise((resolve, reject) => {
        kushki.requestBinInfo(body, (response) => {
            if ('cardType' in response) {
                resolve(response);
            } else {
                reject(response);
            }
        });
    });
};

type KushkiErrorResponse =
    | {
          code: string;
          message: string;
      }
    | {
          code: string;
          message: string;
          questionnaireCode: string;
          questions: {
              text: string;
              options: {
                  id: string;
                  text: string;
              }[];
              id: string;
          }[];
      };

export const handleRequestSecureServiceValidation = (
    body: SecureOtpRequest
): Promise<SecureOtpResponse> => {
    return new Promise((resolve, reject) => {
        kushkiOtp.requestSecureServiceValidation(body, (response) => {
            if ((response as KushkiErrorResponse).code == 'OTP000') {
                resolve(response);
            } else {
                reject(response);
            }
        });
    });
};

/**
 * Get Kushki Token for a card charge (DEBIT/CREDIT)
 * @param {CardAsyncTokenRequest} body
 * @return {Promise<TokenResponse>}
 */

export const handleRequestCardAsyncToken = (
    body: CardAsyncTokenRequest
): Promise<TokenResponse> => {
    const publicMerchantId = getLocalStorageItem('publicMerchantId') as string;

    const kushkiTransaction = new Kushki({
        merchantId: publicMerchantId,
        inTestEnvironment: __RUNTIME_CONFIG__.IS_TEST_ENVIRONMENT as unknown as boolean,
        regional: false
    });

    return new Promise((resolve, reject) => {
        kushkiTransaction.requestCardAsyncToken(body, (response) => {
            if ('token' in response) {
                resolve(response);
            } else {
                reject(response);
            }
        });
    });
};

/**
 * Get Kushki Token for a card charge (DEBIT/CREDIT)
 * @param {TokenChargeRequest} body
 * @return {Promise<TokenResponse>}
 */
export const handleRequestTokenCharge = (body: TokenChargeRequest): Promise<TokenResponse> => {
    const publicMerchantId = getLocalStorageItem('publicMerchantId') as string;

    const kushkiTransaction = new Kushki({
        merchantId: publicMerchantId,
        inTestEnvironment: __RUNTIME_CONFIG__.IS_TEST_ENVIRONMENT as unknown as boolean,
        regional: false
    });

    return new Promise((resolve, reject) => {
        kushkiTransaction.requestDeviceToken(body, (response) => {
            if ('token' in response) {
                resolve(response);
            } else {
                reject(response);
            }
        });
    });
};
