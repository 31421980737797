import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@material-ui/core';
import { FC } from 'react';

import useStyles from './BnplDetailTableStyles.material';
import { IDetailBNPLProps } from './interfaces/IDetailBnpl';

const BnplDetailTable: FC<IDetailBNPLProps> = ({ detailBNPL }) => {
    const classes = useStyles();
    return (
        <TableContainer className={classes.TableContainer}>
            <Table aria-label="success-checkout-table">
                <TableHead className={classes.TableHead}>
                    <TableRow>
                        <TableCell
                            className={`${classes.CellHead} ${classes.CellHead_FirstRounded}`}
                            component="th"
                            scope="row">
                            Monto Compra
                        </TableCell>
                        <TableCell
                            className={`${classes.CellHead} ${classes.CellHead_LastRounded}`}
                            component="th"
                            align="right">
                            {detailBNPL.transactionTotal}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.CellHead} component="th" scope="row">
                            Monto total a pagar
                        </TableCell>
                        <TableCell className={classes.CellHead} component="th" align="right">
                            {detailBNPL?.initialQuotaPlusCtc}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.CellHead} component="th" scope="row">
                            Pie
                        </TableCell>
                        <TableCell className={classes.CellHead} component="th" align="right">
                            {detailBNPL?.initialQuota}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.CellHead} component="th" scope="row">
                            Costo total del crédito
                        </TableCell>
                        <TableCell className={classes.CellHead} component="th" align="right">
                            {detailBNPL?.ctc}
                        </TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    <TableRow>
                        <TableCell className={classes.CellBody} scope="row">
                            <Typography component="p" className={`${classes.CellBody_P}`}>
                                Tasa de interés
                            </Typography>
                            <Typography variant="caption">Mensual</Typography>
                        </TableCell>
                        <TableCell align="right" className={classes.CellBody}>
                            <Typography component="p">{detailBNPL?.interest} %</Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.CellBody} scope="row">
                            <Typography component="p" className={classes.CellBody_P}>
                                Tasa de interés
                            </Typography>
                            <Typography variant="caption">Anual</Typography>
                        </TableCell>
                        <TableCell align="right" className={classes.CellBody}>
                            <Typography component="p">{detailBNPL?.annualRate} %</Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.CellBody} scope="row">
                            <Typography component="p" className={classes.CellBody_P}>
                                Monto de la cuota
                            </Typography>
                        </TableCell>
                        <TableCell align="right" className={classes.CellBody}>
                            <Typography component="p">{detailBNPL?.quotaValue}</Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.CellBody} scope="row">
                            <Typography component="p" className={classes.CellBody_P}>
                                N° de cuotas
                            </Typography>
                        </TableCell>
                        <TableCell align="right" className={classes.CellBody}>
                            <Typography component="p">{detailBNPL?.term}</Typography>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell className={classes.CellBody} scope="row">
                            <Typography component="p" className={classes.CellBody_P}>
                                CAE
                            </Typography>
                        </TableCell>
                        <TableCell align="right" className={classes.CellBody}>
                            <Typography component="p">{detailBNPL?.cae} %</Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.CellBody} scope="row">
                            <Typography component="p" className={classes.CellBody_P}>
                                ITE
                            </Typography>
                        </TableCell>
                        <TableCell align="right" className={classes.CellBody}>
                            <Typography component="p">{detailBNPL?.ite}</Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.CellBody} scope="row">
                            <Typography component="p" className={classes.CellBody_P}>
                                1° Vencimiento
                            </Typography>
                            <Typography variant="caption">
                                1era cuota $ {detailBNPL?.quotaValue}
                            </Typography>
                        </TableCell>
                        <TableCell align="right" className={classes.CellBody}>
                            <Typography component="p">{detailBNPL?.expirationDate}</Typography>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default BnplDetailTable;
