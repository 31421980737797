import { CircularProgress, Typography } from '@material-ui/core';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import ContentBox from '../../components/ContentBox/ContentBox';
import { useAuth } from '../../context/AuthContext/auth';
import { useBackdrop } from '../../context/BackdropContext/backdrop';
import { useTpa } from '../../context/TPAContext/tpa';
import { useRedirection } from '../../hooks/useRedirection';
import { ECardRoutePaths } from '../../routes/enum/ECardRoutePaths';
import { ECheckoutRoutePaths } from '../../routes/enum/ECheckoutRoutePaths';
import { ELoginRoutePaths } from '../../routes/enum/ELoginRoutePaths';
import { IWebPayPlusTransactionUrl } from '../../services/sabbi/transaction/interfaces/IWebPayPlusTransactionUrl';
import { webPayPlusTransactionUrl } from '../../services/sabbi/transaction/transaction';
import { cancelTRXandReturnTrade } from '../../utils/clientHelpers';
import { getDataDevice } from '../../utils/device/dataDeviceHelpers';
import useStyles from './CheckoutPlusPaymentPageStyles.material';
import { IPlusPaymentLocationState } from './interfaces/IPlusPaymentLocationState';

const CheckoutPlusPaymentPage = () => {
    const PAYMENTWEBPAYPLUS = 'WEBPAYPLUS';
    const classes = useStyles();
    const location = useLocation<IPlusPaymentLocationState>();
    const history = useHistory();
    const tpaContext = useTpa();
    const authContext = useAuth();
    const backdrop = useBackdrop();
    const { handleErrorRedirection, shouldRedirect } = useRedirection();

    useEffect(() => {
        if (!validateLocationStateData(location.state)) {
            if (!authContext.user?.te) {
                history.replace(ELoginRoutePaths.LOGIN);
            } else {
                history.replace(ECardRoutePaths.CARD_SELECT);
            }
        } else {
            callWebpayPlus();
        }
    }, []);
    const validateLocationStateData = (obj: IPlusPaymentLocationState) => {
        if (obj) {
            return 'token' in obj;
        }
        return false;
    };
    const callWebpayPlus = async () => {
        backdrop.openBackdrop();
        try {
            const wpPlusTransactionRequest = {
                token: location.state.token,
                TPA: tpaContext.tokenInfo.tpa as string,
                device: getDataDevice(),
                TE: authContext.user.te as string
            } as IWebPayPlusTransactionUrl;
            const response = await webPayPlusTransactionUrl(wpPlusTransactionRequest);
            window.location.href = response.redirectUrl;
        } catch (error) {
            if (shouldRedirect(error)) {
                handleErrorRedirection(error);
            } else {
                const cancelTrxResponse = await cancelTRXandReturnTrade(
                    tpaContext.tokenInfo.tpa as string,
                    true
                );
                if (authContext.user?.te) {
                    history.replace({
                        pathname: ECheckoutRoutePaths.CHECKOUT_LOGGED_USER_SUCCESS,
                        state: {
                            success: false,
                            paymentMethods: PAYMENTWEBPAYPLUS,
                            transaction: {
                                TT: cancelTrxResponse
                            }
                        }
                    });
                } else {
                    history.replace({
                        pathname: ECheckoutRoutePaths.CHECKOUT_SUCCESS,
                        state: {
                            success: false,
                            paymentMethods: PAYMENTWEBPAYPLUS,
                            transaction: {
                                TT: cancelTrxResponse
                            }
                        }
                    });
                }
            }
        } finally {
            backdrop.closeBackdrop();
        }
    };
    return (
        <section className={classes.DebitSubscribePage}>
            <ContentBox>
                <Typography variant="h2" component="h2">
                    Va a ser redirigido a la plataforma de pago
                </Typography>
                <CircularProgress className={classes.CircularProgress} color="primary" />
            </ContentBox>
        </section>
    );
};

export default CheckoutPlusPaymentPage;
