import { KeyLike } from 'jose-browser-runtime/types';

import __RUNTIME_CONFIG__ from '../envConfig';
import { ApplicationError } from '../errors/ApplicationError';
import { SABBI_FRONT_ERRORS } from '../errors/enums/EAppErrors';
import { jwkToKeyLike } from '../jose/parsing';
import { ITokenClientCommerce } from '../tokenManipulation/interfaces/ITokenClientCommerce';

/**
 * Retrieves public key from configuration vars as KeyLike
 * @returns {Promise<{ppKey: KeyLike}>} Public Key as KeyLike
 */
export const getSPKey = async (): Promise<{
    ppKey: KeyLike;
}> => {
    let ppKey: KeyLike;
    try {
        ppKey = await jwkToKeyLike(__RUNTIME_CONFIG__.SPK);
    } catch (error) {
        throw new ApplicationError(SABBI_FRONT_ERRORS.SABBI_F_05);
    }
    return { ppKey };
};

type keyTyped = {
    businessPartnerId: string;
    businessPartnerUserId: string;
};

export const getKeyData = (keyArray: ITokenClientCommerce[], splitCharacter = '*@*') => {
    let mappedArray: keyTyped[] = [];
    if (keyArray.length > 0) {
        mappedArray = keyArray.map((comerce) => {
            const keyArray: Array<string> = comerce.key.split(splitCharacter);
            const keyObject = {
                businessPartnerId: keyArray[0],
                businessPartnerUserId: keyArray[1]
            };
            return keyObject;
        });
        return mappedArray;
    } else {
        return mappedArray;
    }
};
