import { HttpStatus } from '../enums/HttpStatus';

export interface IApplicationError {
    status: HttpStatus;
    code: string;
    message: string;
    payload?: { [k: string]: unknown };
}

export class ApplicationError extends Error {
    status: HttpStatus;
    code: string;
    message: string;
    payload?: { [k: string]: unknown };
    constructor(options: IApplicationError) {
        super();
        this.status = options.status;
        this.code = options.code;
        this.message = options.message;
        this.payload = options.payload;
        Object.setPrototypeOf(this, ApplicationError.prototype);
    }
}
