import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        transition: 'min-height .3s ease-in-out',
        '&.opened': {
            minHeight: '48px'
        },
        '&.closed': {
            minHeight: '0px'
        },
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2)
        }
    },
    Alert: {
        borderRadius: '100px',
        position: 'relative',
        '& .MuiAlert-icon': {
            backgroundColor: theme.palette.error.dark,
            position: 'absolute',
            left: 0,
            top: 0,
            borderRadius: '100px 0 0 100px',
            height: '100%',
            width: '45px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingLeft: '5px'
        },
        '& .MuiAlert-message': {
            paddingLeft: '40px',
            fontFamily: 'robotoMedium'
        },
        '&.MuiAlert-filledError': {
            backgroundColor: theme.palette.error.main
        }
    },
    IconButton: {
        marginRight: '5px',
        fontSize: '1.3rem'
    }
}));

export default useStyles;
