// @ts-ignore
import { JWTExpired } from 'jose-browser-runtime/dist/browser/util/errors';
import { JWSHeaderParameters, KeyLike } from 'jose-browser-runtime/types';

import { ApplicationError } from '../../../errors/ApplicationError';
import { SABBI_FRONT_ERRORS } from '../../../errors/enums/EAppErrors';
import { jwtVerification } from '../../../jose/jwt';
import { parseStringToJSON } from '../../../objectManipulation';
import { IJWTVerification } from './interfaces/IJWTVerification';

/**
 * Extract payload from JWT without verifying signature
 * @param {string} jwt token to decode
 * @return {T}
 */
export const extractPayloadFromJWT = <T>(jwt: string): T => {
    const splitContent = jwt.split('.');
    if (!splitContent[1]) {
        throw new ApplicationError(SABBI_FRONT_ERRORS.SABBI_F_05);
    }
    const base64Payload = splitContent[1];
    const base64CharsReplacement = base64Payload.replace('-', '+').replace('_', '/');
    const decodedPayload = Buffer.from(base64CharsReplacement, 'base64').toString('utf8');
    const parsedPayload = parseStringToJSON<T>(decodedPayload);
    return parsedPayload;
};

/**
 * Verifies a JWT string
 * @param {string} jwt JWT string to verify
 * @param {KeyLike} publicKey Public key to verify the given JWT
 * @param {boolean} allowExpiration Flag that indicates if allows expiration on validation
 * @param {string} errorMessage Message to be thrown in case of error
 * @return {IJWTVerification<T>} Object with verified information from JWT
 */
export const verifyAndExtractPayloadFromJWT = async <T>(
    jwt: string,
    publicKey: KeyLike,
    allowExpiration = false
): Promise<IJWTVerification<T>> => {
    let payloadInformation: T;
    let protectedHeader: JWSHeaderParameters;
    let isExpired = false;
    try {
        const { payload, protectedHeader: protectedHeaderFromJwt } = await jwtVerification(
            jwt,
            publicKey
        );
        payloadInformation = payload as unknown as T;
        protectedHeader = protectedHeaderFromJwt;
    } catch (error) {
        if (!allowExpiration) {
            throw new ApplicationError(SABBI_FRONT_ERRORS.SABBI_F_05);
        } else {
            if (error instanceof JWTExpired) {
                payloadInformation = extractPayloadFromJWT<T>(jwt);
                protectedHeader = {};
                isExpired = true;
            } else {
                throw new ApplicationError(SABBI_FRONT_ERRORS.SABBI_F_05);
            }
        }
    }
    const response: IJWTVerification<T> = {
        jwtVerification: {
            payload: payloadInformation,
            protectedHeader,
            isExpired
        }
    };
    return response;
};
