import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { FC } from 'react';

import SabbiLogo from '../../assets/logo/sabbi-noborder.svg';
import RoundButton from '../FormsContext/RoundButton/RoundButton';
import IModalProps from './interfaces/IModalProps';
import useStyles from './ModalTermsStyles.material';

const ModalTerms: FC<IModalProps> = ({
    id,
    classNames = '',
    open,
    handleClose,
    children,
    fullWidth = true,
    scroll,
    disableBackdropClick,
    disableEscapeKeyDown,
    transition,
    title,
    customFooter,
    maxWidth = 'lg'
}) => {
    const classes = useStyles();
    return (
        <Dialog
            id={id}
            open={open}
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            scroll={scroll}
            disableBackdropClick={disableBackdropClick}
            disableEscapeKeyDown={disableEscapeKeyDown}
            onClose={handleClose}
            TransitionComponent={transition}
            aria-labelledby={`${id}-alert-dialog-title`}
            className={`${classNames} ${classes.ModalTerms}`}>
            <DialogTitle
                className={classes.DialogTitle}
                id={`${id}-alert-dialog-title`}
                disableTypography={true}>
                <div className={classes.TitleWithLogo}>
                    <img className={classes.SabbiLogo} src={SabbiLogo} alt="sabbi Logo" />
                    <Typography variant="h2" component="h3">
                        {title}
                    </Typography>
                </div>
            </DialogTitle>
            <DialogContent className={classes.DialogContent}>{children}</DialogContent>
            <DialogActions className={classes.DialogActions}>
                {customFooter ? (
                    customFooter
                ) : (
                    <RoundButton
                        classNames={classes.RoundButton}
                        color="primary"
                        type="button"
                        onClick={handleClose}>
                        Entendido
                    </RoundButton>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default ModalTerms;
