export enum ECardType {
    CREDIT = 'CREDIT',
    DEBIT = 'DEBIT',
    UNKNOWN = 'UNKNOWN',
    PREPAID = 'PREPAID',
    EMPTY = 'EMPTY'
}

export enum ECardTypeCamelCase {
    Credito = 'credito',
    Debito = 'debito'
}
