import { makeStyles } from '@material-ui/core/styles';
import COLORS from '../../../themes/colors';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '460px',
    },
    AutocompleteContainer: {
        width: '100%',
        display: 'flex',
        margin: 'auto',
        position: 'relative',
        maxWidth: '460px',
        '& .MuiAutocomplete-popperDisablePortal': {
            width: '100%',
            paddingTop: '36px',
        },
        '& .MuiAutocomplete-option[aria-selected="true"]': {
            backgroundColor: theme.palette.common.white
        },
        '& .MuiAutocomplete-option[data-focus="true"]': {
            backgroundColor: COLORS.grey300
        },
        '& .MuiAutocomplete-option:active': {
            backgroundColor: COLORS.cyanLight
        }
    },
    AutocompleteInput: {
        marginBottom: '30px',
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '460px',
        width: '100%',
        '&.error': {
            transition: 'all .3s ease-in-out',
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: theme.palette.error.main
                },
                '&.Mui-focused .MuiSvgIcon-root': {
                    color: theme.palette.error.main
                }
            },
            '& label': {
                color: theme.palette.error.main
            },
            '& .MuiFormHelperText-root': {
                color: theme.palette.error.main
            },
            '& .MuiSvgIcon-colorPrimary': {
                color: theme.palette.error.main
            },
            '& .MuiSvgIcon-root': {
                color: theme.palette.error.main
            }
        },
        '&.success': {
            transition: 'all .3s ease-in-out',
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    color: theme.palette.success.main
                },
                '&.Mui-focused .MuiSvgIcon-root': {
                    color: theme.palette.success.main
                }
            },
            '& label.Mui-focused': {
                color: theme.palette.primary.main
            },
            '& .MuiFormHelperText-root.Mui-focused': {
                color: theme.palette.primary.main
            },
            '& .Mui-focused .MuiSvgIcon-colorPrimary': {
                color: theme.palette.primary.main
            },
            '& .MuiSvgIcon-root': {
                color: theme.palette.success.main
            }
        },
        '&.disabled': {
            '& .MuiSvgIcon-root': {
                color: '#C0BCBC'
            }
        },
        '& .MuiOutlinedInput-root': {
            maxHeight: '40px',
            padding: '0',
            '&.Mui-disabled': {
                '&:hover fieldset': {
                    borderColor: '#C0BCBC'
                },    
            },
            '&:hover fieldset': {
                borderColor: theme.palette.primary.main
            },
            '&.Mui-focused .MuiSvgIcon-root': {
                color: theme.palette.primary.main
            },
            '&.Mui-focused fieldset': {
                borderWidth: '1px'
            },
            '& fieldset': {
                borderColor: theme.palette.grey[500],
                borderRadius: '20px 20px',
                boxSizing: 'border-box'
            },
            '& .MuiInputAdornment-positionEnd': {
                marginLeft: '16px',
                marginRight: '20px',
                '& .MuiSvgIcon-root': {
                    maxWidth: '20px',
                    maxHeight: '20px'
                }
            }
        },
        '& .MuiFormControl-root':{
            marginBottom: '0 !important',
        },
        '& .MuiFormLabel-root':{
            transform: 'translate(14px, 12px) scale(1)',
        },
        '& .MuiFormLabel-root.Mui-focused, & .MuiFormLabel-root.MuiFormLabel-filled':{
            transform: 'translate(14px, -6px) scale(0.75)',
        },
        '& input': {
            paddingLeft: '20px !important',
            '&::placeholder': {
                fontSize: '1rem'
            },
            '&::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none'
            },
            '&::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none'
            },
            '&[type=number]': {
                '-moz-appearance': 'textfield'
            },
            '&:-webkit-autofill': {
                '-webkit-box-shadow': '0 0 0 30px white inset',
                color: theme.palette.grey[800],
                fontFamily: 'robotoRegular'
            },
            '&:-internal-autofill-selected': {
                color: theme.palette.grey[800]
            }
            },
        '& .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd': {
            paddingRight: '14px'
        },
    },
    HyperLink: {
        color: 'blue',
        textAlign: 'right',
        marginTop: '5px',
        display: 'flex',
        justifyContent: 'flex-end',
        marginRight: '1rem',
        '& p': {
            fontSize: '.9rem'
        }
    },
    HelperSection: {
        display: 'flex',
        justifyContent: 'space-between',
        '& .MuiFormHelperText-root':{
            marginTop: '4px',
            marginLeft: '14px',
        }
    },
    label: {
        fontSize: '1rem',
    }
}));

export default useStyles;
