import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    WhatIsSabbi: {
        borderRadius: '20px',
        padding: '1.2rem',
        maxWidth: '100%'
    },
    WhatIsSabbi_Title: {
        color: theme.palette.primary.main,
        fontFamily: 'robotoBold',
        fontSize: '1rem',
        marginBottom: '1rem'
    }
}));

export default useStyles;
