import sabbiHttpInstance from '../../instances/sabbi.instance';
import { IParamsPreviewDocument } from './interface/IParamsPreviewDocument';
import { IPreviewDocumentPrivate } from './interface/IPreviewDocumentPrivate';
import { IPreviewDocumentPublic } from './interface/IPreviewDocumentPublic';
import { IPreviewDocumentResponse } from './interface/IPreviewDocumentResponse';

export const previewDocumentPrivate = async (
    data: IPreviewDocumentPrivate,
    params: IParamsPreviewDocument
): Promise<IPreviewDocumentResponse> => {
    const {
        data: { payload }
    } = await sabbiHttpInstance.post(
        `/document/preview-document/private/${params.typeDocument}`,
        data
    );
    return payload;
};

export const previewDocumentPublic = async (
    data: IPreviewDocumentPublic,
    params: IParamsPreviewDocument
): Promise<IPreviewDocumentResponse> => {
    const {
        data: { payload }
    } = await sabbiHttpInstance.post(
        `/document/preview-document/public/${params.typeDocument}`,
        data
    );
    return payload;
};
