import { Button, Typography } from '@material-ui/core';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import { FC, useState } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import SabbiLogo from '../../assets/logo/sabbi.svg';
import AlertBar from '../../components/AlertBar/AlertBar';
import { IErrorValues } from '../../components/AlertBar/interfaces/IErrorValues';
import ContentBox from '../../components/ContentBox/ContentBox';
import { FormContext } from '../../components/FormsContext/FormContext/FormContext';
import RoundButton from '../../components/FormsContext/RoundButton/RoundButton';
import RutInput from '../../components/FormsContext/RutInput/RutInput';
import { useBackdrop } from '../../context/BackdropContext/backdrop';
import { useTpa } from '../../context/TPAContext/tpa';
import { useRedirection } from '../../hooks/useRedirection';
import { EOtpRoutePaths } from '../../routes/enum/EOtpRoutePaths';
import { generateNewOtpService } from '../../services/sabbi/otp/otp';
import { validateDocumentNumberService } from '../../services/sabbi/user/user';
import { EChannel } from '../../shared/enums/EChannel';
import { EChannelType } from '../../shared/enums/EChannelType';
import { getDataDevice } from '../../utils/device/dataDeviceHelpers';
import { EFlowIndicator } from '../../utils/enums/EFlowIndicator';
import { EStatusAccount } from '../../utils/enums/EStatusAccount';
import { serviceErrorMapper } from '../../utils/errors';
import { ApplicationError } from '../../utils/errors/ApplicationError';
import { SABBI_FRONT_ERRORS } from '../../utils/errors/enums/EAppErrors';
import { clean } from '../../utils/rutHelpers';
import useStyles from './PasswordRecoveryPageStyles.material';

type Inputs = {
    documentNumber?: string;
    channel?: string;
    channelType?: string;
    documentType?: string;
};

const PasswordRecoveryPage: FC = () => {
    const [errorValues, setErrorValues] = useState<IErrorValues>({
        title: '',
        visible: false
    });
    const classes = useStyles();
    const history = useHistory();
    const backdrop = useBackdrop();
    const tpaContext = useTpa();
    const { handleErrorRedirection, shouldRedirect } = useRedirection();
    const defaultValues: Partial<Inputs> = {};
    const methods = useForm<FieldValues>({ mode: 'all', defaultValues });

    const onSubmit: SubmitHandler<any> = async (data) => {
        try {
            backdrop.openBackdrop();
            const device = getDataDevice();
            const validateDocumentRequest = {
                documentNumber: clean(data.documentNumber),
                channel: EChannel.SABBI,
                channelType: EChannelType.WEB,
                device,
                TPA: tpaContext.tokenInfo.tpa as string
            };
            const validateDocument = await validateDocumentNumberService(validateDocumentRequest);
            if (
                validateDocument.statusAccount === EStatusAccount.Active ||
                validateDocument.statusAccount === EStatusAccount.Blocked
            ) {
                const userDevice = getDataDevice();
                const requestNewOtp = {
                    requestedByRut: clean(data.documentNumber),
                    device: userDevice,
                    TPA: tpaContext.tokenInfo.tpa as string
                };
                const newOtp = await generateNewOtpService(requestNewOtp);
                history.push({
                    pathname: EOtpRoutePaths.OTP_VALIDATION,
                    state: {
                        userRecoverPasswordData: {
                            rut: clean(data.documentNumber)
                        },
                        flow: EFlowIndicator.RECOVER_PASSWORD,
                        TPOTP: newOtp.TPOTP
                    }
                });
            } else {
                throw new ApplicationError(SABBI_FRONT_ERRORS.SABBI_F_08);
            }
        } catch (error) {
            const handledError = serviceErrorMapper(error);
            if (shouldRedirect(handledError)) {
                handleErrorRedirection(handledError);
            } else {
                showError(handledError.message);
            }
        } finally {
            backdrop.closeBackdrop();
        }
    };
    const handleBackRedirection = () => {
        history.goBack();
    };

    const showError = (title: string) => {
        setErrorValues({
            title: title,
            visible: true
        });
        handleAlertTimeout();
    };

    const handleAlertTimeout = () => {
        setTimeout(() => {
            clearError();
        }, 6000);
    };

    const clearError = () => {
        setErrorValues({
            title: '',
            visible: false
        });
    };

    return (
        <section className={classes.RecoverPasswordPage}>
            <ContentBox>
                <img className={classes.SabbiLogo} src={SabbiLogo} alt="SabbiLogo" />
                <Typography variant="h2" component="h2">
                    Creación de nueva contraseña
                </Typography>
                <Typography className={classes.RecoverText} component="p" gutterBottom>
                    Para crear una nueva contraseña ingresa tu RUT asociado a la cuenta
                </Typography>
                <FormContext
                    className={classes.Form}
                    id={'form-otp'}
                    methods={methods}
                    onSubmit={onSubmit}>
                    <AlertBar
                        classNames={classes.AlertBar}
                        variant="filled"
                        severity="error"
                        message={errorValues.title}
                        open={errorValues.visible}
                        closeOnClick={() => clearError()}
                        icon={<WarningRoundedIcon />}
                    />
                    <RutInput
                        classNames={classes.RutInput}
                        id={'documentNumber'}
                        name={'documentNumber'}
                        label={'RUT'}
                        maxLength={12}
                        rules={{
                            required: { value: true, message: 'El RUT es requerido' }
                        }}
                        icon={<AssignmentIndIcon />}
                    />
                    <div className={classes.ButtonsBox}>
                        <Button className={classes.CancelButton} onClick={handleBackRedirection}>
                            Cancelar
                        </Button>
                        <RoundButton
                            classNames={classes.SubmitButton}
                            id={'submitbtn'}
                            color="primary"
                            name={'submitbtn'}
                            type="submit">
                            Continuar
                        </RoundButton>
                    </div>
                </FormContext>
            </ContentBox>
        </section>
    );
};

export default PasswordRecoveryPage;
