import { AppBar, Button, Container, Toolbar, Typography } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useAuth } from '../../context/AuthContext/auth';
import { useBackdrop } from '../../context/BackdropContext/backdrop';
import { useTpa } from '../../context/TPAContext/tpa';
import { ECheckoutRoutePaths } from '../../routes/enum/ECheckoutRoutePaths';
import { ELoginRoutePaths } from '../../routes/enum/ELoginRoutePaths';
import { ENotAllowedRoutePaths } from '../../routes/enum/ENotAllowedRoutePaths';
import SBLogo from '../SBLogo/SBLogo';
import useStyles from './BasicHeaderStyles.material';

const BasicHeader: FC = () => {
    const classes = useStyles();
    const authContext = useAuth();
    const tpaContext = useTpa();
    const backdrop = useBackdrop();
    const history = useHistory();
    const location = useLocation();
    const TE = authContext.user.te as string;
    const TPA = tpaContext.tokenInfo.tpa as string;

    const handleClickCloseSession = async () => {
        try {
            backdrop.openBackdrop();
            const resp = await authContext.logout(TPA);
            if (resp.logOut === true) {
                history.replace(ELoginRoutePaths.LOGIN);
            }
        } catch (error) {
            authContext.cleanTE();
            history.replace(ELoginRoutePaths.LOGIN);
        } finally {
            backdrop.closeBackdrop();
        }
    };

    const existInExcludedPaths = (pathName: string) => {
        const excludedPaths: string[] = [
            ECheckoutRoutePaths.CHECKOUT_PLUS_PAYMENT,
            ECheckoutRoutePaths.CHECKOUT_LOGGED_USER_PLUS_PAYMENT,
            ECheckoutRoutePaths.CHECKOUT_SUCCESS,
            ECheckoutRoutePaths.CHECKOUT_LOGGED_USER_SUCCESS,
            ENotAllowedRoutePaths.NOT_AUTHORIZED
        ];
        return excludedPaths.includes(pathName);
    };

    return (
        <AppBar className={classes.BasicHeader} position="sticky">
            <Container>
                <Toolbar className={classes.Toolbar}>
                    <SBLogo size="md" classNames={classes.SabbiLogo} />
                    {TE && TPA && !existInExcludedPaths(location.pathname) && (
                        <Button
                            className={classes.ExitContent}
                            onClick={handleClickCloseSession}
                            variant="contained"
                            disableRipple>
                            <Typography className={classes.ExitButtonText}>
                                Cerrar sesión
                            </Typography>
                            <ExitToAppIcon className={classes.ExitToAppIcon} />
                        </Button>
                    )}
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default BasicHeader;
