import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    CollapseSelectedCard: {
        textAlign: 'center'
    },
    SelectedCard: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        minHeight: '60px',
        borderRadius: '100px',
        justifyContent: 'center',
        '&.fill': {
          backgroundColor: theme.palette.secondary.light,
          border: `1px solid ${theme.palette.secondary.main}`,
        },
        '&.outline':{
          border: `1px solid ${theme.palette.primary.main}`,
        },
        '@media (max-width:500px)': {
          justifyContent: 'space-evenly',
        }
    },
    CreditCardImage: {
        marginRight: '.8rem',
        '@media (max-width:340px)': {
            marginRight: 0
        },
        '& img': {
            maxWidth: '40px'
        }
    },
    CardBrand: {
        fontSize: '1.1rem',
        textTransform: 'capitalize',
        marginRight: '.5rem',
        color: theme.palette.primary.dark,
        fontFamily: 'robotoMedium',
        '@media (max-width:450px)': {
            display: 'none'
        }
    },
    Dots: {
        fontSize: '4rem',
        lineHeight: 0,
        letterSpacing: '13px',
        color: theme.palette.primary.main,
        marginRight: '-5px',
        '@media (max-width:450px)': {
            fontSize: '2rem'
        }
    },
    CardNumber: {
        fontSize: '1.1rem',
        color: theme.palette.primary.dark,
        fontFamily: 'robotoMedium',
        '@media (max-width:450px)': {
            fontSize: '1rem'
        }
    }
}));

export default useStyles;
