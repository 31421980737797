import {
    Button,
    CircularProgress,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { Controller, FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';

import SabbiLogoSrc from '../../assets/logo/sabbi.svg';
import AlertBar from '../../components/AlertBar/AlertBar';
import { IErrorValues } from '../../components/AlertBar/interfaces/IErrorValues';
import BnplDetailTable from '../../components/BnplDetailTable/BnplDetailTable';
import { IDetailBnpl } from '../../components/BnplDetailTable/interfaces/IDetailBnpl';
import ContentBox from '../../components/ContentBox/ContentBox';
import DocumentsList from '../../components/DocumentsList/DocumentsList';
import { DocumentItem } from '../../components/DocumentsList/interfaces/IDocumentsList';
import { BNPLRadioButton } from '../../components/FormsContext/BNPLRadioButton/BNPLRadioButton';
import {
    IBNPLRadioButton,
    PAGOPSPVALUE
} from '../../components/FormsContext/BNPLRadioGroup/interfaces/IBNPLRadioGroupProps';
import CheckboxInput from '../../components/FormsContext/CheckboxInput/CheckboxInput';
import { FormContext } from '../../components/FormsContext/FormContext/FormContext';
import RoundButton from '../../components/FormsContext/RoundButton/RoundButton';
import HyperLink from '../../components/HyperLink/HyperLink';
import ModalDialog from '../../components/ModalDialog/ModalDialog';
import ModalError from '../../components/ModalError/ModalError';
import SelectedCard from '../../components/SelectedCard/SelectedCard';
import { ITransactionDetailInfo } from '../../components/TransactionDetail/interfaces/ITransactionDetailInfo';
import TransactionDetail from '../../components/TransactionDetail/TransactionDetail';
import DownloadFile from '../../components/ViewFile/ViewFile';
import { useAuth } from '../../context/AuthContext/auth';
import { useBackdrop } from '../../context/BackdropContext/backdrop';
import { EModalError } from '../../context/ModalContext/enums/EModalError';
import { useModalExpired } from '../../context/ModalContext/modalExpired';
import { useTpa } from '../../context/TPAContext/tpa';
import { useRedirection } from '../../hooks/useRedirection';
import { ECardRoutePaths } from '../../routes/enum/ECardRoutePaths';
import { ECheckoutRoutePaths } from '../../routes/enum/ECheckoutRoutePaths';
import { ELoginRoutePaths } from '../../routes/enum/ELoginRoutePaths';
import { previewDocumentPrivate } from '../../services/sabbi/document/document';
import { ESabbiTemplates } from '../../services/sabbi/document/enum/ESabbiTemplates';
import { IParamsPreviewDocument } from '../../services/sabbi/document/interface/IParamsPreviewDocument';
import { IPreviewDocumentPrivate } from '../../services/sabbi/document/interface/IPreviewDocumentPrivate';
import { ITransactionSimulation } from '../../services/sabbi/document/interface/ITransactionSimulation';
import { IPurchaseBNPL } from '../../services/sabbi/transaction/interfaces/IPurchaseBNPL';
import { ITransactionCharge } from '../../services/sabbi/transaction/interfaces/ITransactionCharge';
import {
    oneClickTransaction,
    purchaseBNPLService
} from '../../services/sabbi/transaction/transaction';
import { ECardType } from '../../shared/enums/ECardType';
import { cancelTRXandReturnTrade, thousandSeparator } from '../../utils/clientHelpers';
import { getDataDevice } from '../../utils/device/dataDeviceHelpers';
import { IDevice } from '../../utils/device/interfaces/IDevice';
import { EPaymentCategory } from '../../utils/enums/EPaymentCategory';
import { serviceErrorMapper } from '../../utils/errors';
import { handleRequestTokenCharge } from '../../utils/kushkiHelpers';
import { getTEInformation, getTPAInformation } from '../../utils/localStorageManipulation';
import useStyles from './CardPaymentDetailPageStyles.material';
import { EDocumentTitle } from './enum/EDocumentTitle';
import { IBnplPayments } from './interfaces/IBnplPayments';
import IModalValuesState from './interfaces/IModalValuesState';
import { IPaymentDetailPageLocationState } from './interfaces/IPaymentDetailPageLocationState';
import ISelectedCardInfoState from './interfaces/ISelectedCardInfoState';

type PaymentMethod = {
    paymentMethod?: string;
    checkTerms?: boolean;
};

const CardPaymentDetailPage: FC = () => {
    const [hasBnpl, setHasBnpl] = useState<boolean>(false);
    const [selectedCardInfo, setSelectedCardInfo] = useState<ISelectedCardInfoState>({
        subscriptionId: '',
        idCard: '',
        cardBrand: '',
        cardMaskedNumber: '',
        bnplId: '',
        cardType: ''
    });
    const [detailBNPL, setDetailBNPL] = useState<IDetailBnpl>();
    const [detailPayment, setDetailPayment] = useState<IBNPLRadioButton>();
    const [paymentBNPLDocument, setPaymentBNPLDocument] = useState<ITransactionSimulation>();
    const [modalValues, setModalValues] = useState<IModalValuesState>({
        viewDocumentModal: false,
        errorDocumentModal: false
    });
    const [infoTransactionTpa, setInfoTransactionTpa] = useState<ITransactionDetailInfo>();
    const [BnplPayments, setBnplPayments] = useState<IBnplPayments[]>([]);
    const [errorValues, setErrorValues] = useState<IErrorValues>({
        title: '',
        visible: false
    });
    const [documentBase64, setDocumentBase64] = useState<string>('');
    const [userDocuments, setUserDocuments] = useState<DocumentItem[]>([]);
    const [selectedDocument, setSelectedDocument] = useState<DocumentItem>();
    const history = useHistory();
    const location = useLocation<IPaymentDetailPageLocationState>();
    const backdrop = useBackdrop();
    const authContext = useAuth();
    const tpaContext = useTpa();
    const classes = useStyles();
    const modalContext = useModalExpired();

    const defaultValues: Partial<PaymentMethod> = {
        paymentMethod: location.state?.paymentMethods || '',
        checkTerms: false
    };

    const { handleErrorRedirection, shouldRedirect } = useRedirection();
    const methods = useForm<FieldValues>({ mode: 'all', defaultValues });

    const BNPL_CREDIT_DOCUMENTS = [
        {
            documentTitle: EDocumentTitle.SABBI_DIGITAL_CARD_CONTRACT,
            documentId: ESabbiTemplates.SABBI_DIGITAL_CARD_CONTRACT,
            base64: '',
            read: false
        },
        {
            documentTitle: EDocumentTitle.SABBI_SUMMARY_SHEET,
            documentId: ESabbiTemplates.SABBI_SUMMARY_SHEET,
            base64: '',
            read: false
        },
        {
            documentTitle: EDocumentTitle.PAT_SABBI_MANDATE,
            documentId: ESabbiTemplates.SABBI_PAT_MANDATE,
            base64: '',
            read: false
        },
        {
            documentTitle: EDocumentTitle.SABBI_CUSTOMER_SERVICE_AGREEMENT,
            documentId: ESabbiTemplates.SABBI_CUSTOMER_SERVICE_AGREEMENT,
            base64: '',
            read: false
        }
    ];
    const BNPL_DEBIT_DOCUMENTS = [
        {
            documentTitle: EDocumentTitle.SABBI_DIGITAL_CARD_CONTRACT,
            documentId: ESabbiTemplates.SABBI_DIGITAL_CARD_CONTRACT,
            base64: '',
            read: false
        },
        {
            documentTitle: EDocumentTitle.SABBI_SUMMARY_SHEET,
            documentId: ESabbiTemplates.SABBI_SUMMARY_SHEET,
            base64: '',
            read: false
        },
        {
            documentTitle: EDocumentTitle.PAC_SABBI_MANDATE,
            documentId: ESabbiTemplates.SABBI_PAC_MANDATE,
            base64: '',
            read: false
        },
        {
            documentTitle: EDocumentTitle.SABBI_CUSTOMER_SERVICE_AGREEMENT,
            documentId: ESabbiTemplates.SABBI_CUSTOMER_SERVICE_AGREEMENT,
            base64: '',
            read: false
        }
    ];

    useEffect(() => {
        if (!validateLocationStateData(location.state)) {
            if (authContext.user.te) {
                history.replace(ECardRoutePaths.CARD_SELECT);
            } else {
                history.replace(ELoginRoutePaths.LOGIN);
            }
        } else {
            obtainInfo();
        }
    }, []);

    /**
     * Validates incoming location.state data
     * @param {IPaymentDetailPageLocationState} obj object with location.state data
     * @return {boolean}
     */
    const validateLocationStateData = (obj: IPaymentDetailPageLocationState): boolean => {
        if (obj) {
            return (
                'success' in obj &&
                'paymentMethods' in obj &&
                'meta' in obj &&
                'subscriptionId' in obj.meta &&
                'idCard' in obj.meta &&
                'cardBrand' in obj.meta &&
                'cardMaskedNumber' in obj.meta &&
                'cardType' in obj.meta
            );
        }
        return false;
    };

    /**
     * Cancel transaction and return to businessPartner URL
     * TODO: Migrate getRedirectUrlTrade to a custom hook to prevent duplicated code
     * @return {Promise<void>}
     */
    const getRedirectUrlTrade = async (): Promise<void> => {
        backdrop.openBackdrop();
        try {
            await cancelTRXandReturnTrade(tpaContext.tokenInfo.tpa as string);
        } catch (error) {
            const handledError = serviceErrorMapper(error);
            if (shouldRedirect(handledError)) {
                handleErrorRedirection(handledError);
            } else {
                showError(handledError.message);
            }
        } finally {
            backdrop.closeBackdrop();
        }
    };

    /**
     * Update states with transaction information
     * @return {Promise<void>}
     */
    const obtainInfo = async (): Promise<void> => {
        backdrop.openBackdrop();
        const TEInfo = await getTEInformation(authContext.user.te);
        const rut = TEInfo.bodyTE.user.rut;
        const infoTPA = await getTPAInformation(tpaContext.tokenInfo.tpa);
        const infoTPAFormated: ITransactionDetailInfo = {
            businessPartnerLogo: infoTPA?.bodyTPA.businessPartner?.businessPartnerLogo,
            businessPartnerName: infoTPA?.bodyTPA.businessPartner?.businessPartnerName,
            productList: infoTPA?.bodyTPA.transactionDetails?.orderDetails.productList,
            total: infoTPA?.bodyTPA.transactionDetails?.orderDetails.amount.total,
            transactionId: infoTPA?.bodyTPA.transactionDetails?.orderDetails.transactionId,
            purchaseId: infoTPA?.bodyTPA.metadata?.trxPmi as string
        };
        setInfoTransactionTpa(infoTPAFormated);
        const selectCardPayment = {
            subscriptionId: location.state.meta.subscriptionId,
            idCard: location.state.meta.idCard,
            cardBrand: location.state.meta.cardBrand,
            cardMaskedNumber: location.state.meta.cardMaskedNumber,
            bnplId: location.state.meta.BNPLInfo?.id || 0,
            cardType: location.state.meta.cardType
        };
        setSelectedCardInfo(selectCardPayment);
        const { BNPLInfo } = location.state.meta;
        if (location.state.paymentMethods === EPaymentCategory.BNPL && BNPLInfo) {
            const selectPayment: IBNPLRadioButton = {
                label: `Pago inicial (Pie) + ${BNPLInfo.term} cuotas`,
                amount: Number(BNPLInfo.quotaValue),
                caption: `Pago de un Pie inmediato`,
                priceCaption: `Costo Total $ ${thousandSeparator(
                    BNPLInfo.initialQuotaPlusCtc,
                    '.'
                )}`,
                isBnpl: true,
                value: String(BNPLInfo.id),
                key: `BNPL-${BNPLInfo.term}-${BNPLInfo.id}`
            };
            setDetailPayment(selectPayment);
            const selectPaymentDetailBNPL: IDetailBnpl = {
                initialQuota: `$ ${thousandSeparator(Number(BNPLInfo.initialQuota), '.')}`,
                initialQuotaPlusCtc: `$ ${thousandSeparator(
                    Number(BNPLInfo.initialQuotaPlusCtc),
                    '.'
                )}`,
                ctc: `$ ${thousandSeparator(BNPLInfo.ctc, '.')}`,
                cae: `${BNPLInfo.cae}`,
                interest: `${BNPLInfo.interest}`,
                ite: `$ ${thousandSeparator(BNPLInfo.ite, '.')}`,
                annualRate: `${BNPLInfo.annualRate}`,
                term: BNPLInfo.term,
                quotaValue: `$ ${thousandSeparator(BNPLInfo.quotaValue, '.')}`,
                expirationDate: String(
                    moment(String(BNPLInfo?.expirationDate)).format('DD/MM/YYYY')
                ),
                transactionTotal: `$ ${thousandSeparator(Number(infoTPAFormated?.total), '.')}`
            };
            setDetailBNPL(selectPaymentDetailBNPL);
            const simulationDocumentBNPL: ITransactionSimulation = {
                id: BNPLInfo.id || 0,
                codAutorizacion: BNPLInfo.authorizationCode || 0,
                capital: BNPLInfo.capital || 0,
                plazo: BNPLInfo.term || 0,
                rut,
                tasaAnual: BNPLInfo.annualRate || 0,
                CAE: `${BNPLInfo.cae}` || '',
                valorCuota: BNPLInfo.quotaValue || 0,
                interes: BNPLInfo.interest || 0,
                tasaInteres: BNPLInfo.interest || 0,
                comision: BNPLInfo.commission || 0,
                fechaFacturacion: BNPLInfo.billingDate || 0,
                fechaVencimiento: BNPLInfo.expirationDate || 0,
                createdAt: moment().format('DD-MM-YYYY') || '',
                ITE: BNPLInfo.ite || 0,
                CTC: BNPLInfo.ctc || 0
            };
            if (location.state.meta.cardType === ECardType.DEBIT) {
                setUserDocuments(BNPL_DEBIT_DOCUMENTS);
            } else {
                setUserDocuments(BNPL_CREDIT_DOCUMENTS);
            }
            setPaymentBNPLDocument(simulationDocumentBNPL);
            BNPLPaymentsData();
            setHasBnpl(true);
        } else {
            const selectPaymentPSP = {
                label: 'Pago único de ',
                amount: infoTPA?.bodyTPA.transactionDetails?.orderDetails.amount.total as number,
                caption: 'Costo total ',
                isBnpl: false,
                priceCaption: `$ ${thousandSeparator(
                    Number(
                        JSON.stringify(
                            infoTPA?.bodyTPA.transactionDetails?.orderDetails.amount.total as number
                        )
                    ),
                    '.'
                )}`,
                value: PAGOPSPVALUE,
                key: PAGOPSPVALUE
            };
            setDetailPayment(selectPaymentPSP);
        }
        backdrop.closeBackdrop();
    };

    /**
     * Submit form information
     * @param {Inputs} data Inputs information
     * @return {Promise<void>}
     */
    const onSubmit: SubmitHandler<PaymentMethod> = async (data) => {
        try {
            backdrop.openBackdrop();
            const TEInfo = await getTEInformation(authContext.user?.te as string);
            if (TEInfo.isExpired) {
                modalContext.updateModalExpired(EModalError.DEFAULT);
                modalContext.setExpiredTE(true);
                modalContext.openModal();
            } else {
                const transactionToken = await handleRequestTokenCharge({
                    subscriptionId: selectedCardInfo.subscriptionId
                });
                const device: IDevice = getDataDevice();
                if (String(data.paymentMethod) == EPaymentCategory.BNPL) {
                    const purchaseBNPLServiceRequest: IPurchaseBNPL = {
                        idSimulation: Number(selectedCardInfo.bnplId),
                        idCard: selectedCardInfo.idCard,
                        TE: authContext.user?.te as string,
                        TPA: tpaContext.tokenInfo.tpa as string,
                        token: transactionToken.token,
                        device
                    };
                    const purchaseBNPLServiceResponse = await purchaseBNPLService(
                        purchaseBNPLServiceRequest
                    );
                    history.replace({
                        pathname: ECheckoutRoutePaths.CHECKOUT_LOGGED_USER_SUCCESS,
                        state: {
                            success: true,
                            paymentMethods: EPaymentCategory.BNPL,
                            transaction: purchaseBNPLServiceResponse,
                            bnplDetailCuotas: BnplPayments
                        }
                    });
                } else {
                    const requestTransactionData: ITransactionCharge = {
                        idCard: selectedCardInfo.idCard,
                        token: transactionToken.token,
                        device,
                        TE: authContext.user?.te as string,
                        TPA: tpaContext.tokenInfo.tpa as string
                    };
                    const transactionResponse = await oneClickTransaction(requestTransactionData);
                    history.replace({
                        pathname: ECheckoutRoutePaths.CHECKOUT_LOGGED_USER_SUCCESS,
                        state: {
                            success: true,
                            paymentMethods: EPaymentCategory.PSP,
                            transaction: transactionResponse
                        }
                    });
                }
            }
        } catch (error: any) {
            if (shouldRedirect(error)) {
                handleErrorRedirection(error);
            } else {
                const serviceError = serviceErrorMapper(error);
                let TT = serviceError.payload?.TT;
                let rejectionReasons = undefined;
                if (TT === undefined || TT === null) {
                    const cancelTrxResponse = await cancelTRXandReturnTrade(
                        tpaContext.tokenInfo.tpa as string,
                        true
                    );
                    TT = cancelTrxResponse;
                }
                if (error.payload.decisionParsed) {
                    rejectionReasons = {
                        reasons: error.payload.decisionParsed.reasons
                    };
                }
                history.replace({
                    pathname: ECheckoutRoutePaths.CHECKOUT_LOGGED_USER_SUCCESS,
                    state: {
                        success: false,
                        transaction: {
                            TT
                        },
                        paymentMethods: location.state?.paymentMethods,
                        bnplRejectionReasons: rejectionReasons
                    }
                });
            }
        } finally {
            backdrop.closeBackdrop();
        }
    };
    const BNPLPaymentsData = async () => {
        for (let index = 0; index < Number(location.state.meta.BNPLInfo?.term); index++) {
            BnplPayments.push({
                key: String(index + 1),
                amount: Number(location.state.meta.BNPLInfo?.quotaValue),
                fee: index + 1,
                date: String(
                    moment(String(location.state.meta.BNPLInfo?.expirationDate))
                        .add(index, 'M')
                        .format('DD/MM/YYYY')
                )
            });
        }
        setBnplPayments(BnplPayments);
    };
    const handleOnChange = (value: boolean | undefined) => {
        const readDocuments = [...userDocuments];
        readDocuments.map((document) => {
            document.read = true;
        });
        setUserDocuments(readDocuments);
    };

    /**
     * Opens modal showing selected Document by id
     * @param idDocument string
     * @returns {void}
     */
    const viewDocument = async (idDocument: ESabbiTemplates): Promise<void> => {
        const readDocuments = [...userDocuments];
        try {
            const TEInfo = await getTEInformation(authContext.user?.te as string);
            if (TEInfo.isExpired) {
                modalContext.updateModalExpired(EModalError.DEFAULT);
                modalContext.setExpiredTE(true);
                modalContext.openModal();
            } else {
                backdrop.openBackdrop();
                for (let index = 0; index < readDocuments.length; index++) {
                    if (readDocuments[index].documentId === idDocument) {
                        setSelectedDocument(readDocuments[index]);
                        if (readDocuments[index].base64 == '') {
                            const base64 = await getDocumentBase64(idDocument);
                            readDocuments[index].base64 = base64;
                            readDocuments[index].read = true;
                            setUserDocuments(readDocuments);
                            setDocumentBase64(base64);
                            handleShowModal('viewDocumentModal', true);
                        } else {
                            setDocumentBase64(readDocuments[index].base64);
                            handleShowModal('viewDocumentModal', true);
                        }
                        backdrop.closeBackdrop();
                        break;
                    }
                }
            }
        } catch (error) {
            backdrop.closeBackdrop();
            handleShowModal('errorDocumentModal', true);
        }
    };

    /**
     * Get document from PreviewDocument service by id
     * @param idDocument string
     * @returns {void}
     */
    const getDocumentBase64 = async (idDocument: ESabbiTemplates) => {
        const maskedCardNumber = selectedCardInfo.cardMaskedNumber;
        const paramPreviewDocument: IParamsPreviewDocument = {
            typeDocument: idDocument
        };
        const dataPreviewDocument: IPreviewDocumentPrivate = {
            TPA: tpaContext.tokenInfo.tpa as string,
            TE: authContext.user?.te as string,
            device: getDataDevice(),
            metadata: {
                simulation: paymentBNPLDocument as ITransactionSimulation,
                maskedCardNumber
            }
        };
        const docBase64 = await previewDocumentPrivate(dataPreviewDocument, paramPreviewDocument);
        return docBase64.documentBase64;
    };
    const handleShowModal = (modalName: keyof IModalValuesState, show: boolean) => {
        setModalValues({
            ...modalValues,
            [modalName]: show
        });
    };
    const closeModals = () => {
        setModalValues({
            viewDocumentModal: false,
            errorDocumentModal: false
        });
        setTimeout(() => {
            setDocumentBase64('');
        }, 500);
    };
    const handleBackRedirection = () => {
        history.goBack();
    };
    const handleAlertTimeout = () => {
        setTimeout(() => {
            clearError();
        }, 6000);
    };
    const showError = (title: string) => {
        setErrorValues({
            title: title,
            visible: true
        });
        handleAlertTimeout();
    };
    const clearError = () => {
        setErrorValues({
            title: '',
            visible: false
        });
    };
    return (
        <section className={classes.PaymentDetailPage}>
            <Grid container>
                <Grid item xs={12} lg={12}>
                    <div className={classes.HyperLinkBox}>
                        <HyperLink
                            classNames={classes.HyperLink_GoBack}
                            underline="none"
                            onClick={getRedirectUrlTrade}>
                            <ChevronLeftIcon />
                            volver al comercio
                        </HyperLink>
                    </div>
                </Grid>
                <Grid container className={classes.MainGrid} spacing={2}>
                    <Grid item xs={12} md={7} lg={7}>
                        <ContentBox classNames={classes.ContentBox}>
                            <img className={classes.SabbiLogo} src={SabbiLogoSrc} alt="LogoSabbi" />
                            <Typography variant="h2" component="h2" gutterBottom>
                                Este es tu detalle de pago
                            </Typography>
                            <AlertBar
                                classNames={classes.AlertBar}
                                variant="filled"
                                severity="error"
                                message={errorValues.title}
                                open={errorValues.visible}
                                closeOnClick={() => clearError()}
                                icon={<WarningRoundedIcon />}
                            />
                            <SelectedCard
                                classNames={classes.SelectedCard}
                                cardBrand={selectedCardInfo?.cardBrand}
                                cardMaskedNumber={selectedCardInfo?.cardMaskedNumber}
                                cardType={selectedCardInfo.cardType}
                                captionText=""
                            />
                            <BNPLRadioButton
                                classNames={classes.BNPLRadioButton}
                                isChecked={false}
                                key={detailPayment?.key ? detailPayment?.key : ''}
                                label={detailPayment?.label ? detailPayment?.label : ''}
                                value={detailPayment?.value ? detailPayment?.value : ''}
                                caption={detailPayment?.caption ? detailPayment?.caption : ''}
                                priceCaption={
                                    detailPayment?.priceCaption ? detailPayment?.priceCaption : ''
                                }
                            />
                            <FormContext
                                id={'ConfirmPaymentForm'}
                                onSubmit={onSubmit}
                                methods={methods}>
                                <Controller
                                    name="paymentMethod"
                                    rules={{
                                        required: { value: true, message: '' }
                                    }}
                                    render={({ field }) => {
                                        return <input type="hidden" {...field} />;
                                    }}
                                />

                                {hasBnpl ? (
                                    <>
                                        {detailBNPL && <BnplDetailTable detailBNPL={detailBNPL} />}

                                        <DocumentsList
                                            docsArray={userDocuments}
                                            readOnClick={viewDocument}
                                        />

                                        <CheckboxInput
                                            classNames={classes.Checkbox}
                                            id="checkTerms"
                                            clickableLabel={false}
                                            onChangeCheck={handleOnChange}
                                            label={
                                                <Typography
                                                    className={classes.Checkbox_Hyperlink}
                                                    color="initial">
                                                    He leído y tomado conocimiento de todos los
                                                    contratos indicados.
                                                </Typography>
                                            }
                                            name={'checkTerms'}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message:
                                                        'Debe aceptar los los contratos indicados'
                                                }
                                            }}
                                        />

                                        <ModalDialog
                                            id="ModalViewDocument"
                                            open={modalValues.viewDocumentModal}
                                            handleCancel={closeModals}
                                            acceptButtonType="submit"
                                            scroll={'paper'}
                                            acceptText="Confirmar pago"
                                            title={
                                                <div className={classes.ModalViewDocument_Title}>
                                                    <img
                                                        className={classes.ModalViewDocument_Logo}
                                                        src={SabbiLogoSrc}
                                                        alt="Logo sabbi"
                                                    />{' '}
                                                    <Typography component="h2">
                                                        {selectedDocument?.documentTitle}
                                                    </Typography>
                                                </div>
                                            }
                                            maxWidth="md"
                                            customFooter={
                                                <div className={classes.ButtonsBox}>
                                                    <RoundButton
                                                        classNames={
                                                            classes.ModalViewDocument_NextDocumentButton
                                                        }
                                                        onClick={closeModals}
                                                        color="primary"
                                                        type="button">
                                                        Volver
                                                    </RoundButton>
                                                </div>
                                            }>
                                            <div>
                                                <div className={classes.ModalViewDocument_PDF}>
                                                    {documentBase64 === '' ? (
                                                        <div
                                                            className={
                                                                classes.ModalViewDocument_Loader
                                                            }>
                                                            <CircularProgress color="primary" />
                                                            <Typography component="p" gutterBottom>
                                                                Cargando Documento
                                                            </Typography>
                                                        </div>
                                                    ) : (
                                                        <DownloadFile dataFile={documentBase64} />
                                                    )}
                                                </div>
                                            </div>
                                        </ModalDialog>
                                        <ModalError
                                            id="errorDocumentModal"
                                            open={modalValues.errorDocumentModal}
                                            handleAccept={closeModals}
                                            scroll={'paper'}
                                            fullWidth={false}
                                            maxWidth="sm"
                                            title="¡Lo sentimos!"
                                            customFooter={
                                                <>
                                                    <Button
                                                        id={'backcommercebtn'}
                                                        name={'backcommercebtn'}
                                                        className={classes.GuestButton}
                                                        type="button"
                                                        onClick={getRedirectUrlTrade}>
                                                        Volver más tarde
                                                    </Button>
                                                    <RoundButton
                                                        id={'backsimulationbtn'}
                                                        name={'contibacksimulationbtnnuebtn'}
                                                        type="button"
                                                        color="primary"
                                                        onClick={handleBackRedirection}>
                                                        Continuar
                                                    </RoundButton>
                                                </>
                                            }>
                                            <Typography component="p">
                                                Ocurrió un error de carga en algunos de los
                                                documentos legales, por lo que no será posible
                                                completar tu pago en cuotas. Por favor, selecciona
                                                una nueva forma de pago o vuelve a intentarlo más
                                                tarde.
                                            </Typography>
                                        </ModalError>
                                    </>
                                ) : (
                                    <></>
                                )}
                                <div className={classes.ButtonsBox}>
                                    <Button
                                        className={classes.GuestButton}
                                        type="button"
                                        onClick={handleBackRedirection}>
                                        Volver
                                    </Button>
                                    <RoundButton
                                        classNames={classes.SubmitButton}
                                        id={'submitbtn'}
                                        color="primary"
                                        name={'submitbtn'}
                                        type="submit">
                                        {hasBnpl ? 'Firmar y pagar' : 'Aceptar y pagar'}
                                    </RoundButton>
                                </div>
                            </FormContext>
                        </ContentBox>
                    </Grid>

                    <Grid item xs={12} md={5} lg={5}>
                        {infoTransactionTpa && (
                            <TransactionDetail infoTransactionTpa={infoTransactionTpa} />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </section>
    );
};
export default CardPaymentDetailPage;
