import { Button, Grid, Link, Typography } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import ModalErrorIconSrc from '../../assets/icons/modal-error-icon.svg';
import ClaveUnicaLogo from '../../assets/logo/claveunicaLogo.svg';
import SabbiLogo from '../../assets/logo/sabbi.svg';
import AlertBar from '../../components/AlertBar/AlertBar';
import { IErrorValues } from '../../components/AlertBar/interfaces/IErrorValues';
import ContentBox from '../../components/ContentBox/ContentBox';
import RoundButton from '../../components/FormsContext/RoundButton/RoundButton';
import HyperLink from '../../components/HyperLink/HyperLink';
import ModalDialog from '../../components/ModalDialog/ModalDialog';
import { useAuth } from '../../context/AuthContext/auth';
import { useBackdrop } from '../../context/BackdropContext/backdrop';
import { useTpa } from '../../context/TPAContext/tpa';
import { useRedirection } from '../../hooks/useRedirection';
import { ECardRoutePaths } from '../../routes/enum/ECardRoutePaths';
import { ELoginRoutePaths } from '../../routes/enum/ELoginRoutePaths';
import { IAuthenticateUniqueKey } from '../../services/sabbi/validation/interfaces/IAuthenticateUniqueKey';
import { authenticateUniqueKey } from '../../services/sabbi/validation/validation';
import { cancelTRXandReturnTrade } from '../../utils/clientHelpers';
import { getDataDevice } from '../../utils/device/dataDeviceHelpers';
import { serviceErrorMapper } from '../../utils/errors';
import { IUniqueKeyLocationState } from './interfaces/IUniqueKeyLocationState';
import useStyles from './UniqueKeyPageStyles.material';

const UniqueKeyPage: FC = () => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [errorValues, setErrorValues] = useState<IErrorValues>({
        title: '',
        visible: false
    });
    const classes = useStyles();
    const location = useLocation<IUniqueKeyLocationState>();
    const history = useHistory();
    const backdrop = useBackdrop();
    const tpaContext = useTpa();
    const authContext = useAuth();
    const { handleErrorRedirection, shouldRedirect } = useRedirection();

    useEffect(() => {
        if (!validateLocationStateData(location.state)) {
            if (!authContext.user?.te) {
                history.replace(ELoginRoutePaths.LOGIN);
            } else {
                history.replace(ECardRoutePaths.CARD_SELECT);
            }
        }
    }, []);

    const validateLocationStateData = (obj: IUniqueKeyLocationState) => {
        if (obj) {
            return (
                'userRegisterData' in obj &&
                'userContactData' in obj &&
                'flow' in obj &&
                'TOTP' in obj
            );
        }
        return false;
    };

    /**
     * Cancel transaction and return to businessPartner URL
     * @return {Promise<void>}
     */
    const getRedirectUrlTrade = async () => {
        backdrop.openBackdrop();
        try {
            await cancelTRXandReturnTrade(tpaContext.tokenInfo.tpa as string);
        } catch (error) {
            const handledError = serviceErrorMapper(error);
            if (shouldRedirect(handledError)) {
                handleErrorRedirection(handledError);
            } else {
                showError(handledError.message);
            }
        } finally {
            backdrop.closeBackdrop();
        }
    };
    /**
     * Remove error
     * @return {void}
     */
    const clearError = () => {
        setErrorValues({
            title: '',
            visible: false
        });
    };
    /**
     * Clear error on timeout
     * @return {void}
     */
    const handleAlertTimeout = () => {
        setTimeout(() => {
            clearError();
        }, 6000);
    };
    /**
     * Show Alert error and set error values state
     * @param {string} title error message
     * @return {void}
     */
    const showError = (title: string) => {
        setErrorValues({
            title: title,
            visible: true
        });
        handleAlertTimeout();
    };
    /**
     * Validate user through unique key authorization
     * @return {void}
     */
    const validateUniqueKey = async () => {
        setShowModal(false);
        backdrop.openBackdrop();
        try {
            const device = getDataDevice();
            const authenticateUniqueKeyRequest: IAuthenticateUniqueKey = {
                TPA: tpaContext.tokenInfo.tpa as string,
                device,
                documentNumber: location.state?.userRegisterData?.statusDoctoIdentidad
                    .numero as string,
                metadata: {
                    userRegisterData: location.state.userRegisterData,
                    userContactData: location.state.userContactData,
                    TOTP: location.state.TOTP,
                    flow: location.state.flow
                }
            };
            const validateResponse = await authenticateUniqueKey(authenticateUniqueKeyRequest);
            backdrop.closeBackdrop();
            window.location.href = validateResponse.redirectUrl;
        } catch (error) {
            const handledError = serviceErrorMapper(error);
            backdrop.closeBackdrop();
            showError(handledError.message);
        }
    };

    return (
        <section className={classes.UniqueKeyPage}>
            <Grid container>
                <Grid item xs={12} lg={12}>
                    <div className={classes.HyperLinkBox}>
                        <HyperLink
                            classNames={classes.HyperLink_GoBack}
                            underline="none"
                            onClick={getRedirectUrlTrade}>
                            <ChevronLeftIcon />
                            volver al comercio
                        </HyperLink>
                    </div>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} md={12}>
                    <ContentBox classNames={classes.ContentBox}>
                        <img className={classes.SabbiLogo} src={SabbiLogo} alt="SabbiLogo" />
                        <Typography variant="h2" component="h2">
                            Valida tu identidad
                        </Typography>
                        <Typography component="p" gutterBottom align="center">
                            Es importante validemos tu identidad con ClaveÚnica. Esto nos permitirá
                            proteger tu cuenta, perfil y datos personales, y podremos identificarte
                            como persona real y única.
                        </Typography>
                        <img
                            className={classes.ClaveUnica}
                            src={ClaveUnicaLogo}
                            alt="Clave única"
                        />
                        <AlertBar
                            classNames={classes.AlertBar}
                            variant="filled"
                            severity="error"
                            message={errorValues.title}
                            open={errorValues.visible}
                            closeOnClick={() => clearError()}
                            icon={<WarningRoundedIcon />}
                        />
                        <div className={classes.ButtonsBox}>
                            <RoundButton
                                classNames={classes.SubmitButton}
                                color="primary"
                                type="button"
                                onClick={() => validateUniqueKey()}>
                                Validar con Clave Única
                            </RoundButton>
                            <Button
                                type="button"
                                className={classes.CancelButton}
                                onClick={() => setShowModal(true)}>
                                No tengo Clave Única
                            </Button>
                        </div>
                    </ContentBox>
                </Grid>
            </Grid>
            <ModalDialog
                id="ModalCancel"
                open={showModal}
                handleCancel={validateUniqueKey}
                cancelText="Validar con Clave Única"
                handleAccept={getRedirectUrlTrade}
                acceptText="Salir"
                title={
                    <div className={classes.CancelModalTitle}>
                        <img
                            className={classes.InfoIcon}
                            src={ModalErrorIconSrc}
                            alt="Error Icon"
                        />
                        <Typography component="h2" variant="h2" gutterBottom>
                            Necesitamos validar tu identidad para la creación de una cuenta en sabbi
                        </Typography>
                    </div>
                }
                scroll={'paper'}
                maxWidth={'sm'}>
                <Typography component="p" className={classes.ModalText} align="center">
                    Solicitaremos tu ClaveÚnica para proteger tu identidad y <br /> datos
                    personales.{' '}
                    <b>
                        Si aún no la tienes, solicítala en{' '}
                        <Link href="https://claveunica.gob.cl/" target="_blank">
                            claveunica.gob.cl.
                        </Link>
                    </b>
                </Typography>
                <Typography component="p" className={classes.ModalText} align="center">
                    ¡Te esperamos de vuelta con tu Clave Única!
                </Typography>
            </ModalDialog>
        </section>
    );
};

export default UniqueKeyPage;
