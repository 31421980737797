import {
    Box,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography
} from '@material-ui/core';
import { FC, useCallback, useEffect, useRef, useState } from 'react';

import { thousandSeparator } from '../../../utils/clientHelpers';
import { IProduct } from '../../../utils/tokenManipulation/interfaces/IProduct';
import ContentBox from '../../ContentBox/ContentBox';
import CustomScrollbar from '../../CustomScrollbar/CustomScrollbar';
import { ITransactionDetail } from '../interfaces/ITransactionDetail';
import useStyles from './DesktopTransactionDetailStyles.material';

const DesktopTransactionDetail: FC<ITransactionDetail> = ({ infoTransactionTpa }) => {
    const classes = useStyles();
    const simplebar = useRef<HTMLDivElement>();

    const [showGradient, setShowGradient] = useState<boolean>(true);

    const handleScroll = useCallback(() => {
        if (simplebar.current) {
            const { scrollTop, scrollHeight, clientHeight } = simplebar.current;
            if (scrollTop + clientHeight === scrollHeight) {
                setShowGradient(false);
            } else {
                setShowGradient(true);
            }
        }
    }, []);

    useEffect(() => {
        const customScrollElement = simplebar.current!;
        customScrollElement.addEventListener('scroll', handleScroll);
        return () => {
            customScrollElement.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    const handleShowGradient = () => {
        if (infoTransactionTpa?.productList && infoTransactionTpa?.productList.length > 5) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <ContentBox classNames={classes.MyContentBox}>
            <Grid
                container
                className={classes.Header}
                alignItems="center"
                justifyContent="flex-start">
                <Grid item lg={12} className={classes.ContainerImgShop}>
                    <div className={classes.BusinessLogoContainer}>
                        <img
                            className={classes.LogoShop}
                            src={`${infoTransactionTpa?.businessPartnerLogo}`}
                            alt={`${infoTransactionTpa?.businessPartnerName}`}
                        />
                    </div>
                </Grid>
            </Grid>
            <Grid container className={classes.ContainerDetail}>
                <CustomScrollbar
                    classNames={handleShowGradient() ? classes.CustomScrollbar : undefined}
                    type={handleShowGradient() ? 'line' : undefined}
                    scrollableNodeRef={simplebar}>
                    <TableContainer className={classes.TableContainer}>
                        <Table aria-label="detail-transaction-table">
                            <TableBody>
                                {infoTransactionTpa?.productList &&
                                    infoTransactionTpa?.productList.map((row: IProduct) => (
                                        <TableRow key={row.sku}>
                                            <TableCell
                                                scope="row"
                                                className={classes.TableCellProduct}>
                                                <Typography
                                                    className={classes.ShopName}
                                                    component="h4">
                                                    {row.description}
                                                </Typography>
                                                <Typography
                                                    component="p"
                                                    className={classes.Quantity}>
                                                    ({row.quantity}){' '}
                                                    {Number(row.quantity) > 1
                                                        ? 'unidades'
                                                        : 'unidad'}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CustomScrollbar>
                {handleShowGradient() === showGradient ? (
                    <div className={classes.WhiteGradient}></div>
                ) : (
                    <> </>
                )}
                <Grid container className={classes.ContainerTotal}>
                    <Box display="flex" width="100%" justifyContent="space-between">
                        <Box>
                            <Typography className={classes.ShopName}>Total compra</Typography>
                        </Box>
                        <Box>
                            <Typography className={classes.TotalPrice}>
                                ${' '}
                                {thousandSeparator(
                                    infoTransactionTpa?.total ? infoTransactionTpa?.total : 0,
                                    '.'
                                )}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </ContentBox>
    );
};

export default DesktopTransactionDetail;
