import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    ModalDialog: {
        '& .MuiPaper-root': {
            padding: '3rem',
            borderRadius: '20px',
            ['@media (max-width: 600px)']: {
                padding: '2rem'
            },
            ['@media (max-width: 500px)']: {
                margin: 'auto',
                width: 'calc(100% - 45px)',
            },
            ['@media (max-width: 400px)']: {
                padding: '2rem 1rem'
            },
        },
        '& .MuiDialog-paperWidthSm': {
            maxWidth: '700px'
        }
    },
    SabbiLogo: {
        width: '95px',
        alignSelf: 'center',
        ['@media (max-width: 600px)']: {
            width: '75px'
        }
    },
    DialogTitle: {
        textAlign: 'center',
        '& h2': {
            fontFamily: 'robotoMedium',
            marginBottom: '1rem',
            fontSize: '1.2rem',
            color: theme.palette.grey[800],
            '@media (max-width:500px)': {
              marginBottom: 0,
              fontSize: '1rem'
            },
        }
    },
    DialogContent: {
        padding: '0 50px',
        color: theme.palette.grey[800],
        fontSize: '1rem',
        '@media (max-width:500px)': {
            padding: 0
        }
    },
    DialogActions: {
        maxWidth: '100%',
        flexWrap: 'wrap',
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        '&.customFooter': {
            padding: 0
        }
    },
    CancelButton: {
        color: theme.palette.grey[800],
        maxWidth: '225px',
        textTransform: 'none',
        margin: '1rem',
        marginBottom: 0,
        height: '40px',
    },
    RoundButton: {
        marginBottom: 0,
        height: '40px',
    }
}));

export default useStyles;
