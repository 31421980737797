import { HttpStatus } from '../enums/HttpStatus';
import { IApplicationError } from './ApplicationError';
import { ENameError } from './enums/ENameError';
import { IParsedError } from './interfaces/IParsedError';

/**
 * Returns a mapped error from service error response
 * @param {IApplicationError} error
 * @return {IParsedError}
 */
export const serviceErrorMapper = (error: IApplicationError): IParsedError => {
    const { code, message, payload, status } = error;
    const parsedError: IParsedError = {
        name: ENameError.SERVICE_ERROR,
        code: code || '400',
        message: message || 'Ha ocurrido un error, inténtalo nuevamente.',
        status: status || HttpStatus.BAD_REQUEST
    };
    if (payload) {
        parsedError.payload = payload;
    }
    return parsedError;
};
