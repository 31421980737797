export enum EValidBins {
    BIN_1 = '454886',
    BIN_2 = '450600',
    BIN_3 = '454812',
    BIN_4 = '543564',
    BIN_5 = '548652',
    BIN_6 = '518497',
    BIN_7 = '518370',
    BIN_8 = '534129',
    BIN_9 = '540782',
    BIN_10 = '450880',
    BIN_11 = '492210',
    BIN_12 = '417760',
    BIN_13 = '415283',
    BIN_14 = '480916',
    BIN_15 = '423471',
    BIN_16 = '467498',
    BIN_17 = '377826',
    BIN_18 = '496660',
    BIN_19 = '451248',
    BIN_20 = '475776',
    BIN_21 = '425944',
    BIN_22 = '377828',
    BIN_23 = '377825',
    BIN_24 = '476832',
    BIN_25 = '419189',
    BIN_26 = '416589',
    BIN_27 = '496624',
    BIN_28 = '494611',
    BIN_29 = '414756',
    BIN_30 = '496617',
    BIN_31 = '446867',
    BIN_32 = '434956',
    BIN_33 = '448165',
    BIN_34 = '456477',
    BIN_35 = '496670',
    BIN_36 = '442174',
    BIN_37 = '222690',
    BIN_38 = '525731',
    BIN_39 = '540476',
    BIN_40 = '549163',
    BIN_41 = '517980',
    BIN_42 = '546689',
    BIN_43 = '548653',
    BIN_44 = '541678',
    BIN_45 = '554926',
    BIN_46 = '554610',
    BIN_47 = '518850',
    BIN_48 = '520079',
    BIN_49 = '554940',
    BIN_50 = '549162',
    BIN_51 = '543565',
    BIN_52 = '543563',
    BIN_53 = '540491',
    BIN_54 = '540477',
    BIN_55 = '521892',
    BIN_56 = '552300',
    BIN_57 = '515658',
    BIN_58 = '545196',
    BIN_59 = '521312',
    BIN_60 = '522832',
    BIN_61 = '552273',
    BIN_62 = '553656',
    BIN_63 = '549451',
    BIN_64 = '548569',
    BIN_65 = '558727',
    BIN_66 = '554904',
    BIN_67 = '552252',
    BIN_68 = '543696',
    BIN_69 = '559801',
    BIN_70 = '559800',
    BIN_71 = '520387',
    BIN_72 = '545153',
    BIN_73 = '544088',
    BIN_74 = '531663',
    BIN_75 = '545646'
}
