import { useState } from 'react';
import { FC, useEffect } from 'react';
import { RouteProps, useHistory, useLocation } from 'react-router-dom';

import { useAuth } from '../../context/AuthContext/auth';
import { useBackdrop } from '../../context/BackdropContext/backdrop';
import { useBusiness } from '../../context/BusinessContext/business';
import { NewFinancialProducts } from '../../context/BusinessContext/interfaces/IBusinessContext';
import { EModalError } from '../../context/ModalContext/enums/EModalError';
import { useModalExpired } from '../../context/ModalContext/modalExpired';
import { useTpa } from '../../context/TPAContext/tpa';
import { useQuery } from '../../hooks/useQuery';
import { businessInformation } from '../../services/sabbi/businessPartners/business';
import { EBusinessPartnerState } from '../../services/sabbi/businessPartners/enum/EBusinessPartnerState';
import {
    EPaymentMethodsState,
    IBusinessPartnerInformationPaymentMethod
} from '../../services/sabbi/businessPartners/interfaces/IBusinessPartnerInformationPaymentMethod';
import { userSubscribedCardsQuantity } from '../../services/sabbi/card/card';
import { getDataDevice } from '../../utils/device/dataDeviceHelpers';
import { IDevice } from '../../utils/device/interfaces/IDevice';
import { EPaymentMethods } from '../../utils/enums/EPaymentMethods';
import {
    getLocalStorageItem,
    getTEInformation,
    getTPAInformation,
    setLocalStorageItem
} from '../../utils/localStorageManipulation';
import { checkBusinessPartnerId } from '../../utils/tokenManipulation';
import { ECardRoutePaths } from '../enum/ECardRoutePaths';
import { ELoginRoutePaths } from '../enum/ELoginRoutePaths';
import { ENotAllowedRoutePaths } from '../enum/ENotAllowedRoutePaths';

export const EntryPoint: FC<RouteProps> = ({ ...rest }) => {
    const [queryParams, setQueryParams] = useState<{
        TPA: string | null;
    }>();
    const [queryParamsExist, setQueryParamsExist] = useState<boolean>();
    const [tpaIsValid, setTpaIsValid] = useState<boolean>();
    const [financialProducts, setFinacialProducts] = useState<NewFinancialProducts>();
    const [teInfo, setTeInfo] = useState<{ TE: string | null; exist: boolean }>();
    const [userCardsInfo, setUserCardsInfo] = useState<{ hasCards: boolean; quantity: number }>();
    const [redirectToInfo, setRedirectToInfo] = useState<string>();

    const location = useLocation();
    const query = useQuery(location.search);
    const backdrop = useBackdrop();
    const tpaContext = useTpa();
    const teContext = useAuth();
    const fpContext = useBusiness();
    const expiredContext = useModalExpired();
    const history = useHistory();

    //Get query params
    useEffect(() => {
        backdrop.openBackdrop();
        getQueryParams();
        return () => {
            backdrop.closeBackdrop();
        };
    }, []);
    /**
     * Gets TPA from query params
     * @return {Promise<void>}
     */
    const getQueryParams = async (): Promise<void> => {
        try {
            const TPA = query.get('TPA');
            setQueryParams({ TPA });
        } catch (error) {
            setRedirectToInfo(ENotAllowedRoutePaths.NOT_AUTHORIZED);
        }
    };

    //If TPA is found on query params validate and setState
    useEffect(() => {
        if (queryParams) {
            validateQueryParams();
        }
    }, [queryParams]);
    /**
     * Validates if TPA exist on query params and setState
     * @return {Promise<void>}
     */
    const validateQueryParams = async (): Promise<void> => {
        if (queryParams) {
            const { TPA } = queryParams;
            if (TPA) {
                setQueryParamsExist(true);
            } else {
                setRedirectToInfo(ENotAllowedRoutePaths.NOT_AUTHORIZED);
            }
        } else {
            setRedirectToInfo(ENotAllowedRoutePaths.NOT_AUTHORIZED);
        }
    };

    // If query param TPA exists get TPA information
    useEffect(() => {
        if (queryParamsExist) {
            getTpaInfo();
        }
    }, [queryParamsExist]);
    /**
     * Get TPA information and update TPA Context
     * @return {Promise<void>}
     */
    const getTpaInfo = async (): Promise<void> => {
        try {
            const { TPA } = queryParams!;
            await getTPAInformation(TPA);
            setTpaIsValid(true);
            updateTpaContext();
        } catch (error) {
            setRedirectToInfo(ENotAllowedRoutePaths.NOT_AUTHORIZED);
        }
    };
    /**
     * Set TPA localStorage and set TPA Context
     * @return {Promise<void>}
     */
    const updateTpaContext = () => {
        if (queryParams?.TPA) {
            const tokenInfo = {
                tpa: queryParams?.TPA
            };
            setLocalStorageItem('TPA', queryParams?.TPA);
            tpaContext.updateTokenInfo(tokenInfo);
        } else {
            setRedirectToInfo(ENotAllowedRoutePaths.NOT_AUTHORIZED);
        }
    };

    //If TPA is valid get business information
    useEffect(() => {
        if (tpaIsValid) {
            getBusinessInfo();
        }
    }, [tpaIsValid]);
    /**
     * Get business information and validate finantial products
     * @return {Promise<void>}
     */
    const getBusinessInfo = async (): Promise<void> => {
        try {
            const { TPA } = queryParams!;
            const device: IDevice = getDataDevice();
            const { paymentMethods, state, publicMerchantId } = await businessInformation({
                TPA: TPA as string,
                device
            });
            if (state === EBusinessPartnerState.ENABLED && paymentMethods.length > 0) {
                setLocalStorageItem('publicMerchantId', publicMerchantId);
                validateFinancialProductsNew(paymentMethods);
            } else {
                setRedirectToInfo(ENotAllowedRoutePaths.NOT_AUTHORIZED);
            }
        } catch (error) {
            setRedirectToInfo(ENotAllowedRoutePaths.NOT_AUTHORIZED);
        }
    };

    /**
     * New business partners products validations
     * @return {void}
     */
    const validateFinancialProductsNew = (
        contractedProducts: IBusinessPartnerInformationPaymentMethod[]
    ): void => {
        const enabledFinancialProducts: NewFinancialProducts = {
            bnpl: false,
            webpayplus: false,
            oneclick: false
        };
        contractedProducts.map((finantialProduct) => {
            switch (finantialProduct.name) {
                case EPaymentMethods.BNPL_QUOTA:
                    if (finantialProduct.state === EPaymentMethodsState.ENABLED) {
                        enabledFinancialProducts.bnpl = true;
                    }
                    break;
                case EPaymentMethods.ONECLICK:
                    if (finantialProduct.state === EPaymentMethodsState.ENABLED) {
                        enabledFinancialProducts.oneclick = true;
                    }
                    break;
                case EPaymentMethods.WEBPAY:
                    if (finantialProduct.state === EPaymentMethodsState.ENABLED) {
                        enabledFinancialProducts.webpayplus = true;
                    }
                    break;
                default:
                    break;
            }
        });
        setFinacialProducts(enabledFinancialProducts);
    };

    //If finantialProducts are valid update business information and validate user authentication
    useEffect(() => {
        if (financialProducts) {
            updateBusinessInfo();
            validateUserAuth();
        }
    }, [financialProducts]);
    /**
     * Update business information state
     * @return {void}
     */
    const updateBusinessInfo = (): void => {
        if (financialProducts) {
            setLocalStorageItem('FP', financialProducts);
            fpContext.updateFinancialProducts(financialProducts);
        } else {
            setRedirectToInfo(ENotAllowedRoutePaths.NOT_AUTHORIZED);
        }
    };
    /**
     * Validate user authentication TE
     * Check match between TE businessPartnerId and TPA businessPartnerId
     * @return {Promise<void>}
     */
    const validateUserAuth = async (): Promise<void> => {
        try {
            const TE = getLocalStorageItem('TE');
            if (TE) {
                const TEInfo = await getTEInformation(teContext.user.te);
                if (TEInfo.isExpired) {
                    expiredContext.setExpiredTE(true);
                    expiredContext.updateModalExpired(EModalError.TEEXPIRED);
                    setTeInfo({ TE: null, exist: false });
                } else {
                    const validBusiness = await checkBusinessPartnerId(
                        tpaContext.tokenInfo.tpa,
                        TE
                    );
                    if (!validBusiness) {
                        expiredContext.setExpiredTE(true);
                        expiredContext.updateModalExpired(EModalError.ERRORCOMERCIO);
                        setTeInfo({ TE: null, exist: false });
                    } else {
                        setTeInfo({ TE, exist: true });
                    }
                }
            } else {
                setTeInfo({ TE: null, exist: false });
            }
        } catch (error) {
            teContext.cleanTE();
            setTeInfo({ TE: null, exist: false });
        }
    };

    //If TE was validated get user cards and handle user redirection
    useEffect(() => {
        if (teInfo) {
            const { exist } = teInfo;
            if (exist) {
                getUserCards();
            } else {
                checkLoggedUserRedirection();
            }
        }
    }, [teInfo]);
    /**
     * Get user cards
     * @return {Promise<void>}
     */
    const getUserCards = async (): Promise<void> => {
        let userCardsLength = 0;
        try {
            const userCards = await userSubscribedCardsQuantity({
                TE: teInfo?.TE as string,
                TPA: queryParams?.TPA as string
            });
            userCardsLength = userCards.cardsQuantity;
        } catch (error) {
            console.error('@@@@@ SOMETHING WRONG GETTING USER CARDS, DEFAULT 0 CARDS');
        } finally {
            setUserCardsInfo({ hasCards: true, quantity: userCardsLength });
        }
    };
    /**
     * Set redirection url on valid TE
     * @return {void}
     */
    const checkLoggedUserRedirection = (): void => {
        if (teInfo?.TE && teInfo.exist) {
            if (userCardsInfo && userCardsInfo.quantity === 0) {
                setRedirectToInfo(ECardRoutePaths.CARD_SELECT_TYPE);
            } else {
                setRedirectToInfo(ECardRoutePaths.CARD_SELECT);
            }
        } else {
            setRedirectToInfo(ELoginRoutePaths.LOGIN);
        }
    };

    //Updates TE Context after checking user cards
    useEffect(() => {
        if (userCardsInfo) {
            updateTeContext();
        }
    }, [userCardsInfo]);
    /**
     * Updates TE Context if valid TE or set redirection URL
     * @return {void}
     */
    const updateTeContext = (): void => {
        if (teInfo?.TE && teInfo.exist) {
            teContext.updateTE(teInfo?.TE);
        } else {
            checkLoggedUserRedirection();
        }
    };

    //If TE Context was updated set redirection URL
    useEffect(() => {
        if (teContext.user.te && teInfo?.TE && teInfo.exist) {
            checkLoggedUserRedirection();
        }
    }, [teContext]);

    //Any time redirection URL is set, handle user redirection
    useEffect(() => {
        if (redirectToInfo) {
            finalRedirection();
        }
    }, [redirectToInfo]);
    /**
     * Redirects user to redirectionUrl
     * @return {void}
     */
    const finalRedirection = (): void => {
        if (redirectToInfo) {
            history.replace(redirectToInfo);
        } else {
            history.replace(ENotAllowedRoutePaths.NOT_AUTHORIZED);
        }
    };

    return <></>;
};
