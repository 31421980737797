import { makeStyles } from '@material-ui/core/styles';
import COLORS from '../../themes/colors';
const useStyles = makeStyles((theme) => ({
    PaymentDetailPage: {
        maxWidth: '100%',
        '& h2': {
            textAlign: 'center',
            marginBottom: '45px'
        }
    },
    SabbiLogo: {
        width: '60px',
        height: '60px',
        display: 'flex',
        alignSelf: 'center',
        margin: '0 auto 1.9rem auto'
    },
    AlertBar: {
        '&.opened': {
            marginBottom: '1rem'
        }
    },
    SelectedCard: {
        marginBottom: '29px'
    },
    HyperLinkBox: {
        maxWidth: '100%',
        marginBottom: '10px',
        ['@media (max-width:959px)']: {
            width: '650px',
            margin: 'auto',
            marginBottom: '10px'
        }
    },
    HyperLink_GoBack: {
        ['@media (max-width:959px)']: {
            marginLeft: '-9px'
        },
        '& a': {
            fontFamily: 'robotoMedium',
            display: 'flex',
            alignItems: 'center'
        }
    },
    MainGrid: {
        ['@media (max-width:959px)']: {
            flexFlow: 'column-reverse'
        }
    },
    ContentBox: {
        display: 'flex',
        flexDirection: 'column',
        ['@media (max-width:959px)']: {
            margin: 'auto'
        }
    },
    SubmitButton: {
        maxWidth: '225px',
        alignSelf: 'center',
        ['@media (max-width:450px)']: {
            width: '100%',
            maxWidth: '100%',
            margin: '1rem 0'
        }
    },
    ButtonsBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        ['@media (max-width:450px)']: {
            flexDirection: 'column-reverse'
        }
    },
    GuestButton: {
        color: theme.palette.grey[800],
        width: '185px',
        maxWidth: '225px',
        minWidth: '185px',
        textTransform: 'none',
        margin: '1rem',
        ['@media (max-width:450px)']: {
            maxWidth: '100%',
            width: '100%',
            margin: '1rem 0'
        }
    },
    ModalViewDocument_Text: {
        textAlign: 'center'
    },
    BNPLRadioButton: {
        cursor: 'default',
        marginBottom: '1.4rem',
        border: '0.7px solid #8EF98C',
        background: 'rgba(142, 249, 140, 0.20)',
        padding: '15px',
        '& .MuiTypography-caption': {
            color: theme.palette.grey[500],
            fontFamily: 'robotoBold',
            fontSize: 12
        }
    },
    Checkbox: {
        alignItems: 'center',
        width: '100%',
        justifyContent: 'flex-start',
        margin: '2rem 0',
        fontSize: '.8rem',
        flexWrap: 'wrap',
        '& label': {
            marginRight: 0,
            marginLeft: '.5rem'
        },
        '& label svg': {
            color: theme.palette.secondary.dark
        },
        '& div': {
            display: 'block'
        },
        '& a': {
            color: theme.palette.grey[800],
            textDecoration: 'none',
            width: '60%'
        },
        '& .MuiFormHelperText-root': {
            marginLeft: '3.1rem',
            fontSize: '12px'
        }
    },
    Checkbox_Hyperlink: {
        flex: 1
    },
    CardMesaageBNPL: {
        width: '100%',
        minHeight: '5rem',
        borderRadius: '10px',
        border: '0.5px solid #A266CB',
        backgroundColor: theme.palette.warning.light
    },
    CardContentBNPL: {
        alignItems: 'center',
        justifyContent: 'center',
        padding: '3rem 5rem',
        textAlign: 'center',
        paddingButtom: 'inherit'
    },
    ModalViewDocument_Title: {
        display: 'flex',
        alignItems: 'end',
        '@media (max-width:500px)': {
            alignItems: 'center',
            justifyContent: 'center'
        }
    },
    ModalViewDocument_Logo: {
        width: '60px',
        marginRight: '20px',
        '@media (max-width: 500px)': {
            marginRight: '10px'
        }
    },
    ModalViewDocument_PDF: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        '@media (max-width:540px)': {
            flexWrap: 'wrap'
        },
        '& embed': {
            width: '100%',
            height: '60vh'
        }
    },
    ModalViewDocument_Loader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    ModalViewDocument_Checkbox: {
        alignItems: 'center',
        width: '100%',
        justifyContent: 'flex-start',
        marginTop: '1rem',
        fontSize: '.8rem',
        '& label': {
            marginRight: 0,
            marginLeft: '.5rem'
        },
        '& label svg': {
            color: theme.palette.secondary.dark
        },
        '& a': {
            color: theme.palette.grey[800],
            textDecoration: 'none',
            width: '60%'
        }
    },
    ModalViewDocument_NextDocumentButton: {
        margin: 0,
        maxWidth: '100%',
        alignSelf: 'center',
        width: '300px',
        backgroundColor: theme.palette.common.white,
        '& img': {
            paddingLeft: '5px'
        }
    }
}));

export default useStyles;
