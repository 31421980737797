import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    Title: {
        fontSize: '1.4rem',
        fontFamily: 'robotoBold',
        ['@media (max-width:768px)']: {
            textAlign: 'center'
        },
    },
    Icon: {
        marginLeft: '5px'
    },
    SuccessIcon: {
        color: theme.palette.success.main
    },
    ErrorIcon: {
        color: theme.palette.error.light
    },
    RoundButton: {
        display: 'flex',
        textTransform: 'none'
    },
    CancelButton: {
        display: 'flex',
        textTransform: 'none',
        margin: '0 1rem',
        color: theme.palette.grey[800],
        ['@media (max-width:621px)']: {
            marginBottom: 0
        },
        '&:hover': {
            color: theme.palette.grey[800],
            backgroundColor: theme.palette.common.white
        }
    },
    ButtonsBox: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '100%',
      flexWrap: 'wrap',
      justifyContent: 'center',
      marginTop: '2rem',
      ['@media (max-width: 653px)']: {
        flexDirection: 'column-reverse'
      },
    },
    DisclaimerBox: {
        margin: '1rem auto',
        maxWidth: '80%',
        '& p': {
            fontSize: '.9rem',
            marginBottom: '.9rem'
        }
    },
    sabbiImg: {
        height: '126px',
        marginBottom: '20px'
    }
}));

export default useStyles;
