import { Button, Dialog, DialogContent, Typography } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types';
import { useHistory } from 'react-router-dom';

import ModalErrorIconSrc from '../../assets/icons/modal-error-icon.svg';
import ModalWarningIconSrc from '../../assets/icons/modal-warning-icon.svg';
import { useAuth } from '../../context/AuthContext/auth';
import { useBackdrop } from '../../context/BackdropContext/backdrop';
import { useModalExpired } from '../../context/ModalContext/modalExpired';
import { useTpa } from '../../context/TPAContext/tpa';
import { ELoginRoutePaths } from '../../routes/enum/ELoginRoutePaths';
import { EPasswordRoutePaths } from '../../routes/enum/EPasswordRoutePaths';
import { generateEncryptedLogin } from '../../utils/cryptography/cypher';
import { IGenerateEncryptedLogin } from '../../utils/cryptography/cypher/interfaces/IGenerateEncryptedLogin';
import { getDataDevice } from '../../utils/device/dataDeviceHelpers';
import { serviceErrorMapper } from '../../utils/errors';
import { SERVICE_ERRORS } from '../../utils/errors/enums/EServiceErrors';
import { getTEInformation } from '../../utils/localStorageManipulation';
import { FormContext } from '../FormsContext/FormContext/FormContext';
import PasswordInput from '../FormsContext/PasswordInput/PasswordInput';
import RoundButton from '../FormsContext/RoundButton/RoundButton';
import IModalExpiredProps from './interfaces/IModalExpiredProps';
import useStyles from './ModalExpiredStyles.material';

type Inputs = {
    password: string;
};

const ModalExpired: FC<IModalExpiredProps> = ({
    classNames = '',
    open,
    onClose,
    title,
    description,
    icon
}) => {
    const [userName, setUserName] = useState<string>('');
    const [passwordAttempts, setPasswordAttempts] = useState<number>(3);
    const [passwordError, setPasswordError] = useState<string>('Contraseña numérica de 6 dígitos');
    const [blockedUser, setBlockedUser] = useState<boolean>(false);
    const classes = useStyles();
    const backdrop = useBackdrop();
    const tpaContext = useTpa();
    const authContext = useAuth();
    const modalContext = useModalExpired();
    const history = useHistory();
    const defaultValues: Partial<Inputs> = {};
    const methods = useForm<FieldValues>({ mode: 'all', defaultValues });

    useEffect(() => {
        getUserInformation();
    }, []);

    useEffect(() => {
        setPasswordError(
            passwordAttempts === 3
                ? 'Contraseña numérica de 6 dígitos'
                : `La contraseña no coincide, te quedan ${passwordAttempts} intento${
                      passwordAttempts > 1 ? 's' : ''
                  }`
        );
    }, [passwordAttempts]);

    /**
     * Set user name on userName State
     * @return {Promise<void>}
     */
    const getUserInformation = async () => {
        try {
            const TEInfo = await getTEInformation(authContext.user.te);
            const simpleName = TEInfo.bodyTE.user.names
                .substring(0, TEInfo.bodyTE.user.names.indexOf(' '))
                .toLowerCase();
            setUserName(simpleName);
        } catch (error) {
            history.replace(ELoginRoutePaths.LOGIN);
        }
    };

    /**
     * Submit form information
     * @param {Inputs} data Inputs information
     * @return {Promise<void>}
     */
    const onSubmit: any = async (data: Inputs): Promise<void> => {
        try {
            backdrop.openBackdrop();
            const TEInfo = await getTEInformation(authContext.user.te);
            const rut = TEInfo.bodyTE.user.rut;
            const device = getDataDevice();
            const cypherLoginData: IGenerateEncryptedLogin = {
                TPA: tpaContext.tokenInfo.tpa as string,
                password: data.password,
                rut
            };
            const encriptedLogin = await generateEncryptedLogin(cypherLoginData);
            const requestLogin = {
                loginPayload: encriptedLogin.payload,
                device,
                TPA: tpaContext.tokenInfo.tpa as string
            };
            await authContext.login(requestLogin);
            modalContext.setExpiredTE(false);
            onClose();
        } catch (error) {
            const serviceError = serviceErrorMapper(error);
            if (serviceError.code === SERVICE_ERRORS.SABBI_39.code) {
                setBlockedUser(true);
            } else {
                setPasswordAttempts(serviceError.payload?.retryAttempts as number);
                if (serviceError.payload?.retryAttempts === 0) {
                    setBlockedUser(true);
                }
            }
        } finally {
            backdrop.closeBackdrop();
        }
    };

    /**
     * Logout user and redirect to specified route
     * @param {string} route route which the user will be redirected
     * @return {Promise<void>}
     */
    const handleForgotPassword = async (route: string): Promise<void> => {
        try {
            backdrop.openBackdrop();
            const resp = await authContext.logout(tpaContext.tokenInfo.tpa as string);
            if (resp.logOut === true) {
                history.push(route);
            }
        } catch (error) {
            if (route === EPasswordRoutePaths.PASSWORD_RECOVER) {
                history.push(EPasswordRoutePaths.PASSWORD_RECOVER);
            } else {
                history.push(ELoginRoutePaths.LOGIN);
            }
        } finally {
            authContext.cleanTE();
            modalContext.closeModal();
            modalContext.setExpiredTE(false);
            backdrop.closeBackdrop();
        }
    };

    /**
     * Returns specified icon
     * @return {string}
     */
    const handleModalIcon = (): string => {
        switch (icon) {
            case 'error':
                return ModalErrorIconSrc;
            case 'info':
                return ModalWarningIconSrc;
            case 'warning':
                return ModalWarningIconSrc;
            default:
                return ModalWarningIconSrc;
        }
    };

    return (
        <Dialog
            open={open}
            fullWidth={false}
            maxWidth="sm"
            scroll="paper"
            onClose={(event, reason) => {
                if (!reason) {
                    onClose();
                }
            }}
            className={`${classNames} ${classes.ModalDialog}`}>
            <div className={classes.DialogIcon}>
                <img
                    src={!blockedUser ? handleModalIcon() : ModalErrorIconSrc}
                    alt="modal icon error"
                />
            </div>
            <DialogContent className={classes.DialogContent}>
                <Typography component="h3" className={classes.DialogTitle}>
                    {!blockedUser ? (
                        title
                    ) : (
                        <span>
                            Por seguridad, <br />
                            hemos bloqueado tu cuenta
                        </span>
                    )}
                </Typography>
                <Typography component="p" className={classes.Description}>
                    {!blockedUser ? (
                        description
                    ) : (
                        <span className={classes.BlockedUserDescription}>
                            Has superado el máximo de intentos fallidos.
                            <br />
                            <b>Recupera tu cuenta validando tu identidad.</b>
                        </span>
                    )}
                </Typography>
                {!blockedUser && (
                    <Button
                        className={classes.UserBtn}
                        disableRipple
                        onClick={() => handleForgotPassword(ELoginRoutePaths.LOGIN)}>
                        ¿No eres <span className={classes.UserName}>&nbsp;{`${userName}`}</span>?
                    </Button>
                )}
                {!blockedUser ? (
                    <FormContext
                        className={classes.Form}
                        id="ExpiredLoginForm"
                        onSubmit={onSubmit}
                        methods={methods}>
                        <PasswordInput
                            id={'password'}
                            name={'password'}
                            label="Contraseña"
                            defaultHelperText={passwordError}
                            forcedError={passwordAttempts === 3 ? false : true}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'La contraseña es requerida'
                                }
                            }}
                            icon={<LockIcon />}
                        />
                        <div className={classes.DialogActions}>
                            <Button
                                className={classes.CancelButton}
                                type="button"
                                onClick={() =>
                                    handleForgotPassword(EPasswordRoutePaths.PASSWORD_RECOVER)
                                }>
                                Olvidé mi contraseña
                            </Button>
                            <RoundButton
                                classNames={classes.RoundButton}
                                color="primary"
                                type="submit">
                                Continuar
                            </RoundButton>
                        </div>
                    </FormContext>
                ) : (
                    <div className={classes.DialogActions}>
                        <Button
                            className={classes.CancelButton}
                            type="button"
                            onClick={() => handleForgotPassword(ELoginRoutePaths.LOGIN)}>
                            Salir
                        </Button>
                        <RoundButton
                            classNames={classes.RoundButton}
                            color="primary"
                            type="button"
                            onClick={() =>
                                handleForgotPassword(EPasswordRoutePaths.PASSWORD_RECOVER)
                            }>
                            Recuperar cuenta
                        </RoundButton>
                    </div>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default ModalExpired;
