import { CardContent, Typography, useTheme } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import DescriptionIcon from '@material-ui/icons/Description';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { FC } from 'react';

import { ESabbiTemplates } from '../../services/sabbi/document/enum/ESabbiTemplates';
import useStyles from './DocumentsListStyle.material';
import { IDocumentsList } from './interfaces/IDocumentsList';

const DocumentsList: FC<IDocumentsList> = ({ docsArray, readOnClick }) => {
    const classes = useStyles();
    const theme = useTheme();

    const handleFieldClick = (documentId: ESabbiTemplates) => {
        if (readOnClick) {
            readOnClick(documentId);
        }
    };

    return (
        <div className={classes.CardMessageBNPL}>
            <CardContent className={classes.CardContentBNPL}>
                <div className={classes.CardBNPLTitle}>
                    <DescriptionIcon className={classes.CardBNPLTitle_Icon} />
                    <Typography className={classes.CardBNPLTitle_Text} component="h4">
                        Contratos digitales
                    </Typography>
                </div>
                <Typography className={classes.CardBNPLText} component="p" gutterBottom>
                    Para continuar con la aprobación de tu crédito y finalizar la apertura de tu{' '}
                    <span style={{ color: theme.palette.primary.main }}>
                        Tarjeta de Crédito Digital sabbi
                    </span>
                    , a continuación te presentamos los contratos digitales para tu aprobación para
                    que una vez leídos los puedas firmar:
                </Typography>
                <div className={classes.CardBNPLTable}>
                    {docsArray.length === 1 ? (
                        <div className={classes.CardBNPLTable_Doc_Single}>
                            <div>
                                <Typography
                                    className={classes.CardBNPLTable_Doc_Info_Name}
                                    component="p">
                                    Acuerdo de otorgamiento
                                </Typography>
                                <Typography
                                    className={classes.CardBNPLTable_Doc_Info_Read}
                                    component="p">
                                    Leer documento anexo
                                </Typography>
                            </div>
                            <div className={classes.CardBNPLTable_Doc_Icon}>
                                <VisibilityIcon className={classes.CardBNPLTable_Doc_Icon_Eye} />
                            </div>
                        </div>
                    ) : (
                        docsArray.map((doc) => {
                            return (
                                <div key={doc.documentId} className={classes.CardBNPLTable_Doc}>
                                    <div>
                                        <Typography
                                            className={classes.CardBNPLTable_Doc_Info_Name}
                                            component="p">
                                            {doc.documentTitle}
                                        </Typography>
                                        <Typography
                                            onClick={() => handleFieldClick(doc.documentId)}
                                            className={classes.CardBNPLTable_Doc_Info_Read}
                                            component="p">
                                            Leer documento anexo
                                        </Typography>
                                    </div>
                                    <div className={classes.CardBNPLTable_Doc_Icon}>
                                        {doc.read ? (
                                            <CheckIcon
                                                className={classes.CardBNPLTable_Doc_Check}
                                            />
                                        ) : (
                                            <VisibilityIcon
                                                className={classes.CardBNPLTable_Doc_Icon_Eye}
                                            />
                                        )}
                                    </div>
                                </div>
                            );
                        })
                    )}
                </div>
            </CardContent>
        </div>
    );
};

export default DocumentsList;
