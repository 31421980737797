import { HttpStatus } from '../../enums/HttpStatus';
import { IApplicationError } from '../ApplicationError';

export const SERVICE_ERRORS: Record<string, IApplicationError> = {
    SABBI_16: {
        code: 'SABBI_16',
        status: HttpStatus.UNAUTHORIZED,
        message: 'Transacción inválida.'
    },
    SABBI_17: {
        code: 'SABBI_17',
        status: HttpStatus.UNAUTHORIZED,
        message: 'Transacción expirada.'
    },
    SABBI_18: {
        code: 'SABBI_18',
        status: HttpStatus.UNAUTHORIZED,
        message: 'Sesión inválida.'
    },
    SABBI_19: {
        code: 'SABBI_19',
        status: HttpStatus.UNAUTHORIZED,
        message: 'Sesión expirada.'
    },
    SABBI_20: {
        code: 'SABBI_20',
        status: HttpStatus.FORBIDDEN,
        message: 'La tarjeta ya se encuentra agregada.'
    },
    SABBI_21: {
        code: 'SABBI_21',
        status: HttpStatus.FORBIDDEN,
        message: 'Error al suscribir la tarjeta, intente más tarde.'
    },
    SABBI_39: {
        code: 'SABBI_39',
        status: HttpStatus.UNAUTHORIZED,
        message: 'El Rut se encuentra asociado a un usuario bloqueado.'
    },
    SABBI_43: {
        code: 'SABBI_43',
        status: HttpStatus.FORBIDDEN,
        message: 'No es posible ingresar esta tarjeta.'
    },
    SABBI_49: {
        code: 'SABBI_49',
        status: HttpStatus.FORBIDDEN,
        message: 'No es posible ingresar este tipo de tarjeta.'
    },
    SABBI_58: {
        code: 'SABBI_58',
        status: HttpStatus.BAD_REQUEST,
        message: 'No es posible validar tu tarjeta. Por favor intentalo de nuevo.'
    },
    SABBI_59: {
        code: 'SABBI_59',
        status: HttpStatus.FORBIDDEN,
        message: 'No es posible agregar tu tarjeta, excede el límite máximo permitido.'
    }
};
