import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    RegisterPage: {
        maxWidth: '100%',
        '& h2': {
            maxWidth: '100%',
            textAlign: 'center',
            marginBottom: '2rem',
            width: '300px',
            margin: '12px auto 2rem auto'
        }
    },
    HyperLinkBox: {
      maxWidth: '100%',
      marginBottom: '10px',
      ['@media (max-width:959px)']: {
          width: '600px',
          margin: 'auto',
          marginBottom: '10px'
      }
    },
    HyperLink_GoBack: {
      ['@media (max-width:959px)']: {
          marginLeft: '-9px'
      },
      '& a': {
          display: 'flex',
          alignItems: 'center'
      }
    },  
    ContentBox: {
        display: 'flex',
        flexDirection: 'column'
    },
    Form: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        '& .MuiFormControl-root': {
            maxWidth: '100%',
            marginBottom: '30px',
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        '& .MuiFormControl-root:last-of-type': {
            marginBottom: '20px'
        }
    },
    ButtonsBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      ['@media (max-width:450px)']: {
        flexDirection: 'column-reverse'
      },
    },
    SubmitButton: {
        maxWidth: '225px',
        alignSelf: 'center',
        ['@media (max-width:450px)']: {
          width: '100%',
          maxWidth: '100%',
          margin: '1rem 0',
        }
    },
    CancelButton: {
        color: theme.palette.grey[800],
        width: '185px',
        maxWidth: '225px',
        minWidth: '185px',
        textTransform: 'none',
        margin: '1rem',
        ['@media (max-width:450px)']: {
          maxWidth: '100%',
          width: '100%',
          margin: '1rem 0',
        }
    },
    CardImage: {
        maxWidth: '100%',
        width: '300px'
    },
    LogoImage: {
        maxWidth: '100%',
        width: '60px',
        alignSelf: 'center'
    },
    CdiFront: {
        width: '121px',
        height: '71px',
        display: 'flex',
        margin: '10px auto '
    },
    CdiBack: {
        width: '121px',
        height: '71px',
        display: 'flex',
        margin: '10px auto '
    },
    TooltipCustomized: {
        fontSize: '0.65rem',
        display: 'flex',
        flexFlow: 'row wrap',
        padding: 'auto'
    },
    TooltipCustomized_p: {
        margin: '0',
        color: theme.palette.secondary.light
    },
    TooltipCustomized_pTitle: {
        margin: '0',
        color: theme.palette.common.white
    },
    SpanTooltip: {
        display: 'flex',
        justifyContent: 'flex-end',
        cursor: 'pointer',
    },
    CheckboxInput: {
        alignItems: 'center',
        flexDirection: 'row-reverse',
        maxWidth: '100%',
        justifyContent: 'flex-start',
        marginLeft: 'auto',
        marginRight: '5px',
        '& a': {
            fontSize: '.8rem',
            marginRight: '10px',
            color: theme.palette.grey[800],
            ['@media (max-width:450px)']: {
                fontSize: '12px'
            }
        },
        '& .MuiFormControlLabel-root': {
            marginRight: '5px'
        },
        '& .MuiFormHelperText-root': {
            marginRight: '15px'
        }
    },
    CheckboxHyperLink: {
        color: 'blue',
        ['@media (max-width:420px)']: {
            maxWidth: '180px',
            textAlign: 'right'
        }
    },
    AlertBar: {
        '&.opened': {
            marginBottom: '1rem'
        }
    },
    DisclaimerText: {
        fontFamily: 'robotoBold',
        fontSize: '.85rem',
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: '2rem'
    },
    ModalViewDocument_Loader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    ModalViewDocument_PDF: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        '@media (max-width:540px)': {
            flexWrap: 'wrap',
        },
        '& embed': {
            width: '100%',
            height: '55vh',
            '@media (max-width:500px)': {
                height: '50vh',
            },
        }
    },
}));

export default useStyles;
