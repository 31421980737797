import sabbiHttpInstance from '../../instances/sabbi.instance';
import { IPurchaseBnplResponse } from '../user/interfaces/IPurchaseBnplResponse';
import { IBnplSimulation } from './interfaces/IBnplSimulation';
import { IBnplSimulationResponse } from './interfaces/IBnplSimulationResponse';
import { IDataSign } from './interfaces/IDataSign';
import { IDataSignResponse } from './interfaces/IDataSignResponse';
import { IOneClickTransactionResponse } from './interfaces/IOneClickTransactionResponse';
import { IPurchaseBNPL } from './interfaces/IPurchaseBNPL';
import { ITransactionCharge } from './interfaces/ITransactionCharge';
import { IWebPayPlusTransaction } from './interfaces/IWebPayPlusTransaction';
import { IWebPayPlusTransactionResponse } from './interfaces/IWebPayPlusTransactionResponse';
import { IWebPayPlusTransactionTokenResponse } from './interfaces/IWebPayPlusTransactionTokenResponse';
import { IWebPayPlusTransactionUrl } from './interfaces/IWebPayPlusTransactionUrl';

export const oneClickTransaction = async (
    data: ITransactionCharge
): Promise<IOneClickTransactionResponse> => {
    const {
        data: { payload }
    } = await sabbiHttpInstance.post(`/transaction/card-transaction-charge`, data);
    return payload;
};

export const webPayPlusTransactionUrl = async (
    data: IWebPayPlusTransactionUrl
): Promise<IWebPayPlusTransactionTokenResponse> => {
    const {
        data: { payload }
    } = await sabbiHttpInstance.post(`/transaction/web-pay-plus-transaction`, data);
    return payload;
};

export const webPayPlusTransaction = async (
    data: IWebPayPlusTransaction
): Promise<IWebPayPlusTransactionResponse> => {
    const {
        data: { payload }
    } = await sabbiHttpInstance.post(`/transaction/save-wp-transaction`, data);
    return payload;
};

export const bnplSimulation = async (data: IBnplSimulation): Promise<IBnplSimulationResponse> => {
    const {
        data: { payload }
    } = await sabbiHttpInstance.post(`/transaction/simulation-bnpl`, data);
    return payload;
};

export const purchaseBNPLService = async (data: IPurchaseBNPL): Promise<IPurchaseBnplResponse> => {
    const {
        data: { payload }
    } = await sabbiHttpInstance.post(`/transaction/purchase-bnpl`, data);
    return payload;
};

export const dataSignService = async (data: IDataSign): Promise<IDataSignResponse> => {
    const {
        data: { payload }
    } = await sabbiHttpInstance.post(`/transaction/transaction-data-sign`, data);
    return payload;
};
