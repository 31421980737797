import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      maxWidth: '460px',
    },
    GoogleAddressInput: {
      marginBottom: '30px',
      '&.error': {
        transition: 'all .3s ease-in-out',
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: theme.palette.error.main
            },
            '&.Mui-focused .MuiSvgIcon-root': {
                color: theme.palette.error.main
            }
        },
        '& label': {
            color: theme.palette.error.main
        },
        '& .MuiFormHelperText-root': {
            color: theme.palette.error.main
        },
        '& .MuiSvgIcon-colorPrimary': {
            color: theme.palette.error.main
        },
        '& .MuiSvgIcon-root': {
            color: theme.palette.error.main
        }
      },
      '&.success': {
        transition: 'all .3s ease-in-out',
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                color: theme.palette.success.main
            },
            '&.Mui-focused .MuiSvgIcon-root': {
                color: theme.palette.success.main
            }
        },
        '& label.Mui-focused': {
            color: theme.palette.primary.main
        },
        '& .MuiFormHelperText-root.Mui-focused': {
            color: theme.palette.primary.main
        },
        '& .Mui-focused .MuiSvgIcon-colorPrimary': {
            color: theme.palette.primary.main
        },
        '& .MuiSvgIcon-root': {
            color: theme.palette.success.main
        }
      },
      '& .MuiOutlinedInput-root': {
          maxHeight: '40px',
          padding: '0',
          '&:hover fieldset': {
              borderColor: theme.palette.primary.main
          },
          '&.Mui-focused .MuiSvgIcon-root': {
              color: theme.palette.primary.main
          },
          '&.Mui-focused fieldset': {
              borderWidth: '1px'
          },
          '& fieldset': {
              borderColor: theme.palette.grey[500],
              borderRadius: '20px 20px',
              boxSizing: 'border-box'
          },
          '& .MuiInputAdornment-positionEnd': {
              marginLeft: '16px',
              marginRight: '20px',
              '& .MuiSvgIcon-root': {
                  maxWidth: '20px',
                  maxHeight: '20px'
              }
          }
      },
      '& .MuiFormControl-root':{
        marginBottom: '0 !important',
      },
      '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]':{
        paddingRight: '14px',
      },
      '& .MuiFormLabel-root':{
        transform: 'translate(14px, 12px) scale(1)',
      },
      '& .MuiFormLabel-root.Mui-focused, & .MuiFormLabel-root.MuiFormLabel-filled':{
        transform: 'translate(14px, -6px) scale(0.75)',
      },
      
      '& input': {
        paddingLeft: '20px !important',
        '&::placeholder': {
            fontSize: '1rem'
        },
        '&::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none'
        },
        '&::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none'
        },
        '&[type=number]': {
            '-moz-appearance': 'textfield'
        },
        '&:-webkit-autofill': {
            '-webkit-box-shadow': '0 0 0 30px white inset',
            color: theme.palette.grey[800],
            fontFamily: 'robotoRegular'
        },
        '&:-internal-autofill-selected': {
            color: theme.palette.grey[800]
        }
      }
    },
    ManualAddressButton: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        fontFamily: 'robotoMedium',
        lineHeight: '24px',
        color: theme.palette.primary.main,
    },
    HyperLink: {
        color: 'blue',
        textAlign: 'right',
        marginTop: '5px',
        display: 'flex',
        justifyContent: 'flex-end',
        marginRight: '1rem',
        '& p': {
            fontSize: '.9rem'
        }
    },
    HelperSection: {
        display: 'flex',
        justifyContent: 'space-between',
        '& .MuiFormHelperText-root':{
          marginTop: '4px',
          marginLeft: '14px',
        }
    },
    label: {
        fontSize: '1rem'
    }
}));

export default useStyles;
