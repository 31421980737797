import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { FC } from 'react';

import useStyles from './AccordionCollapseStyles.material';
import IAccordionCollapseProps from './interfaces/IAccordionCollapseProps';

const AccordionCollapse: FC<IAccordionCollapseProps> = ({
    classNames = '',
    children,
    expanded,
    accordionSummary,
    expandIcon,
    defaultExpanded = false
}) => {
    const classes = useStyles();
    return (
        <Accordion
            className={`${classNames} ${classes.AccordionCollapse}`}
            expanded={expanded}
            defaultExpanded={defaultExpanded}>
            <AccordionSummary className={classes.AccordionSummary} expandIcon={expandIcon}>
                {accordionSummary}
            </AccordionSummary>
            <AccordionDetails className={classes.AccordionDetails}>{children}</AccordionDetails>
        </Accordion>
    );
};

export default AccordionCollapse;
