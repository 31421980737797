import { Button, Grid, Typography } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DateRangeIcon from '@material-ui/icons/DateRange';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import { FC, useEffect, useState } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';

import CardBackSrc from '../../assets/cdi/cardBack.svg';
import SabbiLogo from '../../assets/logo/sabbi.svg';
import SabbiLogoNoBorderSrc from '../../assets/logo/sabbi-noborder.svg';
import AlertBar from '../../components/AlertBar/AlertBar';
import { IErrorValues } from '../../components/AlertBar/interfaces/IErrorValues';
import ContentBox from '../../components/ContentBox/ContentBox';
import CreditCardInput from '../../components/FormsContext/CreditCardInput/CreditCardInput';
import ExpDateInput from '../../components/FormsContext/ExpDateInput/ExpDateInput';
import { FormContext } from '../../components/FormsContext/FormContext/FormContext';
import RoundButton from '../../components/FormsContext/RoundButton/RoundButton';
import RutInput from '../../components/FormsContext/RutInput/RutInput';
import TextInput from '../../components/FormsContext/TextInput/TextInput';
import HtmlTooltip from '../../components/HtmlTooltip/HtmlTooltip';
import { ClickableTooltip } from '../../components/HtmlTooltip/HtmlTooltip';
import HyperLink from '../../components/HyperLink/HyperLink';
import ModalDialog from '../../components/ModalDialog/ModalDialog';
import { useAuth } from '../../context/AuthContext/auth';
import { useBackdrop } from '../../context/BackdropContext/backdrop';
import { EModalError } from '../../context/ModalContext/enums/EModalError';
import { useModalExpired } from '../../context/ModalContext/modalExpired';
import { useTpa } from '../../context/TPAContext/tpa';
import { useRedirection } from '../../hooks/useRedirection';
import { ECardRoutePaths } from '../../routes/enum/ECardRoutePaths';
import { ELoginRoutePaths } from '../../routes/enum/ELoginRoutePaths';
import { userSubscribedCardsQuantity } from '../../services/sabbi/card/card';
import { ECardType } from '../../shared/enums/ECardType';
import { Currencies } from '../../types/currencies';
import { cancelTRXandReturnTrade, invalidBinNumber } from '../../utils/clientHelpers';
import { cleanCardNumber, validateExpDate } from '../../utils/creditCardHelpers';
import { ECurrency } from '../../utils/enums/ECurrency';
import { serviceErrorMapper } from '../../utils/errors';
import { ApplicationError } from '../../utils/errors/ApplicationError';
import { SABBI_FRONT_ERRORS } from '../../utils/errors/enums/EAppErrors';
import { handleOTPRequestToken } from '../../utils/kushkiHelpers';
import { getTEInformation, getTPAInformation } from '../../utils/localStorageManipulation';
import useStyles from './CardSubscriptionPageStyles.material';
import { ICardSubscriptionPageLocationState } from './interfaces/ICardSubscriptionPageLocationState';
import IModalValuesState from './interfaces/IModalValuesState';
import { InputTypes } from './interfaces/InputTypes';

const CardSubscriptionPage: FC = () => {
    const [modalValues, setModalValues] = useState<IModalValuesState>({
        cancelModal: false
    });
    const [errorValues, setErrorValues] = useState<IErrorValues>({
        title: '',
        visible: false
    });
    const [isLoading, setIsloading] = useState<boolean>(true);
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation<ICardSubscriptionPageLocationState>();
    const backdrop = useBackdrop();
    const tpaContext = useTpa();
    const authContext = useAuth();
    const modalContext = useModalExpired();
    const defaultValues: Partial<InputTypes> = {};
    const methods = useForm<FieldValues>({ mode: 'all', defaultValues });
    const { handleErrorRedirection, shouldRedirect } = useRedirection();

    useEffect(() => {
        if (!validateLocationStateData(location.state)) {
            if (authContext.user.te) {
                history.replace(ECardRoutePaths.CARD_SELECT);
            } else {
                history.replace(ELoginRoutePaths.LOGIN);
            }
        } else {
            modalContext.checkTeExpiration();
        }
    }, []);

    useEffect(() => {
        if (!modalContext.isExpired) {
            setIsloading(false);
        }
    }, [modalContext.isExpired]);

    const validateLocationStateData = (obj: ICardSubscriptionPageLocationState) => {
        if (obj) {
            return 'cardType' in obj;
        }
        return false;
    };

    const getRedirectUrlTrade = async () => {
        backdrop.openBackdrop();
        try {
            await cancelTRXandReturnTrade(tpaContext.tokenInfo.tpa as string);
        } catch (error) {
            const handledError = serviceErrorMapper(error);
            if (shouldRedirect(handledError)) {
                handleErrorRedirection(handledError);
            } else {
                showError(handledError.message);
            }
        } finally {
            backdrop.closeBackdrop();
        }
    };

    const onSubmit: SubmitHandler<any> = async (data) => {
        try {
            const TEInfo = await getTEInformation(authContext.user?.te as string);
            if (TEInfo.isExpired) {
                modalContext.updateModalExpired(EModalError.DEFAULT);
                modalContext.setExpiredTE(true);
                modalContext.openModal();
            } else {
                backdrop.openBackdrop();
                const creditCardNumber = cleanCardNumber(data.creditCard);
                if (invalidBinNumber(creditCardNumber)) {
                    throw new ApplicationError(SABBI_FRONT_ERRORS.SABBI_F_12);
                }
                const currencyType: Currencies = ECurrency.CHILE;
                const cardExpDate = data.expDate?.split('/');
                let month = '',
                    year = '';
                if (cardExpDate) {
                    month = cardExpDate[0];
                    year = cardExpDate[1];
                }
                const tpaInfo = await getTPAInformation(tpaContext.tokenInfo.tpa);
                const amountTotal = tpaInfo?.bodyTPA.transactionDetails?.orderDetails.amount
                    .total as number;
                const cardData = {
                    card: {
                        cvc: data.cvv,
                        expiryMonth: month,
                        expiryYear: year,
                        name: data.name,
                        rut: data.documentNumber,
                        number: creditCardNumber
                    },
                    amount: amountTotal,
                    currency: currencyType
                };
                const otpRequestToken = await handleOTPRequestToken(cardData);
                history.replace({
                    pathname: ECardRoutePaths.CARD_VALIDATE,
                    state: {
                        cardData: cardData,
                        cardType: ECardType.CREDIT,
                        otpRequestToken
                    }
                });
            }
        } catch (error) {
            const handledError = serviceErrorMapper(error);
            if (shouldRedirect(handledError)) {
                handleErrorRedirection(handledError);
            } else {
                showError(handledError.message);
            }
        } finally {
            backdrop.closeBackdrop();
        }
    };

    const handleAlertTimeout = () => {
        setTimeout(() => {
            clearError();
        }, 6000);
    };

    const showError = (title: string) => {
        setErrorValues({
            title: title,
            visible: true
        });
        handleAlertTimeout();
    };

    const clearError = () => {
        setErrorValues({
            title: '',
            visible: false
        });
    };

    const handleCancelSubscribe = async () => {
        const response = await userSubscribedCardsQuantity({
            TE: authContext.user.te as string,
            TPA: tpaContext.tokenInfo.tpa as string
        });
        const { cardsQuantity } = response;
        if (cardsQuantity && cardsQuantity > 0) {
            history.replace(ECardRoutePaths.CARD_SELECT);
        } else {
            history.replace(ECardRoutePaths.CARD_SELECT_TYPE);
        }
    };
    const showModal = (modalName: keyof IModalValuesState) => {
        setModalValues({
            ...modalValues,
            [modalName]: true
        });
    };
    const closeModals = () => {
        setModalValues({
            cancelModal: false
        });
    };

    /**
     * Render clickable tooltip depending on screen size
     * @returns {JSX.Element}
     */
    const renderTooltip = (): JSX.Element => {
        const CONST_SCREEN_WIDTH = 769;
        const width =
            window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

        const tooltipContent = (
            <div>
                <p className={classes.Tooltip_Text}>
                    <b>El código de seguridad</b> es de 3 dígitos y se encuentra en la parte
                    posterior de tu tarjeta de crédito
                </p>
                <img className={classes.Tooltip_CardImage} src={CardBackSrc} alt="CardBack" />
            </div>
        );
        const tooltipChildren = <span>¿Dónde lo encuentro?</span>;

        if (width < CONST_SCREEN_WIDTH) {
            return (
                <ClickableTooltip placement={'top-end'} size="md" tooltipContent={tooltipContent}>
                    {tooltipChildren}
                </ClickableTooltip>
            );
        } else {
            return (
                <HtmlTooltip placement={'top-end'} size="md" tooltipContent={tooltipContent}>
                    {tooltipChildren}
                </HtmlTooltip>
            );
        }
    };
    if (isLoading) {
        return <></>;
    } else {
        return (
            <section className={classes.SubscribePage}>
                <Grid container>
                    <Grid item xs={12}>
                        <div className={classes.HyperLinkBox}>
                            <HyperLink
                                classNames={classes.HyperLink_GoBack}
                                underline="none"
                                onClick={getRedirectUrlTrade}>
                                <ChevronLeftIcon />
                                volver al comercio
                            </HyperLink>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <ContentBox classNames={classes.ContentBox}>
                            <img className={classes.LogoImage} src={SabbiLogo} alt="LogoSabbi" />
                            <Typography variant="h2" component="h2" gutterBottom>
                                Ingresa los datos de tu Tarjeta de Crédito bancaria:
                            </Typography>
                            <FormContext
                                className={classes.Form}
                                id={'SubscribeForm'}
                                onSubmit={onSubmit}
                                methods={methods}>
                                <AlertBar
                                    classNames={classes.AlertBar}
                                    variant="filled"
                                    severity="error"
                                    message={errorValues.title}
                                    open={errorValues.visible}
                                    closeOnClick={() => clearError()}
                                    icon={<WarningRoundedIcon />}
                                />
                                <CreditCardInput
                                    id={'creditCard'}
                                    name={'creditCard'}
                                    label={'Número de tarjeta'}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'La tarjeta de crédito es requerida'
                                        }
                                    }}
                                />
                                <TextInput
                                    id={'name'}
                                    name={'name'}
                                    type={'text'}
                                    label={'Nombre'}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'El nombre es requerido'
                                        },
                                        minLength: { value: 2, message: 'Campo Incorrecto' }
                                    }}
                                    icon={<AccountCircleIcon />}
                                />
                                <RutInput
                                    id={'documentNumber'}
                                    name={'documentNumber'}
                                    label={'RUT'}
                                    maxLength={12}
                                    rules={{
                                        required: { value: true, message: 'El RUT es requerido' }
                                    }}
                                    icon={<AssignmentIndIcon />}
                                />
                                <ExpDateInput
                                    id={'expDate'}
                                    name={'expDate'}
                                    label={'Vencimiento de tarjeta'}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'La fecha de vencimiento es requerida'
                                        },
                                        minLength: { value: 5, message: 'Campo Incorrecto' },
                                        validate: {
                                            isValid: (value) => {
                                                return validateExpDate(value)
                                                    ? true
                                                    : 'Fecha Incorrecta';
                                            }
                                        }
                                    }}
                                    icon={<DateRangeIcon />}
                                />
                                <TextInput
                                    classNames={classes.CvvInput}
                                    id="cvv"
                                    name="cvv"
                                    type={'password'}
                                    label={'Código de seguridad'}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'El código es requerido'
                                        },
                                        minLength: { value: 2, message: 'Campo Incorrecto' },
                                        maxLength: { value: 4, message: 'Campo Incorrecto' }
                                    }}
                                    maxLength={4}
                                    hasHyperLink={true}
                                    hyperLinkText={renderTooltip()}
                                    icon={<MoreHorizIcon />}
                                />
                                <div className={classes.ButtonsBox}>
                                    <Button
                                        className={classes.CancelButton}
                                        onClick={() => showModal('cancelModal')}>
                                        Cancelar
                                    </Button>
                                    <RoundButton
                                        classNames={classes.SubmitButton}
                                        id={'submitbtn'}
                                        color="primary"
                                        name={'submitbtn'}
                                        type="submit">
                                        Continuar
                                    </RoundButton>
                                </div>
                            </FormContext>
                        </ContentBox>
                    </Grid>
                </Grid>
                <ModalDialog
                    id="ModalCancel"
                    open={modalValues.cancelModal}
                    handleCancel={closeModals}
                    handleAccept={handleCancelSubscribe}
                    title={
                        <>
                            <img src={SabbiLogoNoBorderSrc} alt="sabbi" />
                            <Typography variant="h2" component="h2">
                                ¿Seguro que deseas cancelar esta operación?
                            </Typography>
                        </>
                    }
                    scroll={'paper'}
                    maxWidth={'sm'}>
                    <div className={classes.CancelModal}>
                        <Typography component="p">
                            Al cancelar la operación, perderás los datos ingresados.
                        </Typography>
                    </div>
                </ModalDialog>
            </section>
        );
    }
};
export default CardSubscriptionPage;
